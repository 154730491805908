<template>
  <!-- 主播查看房间用户 -->
  <div class="conts">
    <div style="padding: 10px">
      <van-tag
        v-for="(item, idx) in tagArr"
        :key="idx"
        :plain="item.tag == tag ? false : true"
        type="primary"
        size="large"
        style="margin-right: 2px"
        @click="selectTag(item.tag)"
        >{{ item.title }}</van-tag
      >
    </div>
    <div v-if="tag == 'search'">
      <van-cell-group>
        <van-field
          v-model="keyword"
          label-width="50"
          center
          label="关键词"
          placeholder="输入关键词"
        >
          <template #button>
            <van-button size="small" @click="searchDo" type="default"
              >搜索</van-button
            >
          </template>
        </van-field>
      </van-cell-group>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        :loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div v-for="(item, idx) in dataList" :key="idx">
          <div class="user_list" @click="selectUser(item)">
            <div class="con">
              <div class="avatar">
                <van-image
                  style="width: 100%; height: 100%"
                  round
                  :src="item.avatar"
                />
              </div>
              <div class="head">
                <div>
                  <div class="title">
                    {{ item.nickname }}
                    <span>
                      <van-tag
                        style="margin-right: 4px"
                        v-if="item.auth == 1"
                        round
                        type="success"
                        >证</van-tag
                      >
                      <van-tag
                        style="margin-right: 4px"
                        v-else
                        round
                        color="#aaaaaa"
                        >未认证</van-tag
                      >
                      <van-tag
                        round
                        v-if="item.grade > 0"
                        style="margin-right: 4px"
                        type="warning"
                        >团</van-tag
                      >
                      <van-tag
                        round
                        v-if="item.is_zb > 0"
                        style="
                          margin-right: 4px;
                          background-color: rgba(18, 163, 220, 0.8);
                        "
                        type="warning"
                        >主</van-tag
                      >
                      <van-tag
                        round
                        v-if="item.zuli > 0"
                        style="
                          margin-right: 4px;
                          background-color: rgba(90, 140, 220, 0.8);
                        "
                        type="warning"
                        >助理</van-tag
                      >
                      <van-tag
                        round
                        v-if="item.black > 0"
                        style="
                          margin-right: 4px;
                          background-color: rgba(0, 0, 0, 0.6);
                        "
                        type="warning"
                        >黑</van-tag
                      >
                      <van-tag
                        round
                        v-if="item.comment_status == 0"
                        style="
                          margin-right: 4px;
                          background-color: rgba(0, 0, 0, 0.6);
                        "
                        type="warning"
                        >禁</van-tag
                      >
                    </span>
                  </div>

                  <div class="mark">
                    答题：正确{{
                      item.share_count_info.questionSuccess
                    }}次，错误{{ item.share_count_info.questionFail }}次
                  </div>
                  <div class="mark" v-if="item.ip_info">
                    {{
                      item.ip_info
                        ? item.ip_info.province +
                          "." +
                          item.ip_info.city +
                          "." +
                          item.ip_info.area
                        : "-"
                    }}
                  </div>
                </div>
                <div class="times" style="line-height: 26px">
                  <template v-if="item.grade > 0">
                    <span
                      >邀请
                      <span style="color: #cc0000">{{
                        item.share_count_info.childCount
                      }}</span>
                      人</span
                    >
                    <br />
                  </template>
                  <span
                    >观看
                    <span style="color: #cc0000">{{
                      item.share_count_info.times
                    }}</span></span
                  >
                  <br />
                  <span
                    >红包
                    <span style="color: #cc0000">{{
                      item.share_count_info.prizeCount
                    }}</span></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>

    <!-- 对用户进行操作 -->
    <van-popup
      v-model:show="showUserAction"
      round
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <template #default>
        <div class="apps_new">
          <div class="title van-hairline--bottom">
            【管理】{{ selectItem.nickname }}
          </div>
          <van-grid :column-num="4" :gutter="10" square>
            <van-grid-item
              v-for="(menu, idx) in userOptions"
              :key="idx"
              @click="selectUserActionDo(menu)"
            >
              <div class="item">
                <van-icon
                  class="icon"
                  :style="{ color: menu.color }"
                  :name="menu.icon"
                />
                <div>{{ menu.name }}</div>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
      </template>
    </van-popup>
  </div>
</template>
<script>
import { showToast, showConfirmDialog } from "vant";
export default {
  name: "userList",
  props: {
    casterId: {
      default: 0,
    },
    roomId: {
      default: 0,
    },
    userAuth: {
      default: {},
    },
  },
  setup(props, { emit }) {
    function returnTotal(val) {
      emit("returnTotal", val);
    }
    return { returnTotal };
  },
  data() {
    return {
      loading: false,
      refreshing: false,
      finished: false,
      error: false,
      dataList: [],
      tagArr: [
        {
          title: "全部",
          tag: "all",
        },
        {
          title: "已认证",
          tag: "yes",
        },
        {
          title: "未认证",
          tag: "no",
        },
        // {
        //   title: "黑名单",
        //   tag: "black",
        // },
        {
          title: "已禁言",
          tag: "close_comment",
        },
        {
          title: "自定义",
          tag: "search",
        },
      ],
      per_page: 10,
      last_page: 0,
      current_page: 0,
      total: 0,
      showUserAction: false,
      selectItem: {},
      tag: "all",
      keyword: "",
    };
  },
  mounted() {
    this.onRefresh();
  },
  methods: {
    selectTag(val) {
      this.tag = val;
      this.keyword = "";
      if (this.tag == "search") {
        this.dataList = [];
        return;
      }
      this.onRefresh();
    },
    searchDo() {
      this.onRefresh();
    },
    selectUser(item) {
      if (this.userAuth.grade == 0 && this.userAuth.zuli == 0) {
        showToast("没有权限");
        return;
      }
      // 团队长操作用户
      this.selectItem = item;
      this.userOptions = [];
      if (item.auth > 0) {
        this.userOptions.push({
          name: "取消认证",
          icon: "good-job",
          act: "auth_cancle",
          color: "#666666",
        });
      } else {
        this.userOptions.push({
          name: "认证",
          icon: "good-job",
          act: "auth_ok",
          color: "#23cc77",
        });
      }
      if (item.grade > 0) {
        this.userOptions.push({
          name: "取消团队长",
          icon: "manager",
          act: "down_grade",
          color: "#666666",
        });
      } else {
        this.userOptions.push({
          name: "设为团队长",
          icon: "manager",
          act: "up_grade",
          color: "#1ed6e0",
        });
      }
      //   if(item.is_zb>0){
      //     this.userOptions.push({ name: '取消主播', icon: 'gem' ,act:'down_zb',color:'#666666'})
      //   }else{
      //     this.userOptions.push({ name: '设为主播', icon: 'gem' ,act:'up_zb',color:'#1ed6e0'})
      //   }
      // if(item.zuli>0){
      //   this.userOptions.push({ name: '取消助理', icon: 'shield-o' ,act:'down_zuli',color:'#666666'})
      // }else{
      //   this.userOptions.push({ name: '设为助理', icon: 'shield-o' ,act:'up_zuli',color:'#1ed6e0'})
      // }
      if (item.black > 0) {
        this.userOptions.push({
          name: "恢复",
          icon: "cross",
          act: "in_black",
          color: "#23cc77",
        });
      } else {
        this.userOptions.push({
          name: "踢出房间",
          icon: "cross",
          act: "out_black",
          color: "#666666",
        });
      }
      if (item.comment_status == 0) {
        this.userOptions.push({
          name: "开启评论",
          icon: "chat-o",
          act: "open_comment",
          color: "#23cc77",
        });
      } else {
        this.userOptions.push({
          name: "禁止评论",
          icon: "chat-o",
          act: "close_comment",
          color: "#666666",
        });
      }
      this.showUserAction = true;
    },
    selectUserActionDo(opt) {
      if (opt.act == "auth_ok" || opt.act == "auth_cancle") {
        // 进行认证操作
        this.changeAuthStatus("auth", `确认【${opt.name}】吗？`);
      } else if (opt.act == "down_grade" || opt.act == "up_grade") {
        // 进行认证操作
        this.changeAuthStatus("grade", `确认【${opt.name}】吗？`);
      } else if (opt.act == "in_black" || opt.act == "out_black") {
        // 进行认证操作
        this.changeAuthStatus("black", `确认【${opt.name}】吗？`);
      } else if (opt.act == "down_zb" || opt.act == "up_zb") {
        // 设置主播身份
        this.changeAuthStatus("is_zb", `确认【${opt.name}】吗？`);
      } else if (opt.act == "open_comment" || opt.act == "close_comment") {
        // 设置房间禁言
        this.changeAuthStatus("comment_status", `确认【${opt.name}】吗？`);
      } else if (opt.act == "up_zuli" || opt.act == "down_zuli") {
        // 助理身份
        this.changeAuthStatus("zuli", `确认【${opt.name}】吗？`);
      } else {
        showToast("不支持的操作");
      }
    },
    changeAuthStatus(field, tips) {
      showConfirmDialog({
        title: "请确认",
        message: tips,
      })
        .then(() => {
          this.$http
            .postData("/ShareLog/changeStatus", {
              user_id: this.selectItem.user_id,
              caster_id: this.casterId,
              field: field,
              hide_success: false,
            })
            .then((res) => {
              this.showUserAction = false;
              this.onRefresh();
            })
            .catch((error) => {});
        })
        .catch(() => {
          // on cancel
        });
    },
    onRefresh() {
      this.dataList = [];
      this.current_page = 0;
      this.refreshing = true;
      this.finished = false;
      this.onLoad();
    },
    onLoad() {
      if (this.tag == "search" && this.keyword == "") {
        showToast("请输入关键词");
        return;
      }
      if (this.inLoad) {
        return;
      }
      this.inLoad = true;
      this.$http
        .postData("/RowList/getList", {
          search: {
            scence: "share_from_detail",
            caster_id: this.casterId,
            room_id: this.roomId,
            tag: this.tag,
            keyword: this.keyword,
          },
          pageInfo: {
            page_size: this.per_page,
          },
          page: this.current_page + 1,
          table: "ShareLog",
          hide_success: true,
        })
        .then((res) => {
          this.inLoad = false;
          this.refreshing = false;

          res.data.map((item) => {
            this.dataList.push(item);
          });
          this.current_page = res.current_page;
          this.total = res.total;
          this.returnTotal(res.total);
          if (res.last_page == res.current_page || res.last_page == 0) {
            this.loading = false;
            this.finished = true;
          }
        })
        .catch((error) => {
          this.inLoad = false;
          this.finished = true;
          this.refreshing = false;
        });
    },
  },
};
</script>
  <style scoped>
.conts {
  min-height: 30vh;
}
.user_list {
  border-bottom: 1px solid rgb(235, 235, 235);
}
.user_list .footer {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  padding: 0px 10px;
  margin-bottom: 10px;
}
.user_list .con {
  padding: 0 10px;
  background-color: #ffffff;
  font-size: 12px;
  position: relative;
}
.user_list .head {
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  line-height: 26px;
  padding: 10px;
  padding-left: 56px;
}
.user_list .head .title {
  font-size: 14px;
}
.user_list .head .times {
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}
.user_list .avatar {
  position: absolute;
  left: 10px;
  top: 16px;
  width: 40px;
  height: 40px;
}

.apps_new {
  padding: 20px 10px;
}
.apps_new .title {
  text-align: center;
  font-size: 16px;
  color: #666666;
  padding-bottom: 14px;
  margin-bottom: 10px;
}
.apps_new .item {
  font-size: 12px;
  text-align: center;
  line-height: 24px;
}
.apps_new .icon {
  font-size: 28px;
}
</style>
import {createRouter,createWebHashHistory,createWebHistory,useRouter} from "vue-router"
import { showToast } from 'vant';
import Home from "../views/Home"
import Login from "../views/Login"
import Detail from "../views/Detail"
import forPc from "../views/forPc"

// import makeShare from "../views/makeShare"
import qrcode from "../views/qrcode"
import getList from "../views/getList"
import myRoom from "../views/myRoom"
import log from "../views/log"
import admin from "../views/admin"
import push from "../views/push"
import viewData from "../views/viewData"

const routes=[{
    name:'myRoom',
    path:'/',
    component:myRoom,
    meta:{
        title:'我的直播间',
        auth:true
    }
},{
    name:'qrcode',
    path:'/qrcode',
    component:qrcode,
    meta:{
        title:'我的二维码',
        auth:true
    }
},{
    name:'rowList',
    path:'/:actName/getList',
    component:getList,
    meta:{
        title:'数据',
        auth:true
    }
},{
    name:'admin',
    path:'/admin/:actName',
    component:admin,
    meta:{
        title:'管理',
        auth:true
    }
},{
    name:'log',
    path:'/log',
    component:log,
    meta:{
        title:'播放记录',
        auth:true
    }
},{
    name:'login',
    path:'/login',
    component:Login,
    meta:{
        title:'登陆',
        auth:false
    }
},{
    name:'detail',
    path:'/detail/:code',
    component:Detail,
    meta:{
        title:'详情',
        auth:true
    }
},{
    name:'forPc',
    path:'/forPc/:code',
    component:forPc,
    meta:{
        title:'详情',
        auth:true
    }
},{
    name:'push',
    path:'/push/:code',
    component:push,
    meta:{
        title:'主播端',
        auth:true
    }
},{
    name:'viewData',
    path:'/viewData/:spm',
    component:viewData,
    meta:{
        title:'直播数据',
        auth:true
    }
},]

const router = createRouter({
    // history:createWebHashHistory(),
    history:createWebHistory(),
    routes
})
router.beforeEach((to,from,next) =>{
    let userInfo = localStorage.getItem('userInfo')
    userInfo = userInfo?JSON.parse(userInfo):false
    let token = userInfo ? userInfo.token : false
    let nowTime = new Date().getTime()
    // let auth_url = 'https://jytc.qianbaiyou88.com/wechat/Auth/authorize?hurl='+location.href
    // let auth_url = 'https://jytc.qianbaiyou88.com/wechat/Auth/authorize?hurl=http://localhost:8080/'
    let auth_url = 'https://jytc.qianbaiyou88.com/wechat/Auth/authorize'
    let tmpUrl = localStorage.getItem('redirect_url')
    if(to.meta.auth){
        if(!token || userInfo.exp >= nowTime){
            if(!tmpUrl || tmpUrl!=location.href){
                localStorage.setItem('redirect_url',location.href)
            }
            localStorage.removeItem('userInfo')
            showToast('请登陆')
            next({
                name: 'login'
            })
            return
        }else{
            next()
        }
    }else{
        if (to.name=='login' && token ) {
            next({
                path: '/'
            })
            // showToast('已经登陆过')
            setTimeout(() => {
                next({
                    path: '/'
                })
            }, 500);
        }else{
            next()
        }
    }
})
router.afterEach(to => {
    // 更改标题
    const titleText = to.meta.title
    // const processTitle = process.env.VUE_APP_TITLE || '金易天成'
    // window.document.title = `${processTitle}${titleText ? ` | ${titleText}` : ''}`
    window.document.title = titleText
  })

export default router
  
  
<template>
  <div class="conts">
    <div style="margin-top: 16px">
      <van-cell-group inset label-width="auto">
        <van-field
          label="直播类型"
          v-model="saveData.type_name"
          placeholder="选择直播间类型"
          readonly
          is-link
          @click="showRoomPopup = true"
        />
        <van-field
          label="所属栏目"
          v-model="saveData.collective_name"
          placeholder="指定一个栏目"
          readonly
          is-link
          @click="showCollectivePopup = true"
        />
        <van-field
          label="名称"
          v-model="saveData.title"
          placeholder="对外展示名称"
        />
        <van-field
          label="简介"
          v-model="saveData.desc"
          placeholder="内容简介"
        />
        <template v-if="parseInt(saveData.type) == 2">
          <van-field
            label="视频资源"
            v-model="saveData.video_name"
            placeholder="输入关键词查询"
          >
            <template #button>
              <van-button size="small" @click="searchVod" type="primary"
                >查询</van-button
              >
            </template>
          </van-field>
          <template v-if="!loadSync">
            <van-field label="播放次数">
              <template #input>
                <van-stepper
                  v-model="saveData.repeatTimes"
                  :min="1"
                  :max="1000"
                />
                <div style="font-size: 0.6rem; color: #cc0000">
                  播放{{ saveData.repeatTimes }}次后结束
                </div>
              </template>
            </van-field>
            <van-field label="预计时长">
              <template #input>
                {{ parseInt(saveData.duration * saveData.repeatTimes) }}秒
              </template>
            </van-field>
          </template>
        </template>
        <van-field
          label="开播时间"
          v-model="saveData.opentime"
          placeholder="选择开播时间"
          readonly
          is-link
          @click="showPopup = true"
        />
        <van-field label="预计时长" v-if="parseInt(saveData.type) == 1">
          <template #input>
            <van-stepper v-model="saveData.expire" :min="10" :max="180" />分钟
          </template>
        </van-field>
        <van-field label="封面图" placeholder="直播间封面图">
          <template #input>
            <van-uploader
              v-model="saveData.thumb"
              :max-count="1"
              :before-read="beforeRead"
              @oversize="isOverSize"
              :after-read="afterRead2"
            />
          </template>
        </van-field>
        <van-field label="背景图" placeholder="直播间背景图">
          <template #input>
            <van-uploader
              v-model="saveData.bg_pic"
              :max-count="1"
              :before-read="beforeRead"
              @oversize="isOverSize"
              :after-read="afterRead1"
            />
            <van-tag
              style="margin-left: 10px"
              size="large"
              type="primary"
              @click="showSelectBg = true"
              >选择模板</van-tag
            >
          </template>
        </van-field>
        <van-field label="评论状态">
          <template #input>
            <van-switch v-model="saveData.open_comment" />
          </template>
        </van-field>
        <van-field label="直播问答">
          <template #input>
            <van-switch v-model="saveData.config.open_question" />
          </template>
        </van-field>
        <template v-if="saveData.config.open_question">
          <van-field label="观看时长" v-model="saveData.config.min_time">
            <template #input>
              <van-stepper
                v-model="saveData.config.min_time"
                :min="1"
                :max="360"
              />
              <div style="font-size: 0.6rem; clear: both; margin: 0 auto">
                累计观看{{ saveData.config.min_time }}分钟后可答题
              </div>
            </template>
          </van-field>
          <van-field label="延迟答题" v-model="saveData.config.dely_time">
            <template #input>
              <van-stepper
                v-model="saveData.config.dely_time"
                :min="0"
                :max="10"
              />
              <div style="font-size: 0.6rem; clear: both; margin: 0 auto">
                延长{{ saveData.config.dely_time }}分钟答题时间
              </div>
            </template>
          </van-field>
          <van-field
            label="出错次数"
            v-model="saveData.config.error_count"
            placeholder="允许答题出错次数"
          >
            <template #input>
              <van-stepper
                v-model="saveData.config.error_count"
                :min="0"
                :max="10"
              />
              <div style="font-size: 0.6rem; clear: both; margin: 0 auto">
                允许错误{{ saveData.config.error_count }}次
              </div>
            </template>
          </van-field>
          <!-- 问题列表 -->
          <van-collapse v-model="activeNames" accordion>
            <van-collapse-item
              v-for="(item, idx) in saveData.config.question_list"
              :title="'问题' + (idx + 1)"
              :key="idx"
              :name="'question' + idx"
            >
              <div>
                <van-field
                  label="题目"
                  v-model="item.title"
                  placeholder="请填写问题"
                />
                <van-field label="选项" placeholder="请填写问题">
                  <template #input>
                    <van-tag
                      style="margin-right: 4px"
                      closeable
                      size="medium"
                      type="primary"
                      v-for="(q, qi) in item.list"
                      @close="deleteQuestionList(idx, qi)"
                      :key="qi"
                      >{{ q }}</van-tag
                    >
                    <van-tag
                      size="medium"
                      type="danger"
                      @click="addQuestionList(idx)"
                      >添加</van-tag
                    >
                  </template>
                </van-field>
                <van-field label="答案" v-model="item.answer">
                  <template #input>
                    <span v-if="!item.list || !item.list.length"
                      >请设置选项</span
                    >
                    <template v-else>
                      <van-stepper
                        v-model="item.answer"
                        :min="1"
                        :max="item.list.length"
                      />
                    </template>
                  </template>
                </van-field>
              </div>
              <div style="text-align: center">
                <van-button
                  plain
                  type="warning"
                  @click="delQuestion(idx)"
                  size="mini"
                  >删除问题</van-button
                >
              </div>
            </van-collapse-item>
          </van-collapse>
          <div style="text-align: center; padding: 6px 0">
            <van-button plain type="primary" @click="addQuestion" size="mini"
              >添加问题</van-button
            >
          </div>
        </template>
      </van-cell-group>
      <div style="margin: 16px; text-align: center">
        <van-button
          size="small"
          type="primary"
          native-type="submit"
          @click="toSave"
          >保存场次</van-button
        >
        <van-button
          size="small"
          type="default"
          style="margin-left: 20px"
          @click="onCallBack(false)"
          >取 消</van-button
        >
      </div>
    </div>

    <!-- 选择开播时间 -->
    <van-popup v-model:show="showPopup" position="bottom">
      <van-picker-group
        title="开播时间"
        :tabs="['选择日期', '选择时间']"
        @confirm="onConfirm"
      >
        <van-date-picker
          v-model="saveData.open_date"
          :min-date="minDate"
          :max-date="maxDate"
        />
        <van-time-picker v-model="saveData.open_time" />
      </van-picker-group>
    </van-popup>
    <!-- 选择封面图 -->
    <template v-if="showSelectBg">
      <div class="bgsArr">
        <van-swipe
          class="my-swipe"
          :autoplay="3000000"
          indicator-color="#ff6600"
        >
          <van-swipe-item
            style="text-align: center; background: transparent"
            v-for="i in 8"
            :key="i"
          >
            <van-image
              style="margin: 0 auto; border-radius: 10px; overflow: hidden"
              width="100wd"
              height="50vh"
              fit="contain"
              :src="'/storage/bg/post' + i + '.png'"
            />
            <div style="margin: 10px 40px; margin-bottom: 30px">
              <van-button
                round
                block
                size="small"
                type="success"
                style="background-color: #38a0f5"
                @click="selectPic(i)"
                >选 择</van-button
              >
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="marsk"></div>
    </template>
    <!-- 添加选项 -->
    <van-popup
      v-model:show="showAddOptsPopup"
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <div style="margin-top: 16px">
        <van-cell-group inset label-width="auto">
          <van-field
            v-model="opts.value"
            label="选项"
            placeholder="请填写内容"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button
            round
            block
            type="primary"
            native-type="submit"
            @click="addQuestionListDo"
            >确认</van-button
          >
        </div>
      </div>
    </van-popup>
    <!-- 选择直播间类型 -->
    <van-popup
      v-model:show="showRoomPopup"
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <van-picker
        title="选择类型"
        :columns="roomTypeArr"
        @confirm="selectRoomType"
      />
    </van-popup>
    <!-- 选择合集 -->
    <van-popup
      v-model:show="showCollectivePopup"
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <van-picker
        title="选择类型"
        :columns="collectiveArr"
        @confirm="selectCollective"
      />
    </van-popup>

    <!-- 选择视频资源 -->
    <van-popup
      v-model:show="showVideoPopup"
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <van-picker
        title="选择类型"
        :columns="videoList"
        @confirm="selectVideo"
      />
    </van-popup>
  </div>
</template>
<script>
import { showToast } from "vant";
import { ref } from "vue";
export default {
  name: "CreateCaster",
  props: {
    roomId: {
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 5, 1),
      showPopup: false,
      showAddOptsPopup: false,
      showRoomPopup: false,
      showUserPopup: false,
      showSelectBg: false,
      loadSync: false,
      showCollectivePopup: false,
      opts: {
        idx: 0,
        value: "",
      },
      activeNames: "question0",
      roomTypeArr: [
        {
          text: "在线直播",
          value: 1,
        },
        {
          text: "视频录播",
          value: 2,
        },
      ],
      collectiveArr: [],
      showVideoPopup: false,
      videoList: [],
      saveData: {
        type: 1, //在线直播
        type_name: "在线直播",
        collective_name: "",
        repeatTimes: 1,
        collect_id: "",
        video_name: "",
        resourceId: "",
        duration: 0,
        title: "", //标题
        desc: "", //描述
        bg_pic: [], //背景图
        content: "",
        open_comment: 0,
        thumb: [], //封面图
        opentime: "", //格式化开播时间
        expire: 60, //直播时长
        open_date: ref(["2022", "06", "01"]),
        open_time: ref(["12", "00"]),
        config: {
          open_question: false,
          question_list: [],
          error_count: 5,
          min_time: 60,
          dely_time: 5,
        },
      },
    };
  },
  setup(props, { emit }) {
    const onCallBack = (val) => {
      emit("callBack", val);
    };
    const isOverSize = (file) => {
      const maxSize = file.type === "image/jpeg" ? 1 : 2;
      return file.size >= maxSize;
    };
    // 返回布尔值
    const beforeRead = (file) => {
      if (!(file.type == "image/jpeg" || file.type == "image/png")) {
        showToast("请上传 jpg或png 格式图片");
        return false;
      }
      return true;
    };
    return { onCallBack, isOverSize, beforeRead };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.saveData.room_id = this.roomId;
    this.getTmpList();
  },
  methods: {
    searchVod() {
      this.loadSync = true;
      this.$http
        .postData("/RowList/getList", {
          search: {
            scence: "vodList",
            keyword: this.saveData.video_name,
          },
          table: "Room",
          hide_success: true,
        })
        .then((res) => {
          this.loadSync = false;
          this.videoList = res ? res : [];
          this.showVideoPopup = true;
        })
        .catch((error) => {
          console.log(error);
          this.loadSync = false;
        });
    },
    selectVideo(val) {
      this.saveData.resourceId = val.selectedOptions[0].value;
      this.saveData.video_name = val.selectedOptions[0].text;
      this.saveData.duration = val.selectedOptions[0].duration;
      this.showVideoPopup = false;
    },
    selectPic(i) {
      this.saveData.bg_pic = [];
      this.saveData.bg_pic.push({
        name: "post" + i,
        url: `/storage/bg/post${i}.png`,
      });
      this.showSelectBg = false;
    },
    selectRoomType(val) {
      this.saveData.type = val.selectedOptions[0].value;
      this.saveData.type_name = val.selectedOptions[0].text;
      this.showRoomPopup = false;
    },
    selectCollective(val) {
      this.saveData.collect_id = val.selectedOptions[0].value;
      this.saveData.collective_name = val.selectedOptions[0].text;
      this.showCollectivePopup = false;
    },
    getTmpList() {
      this.loading = true;
      this.$http
        .postData("/RowList/getList", {
          search: {
            scence: "collective_list",
            actTag: "create_caster",
            room_id: this.roomId,
          },
          table: "Collective",
          pageInfo: {
            page_size: 100000,
          },
        })
        .then((res) => {
          this.loading = false;
          this.saveData.open_date = res.dateArr.today;
          this.saveData.open_time = res.dateArr.time;
          this.maxDate = new Date(
            res.dateArr.max[0],
            parseInt(res.dateArr.max[1]) - 1,
            res.dateArr.max[2]
          );
          this.minDate = new Date(
            res.dateArr.min[0],
            parseInt(res.dateArr.min[1]) - 1,
            res.dateArr.min[2]
          );
          res.data.forEach((item, idx) => {
            if (idx == 0) {
              this.saveData.collect_id = item.id;
              this.saveData.collective_name = item.title;
            }
            this.collectiveArr.push({
              value: item.id,
              text: item.title,
            });
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    addQuestionList(idx) {
      this.opts = {
        idx: idx,
        value: "",
      };
      this.showAddOptsPopup = true;
    },
    addQuestionListDo() {
      if (!this.opts.value) {
        showToast("请填写内容");
        return;
      }
      this.saveData.config.question_list[this.opts.idx].list.push(
        this.opts.value
      );
      this.saveData.config.question_list[this.opts.idx].answer = 1;
      this.showAddOptsPopup = false;
    },
    deleteQuestionList(idx1, idx2) {
      this.saveData.config.question_list[idx1].list.splice(idx2, 1);
      this.saveData.config.question_list[this.opts.idx].answer = 1;
    },
    addQuestion() {
      this.saveData.config.question_list.push({
        title: "",
        list: [],
        answer: 1,
      });
      this.activeNames =
        "question" + (this.saveData.config.question_list.length - 1);
    },
    delQuestion(idx) {
      this.saveData.config.question_list.splice(idx, 1);
      this.activeNames =
        "question" + (this.saveData.config.question_list.length - 1);
    },
    afterRead1(file) {
      let params = new FormData(); //创建form对象
      params.append("files", file.file);
      this.$http
        .uploadPic("/Upload/uploadFile", params)
        .then((res) => {
          this.saveData.bg_pic = [res];
        })
        .catch((error) => {});
    },
    afterRead2(file) {
      let params = new FormData(); //创建form对象
      params.append("files", file.file);
      this.$http
        .uploadPic("/Upload/uploadFile", params)
        .then((res) => {
          this.saveData.thumb = [res];
        })
        .catch((error) => {});
    },
    toSave() {
      if (this.loading) {
        console.log("操作过快");
        return;
      }
      if (!this.saveData.title) {
        showToast("请填写直播间名称");
        return;
      }
      if (!this.saveData.opentime) {
        showToast("请设置开播时间");
        return;
      }
      // if(!this.saveData.bg_pic){
      //     showToast('请设置房间背景图')
      //     return
      // }
      // if(!this.saveData.thumb){
      //     showToast('请设置房间封面')
      //     return
      // }
      // 如果开启了问答
      if (this.saveData.config.open_question) {
        if (
          !this.saveData.config.question_list ||
          !this.saveData.config.question_list.length
        ) {
          showToast("请至少添加一个问题");
          return;
        }
        try {
          this.saveData.config.question_list.forEach((item, idx) => {
            if (item.title == "") {
              throw new Error("问题" + (idx + 1) + ",标题不能为空");
            }
            if (!item.list || !item.list.length) {
              throw new Error("问题" + (idx + 1) + ",未设置选项");
            }
          });
        } catch (error) {
          showToast(error.message);
          return;
        }
      }

      this.loading = true;
      this.$http
        .postData("/Caster/createCaster", {
          ...this.saveData,
        })
        .then((res) => {
          this.loading = false;
          this.onCallBack(true);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    onConfirm() {
      let str = `${this.saveData.open_date.join(
        "-"
      )} ${this.saveData.open_time.join(":")}`;
      this.saveData.opentime = str;
      this.showPopup = false;
    },
  },
};
</script>
  <style scoped>
.bgsArr {
  position: fixed;
  z-index: 100;
  top: 130px;
  bottom: 130px;
  left: 50px;
  right: 50px;
}
.marsk {
  position: fixed;
  z-index: 99;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
}
</style>
<template>
    <div class="hello">
        <!-- <van-nav-bar
        title="资源中心"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
        /> -->

        <div>

          <van-list
          :loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          >
          <div class="user_list" v-for="item in videoList" :key="item.id" @click="viewVideo(item.vid)">
            <van-image
                class="avatar"
                fit="cover"
                position="center"
                :src="item.video.thumb"
              />
            <div class="play">
              <van-icon name="play-circle-o" />
            </div>
            <div class="title">{{ item.video.title }}</div>
            <div class="desc">已观看 {{ item.current_time }}秒</div>
          </div>

          </van-list>

        </div>
    </div>
</template>
  
<script>
  export default {
    name: 'videoList',
    data(){
        return {
            loading:false,
            finished:false,
            error:false,
            videoList:[],
            per_page:10,
            last_page:0,
            current_page:0,
            total:0,
            userInfo:false
        }
    },
    mounted() {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.onLoad()
    },
    methods: {
        onClickLeft(){
        this.$router.push({path:'/'})
      },viewVideo(vid){
        let url = '/detail/'+vid+'/'+this.userInfo.uid
        window.location.href = url
        return
        this.$router.push({path:'/detail',query:{item:vid}})
      },
      onLoad(){
        if(this.inLoad){
          return
        }
        this.inLoad = true
        this.$http.getList({
            table:'VodLog',
            hide_success:true,
            pageInfo:{
            page_size: this.per_page,
            },
            page: this.current_page+1
        }).then(res=>{
            this.inLoad = false
            res.data.map(item=>{
              this.videoList.push(item)
            })
            this.current_page = res.current_page
            this.total = res.total
            if(res.last_page == res.current_page || res.last_page==0){
                this.loading = false
                this.finished = true
                return
            }
        }).catch(error=>{
            this.inLoad = false
            this.finished = true
            console.log(error)
        })
        // this.$router.push({path:'/'})
      }
    },
  }
  </script>
  <style scoped>
    .user_list{
    position: relative;
    padding:10px;
    margin:10px;
    background-color: #ffffff;
    font-size: 14px;
    margin-top:20px;
    border-radius: 10px;
			box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.3);
  }
  .user_list .title{
    font-size: 1rem;
    line-height: 30px;
  }
  .user_list .avatar{
    width:100%;
    height: 160px;
  }
  .user_list .content{
    color: #666666;
    line-height: 24px;
    padding:10px;
    font-size: 0.8rem;
  }
  .play{
    position: absolute;
    z-index: 1;
    top:0px;
    left:0px;
    right:0px;
    height: 100%;
    color: #ffffff;
    font-size: 50px;
    text-align: center;
    line-height: 160px;
  }
  </style>
  
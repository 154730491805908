<template>
    <div class="conts">
        <div style="margin-top: 16px;">
            <van-cell-group inset label-width="auto">
              <van-field label="查找用户" v-model="searchKey" @blur="searchUser" placeholder="查找用户" />
              <van-field label="主播" v-model="saveData.auth_name" :placeholder="'找到个'+userList.length+'用户'" readonly is-link @click="showUser" />
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit" :disabled="loading" @click="toSave">创建直播间</van-button>
            </div>
        </div>

        <!-- 选择主播 -->
        <van-popup v-model:show="showUserPopup" position="bottom" :style="{ minHeight: '30%' }">
        <van-picker
            title="选择主播"
            :columns="userList"
            @confirm="selectUser"
        /></van-popup>

    </div>
</template>
<script>
import { showToast } from 'vant'
import {ref} from 'vue'
export default {
    name: 'CreateRoom',
    props: {
    },
    data(){
        return {
            loading:false,
            showUserPopup:false,
            searchKey:'',
            userList:[],
            saveData:{
                type:0,
                auth_id:'',
                auth_name:'',
            }
        }
    },
    setup (props,{emit}) {
        const onCallBack = (val) => {
            emit('callBack',val); 
        }
        const isOverSize = (file) => {
            const maxSize = file.type === 'image/jpeg' ? 1:2;
            return file.size >= maxSize;
        };
        // 返回布尔值
        const beforeRead = (file) => {
            if (!(file.type == 'image/jpeg' || file.type == 'image/png')) {
                showToast('请上传 jpg或png 格式图片');
                return false;
            }
            return true;
        };
        return {onCallBack,isOverSize,beforeRead}
    },
    mounted() {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    },
    methods: {
        searchUser(){
            if(this.loading){
                console.log('操作过快')
                return
            }
            if(!this.searchKey){
                showToast('填写关键词')
                return
            }
            this.loading = true
            this.$http.postData("/RowList/getList",{
                search:{
                    scence:'zuboList',
                    keyword:this.searchKey
                },
                table:"User",
                pageInfo:{
                    page_size:100
                }
            }).then(res=>{
                
                this.loading = false
                if(!res.data || !res.data.length){
                    showToast('没有找到用户')
                    return
                }
                this.userList = [...res.data]
                this.showUserPopup = true
            }).catch(error=>{
                this.loading = false
            })
        },
        showUser(){
            if(!this.userList || !this.userList.length){
                return
            }
            this.showUserPopup = true
        },
        selectUser(val){
            this.saveData.auth_id = val.selectedOptions[0].value
            this.saveData.auth_name = val.selectedOptions[0].text
            this.showUserPopup = false
        },
        toSave(){
            if(this.loading){
                console.log('操作过快')
                return
            }
            if(!this.saveData.auth_id){
                showToast('请指定主播')
                return
            }
            this.loading = true
            this.$http.postData("/Room/createRoom",{
                ...this.saveData,
            }).then(res=>{
                this.loading = false
                this.onCallBack(res)
            }).catch(error=>{
                this.loading = false
                console.log(error)
            })
        }
    },
  }
  </script>
  <style scoped>
  .conts{
  }
  </style>
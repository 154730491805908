// 海报图
export function drawPoster(data) {
    return new Promise(function(resolve) {
      switch (data.type) {
        case 1:
          resolve(poster1(data))
          break
        case 2:
            resolve(poster2(data))
            break
        case 3:
            resolve(poster3(data))
            break
        case 4:
            resolve(poster4(data))
            break
        case 5:
            resolve(poster5(data))
            break
        case 6:
            resolve(poster6(data))
            break
        case 7:
          resolve(poster7(data))
          break
        default:
          resolve(poster1(data))
      }
    })
  }
  const bWidth = `${document.documentElement.clientWidth}px`
  const bHeight = `${document.documentElement.clientWidth * 1.8}px`

  // demo 微信分享图
  function poster1(data) {
    return {
        width: bWidth,
        height: bHeight,
        borderRadius:'10px',
        background: '#ffffff',
        views: [
        {
            type: "image",
            url: "/storage/bg/post1.png",
            css: {
              width: bWidth,
              height: bHeight,
              borderRadius:'10px',
              top: "0px",
              bottom:"0px"
            },
          },
          {
            type: "image",
            url: data.info.avatar,
            css: {
              width: `80px`,
              height: `80px`,
              margin: "0 auto",
              top: `100px`,
              left: `${(document.documentElement.clientWidth-80) / 2}px`,
              borderRadius:'100%',
              borderColor:'rgba(255,255,255,0.8)',
              borderWidth:'3px',
            },
          },
          {
            type: "text",
            text: data.info.nickname,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `200px`,
                left: `0px`,
                color: "#0b7387",
                fontSize: "20px",
              },
            ],
          },
          {
            type: "text",
            text: "邀请您一起看直播",
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `230px`,
                left: `0px`,
                color: "#0b7387",
                fontSize: "17px",
              },
            ],
          },
          {
            type: "text",
            text: data.info.times,
            css: [
              {
                width:bWidth,
                textAlign:"right",
                bottom: `40px`,
                right: `20px`,
                color: "#0b7387",
                fontSize: "17px",
              },
            ],
          },
          {
            type: "qrcode",
            content: data.info.codeUrl,
            css: {
              top: `340px`,
              left: `${(document.documentElement.clientWidth-120) / 2}px`,
              color: data.info.has_auth?'#ff0000':"#32acd2",
              borderWidth:"4px",
              borderColor:"#ffffff",
              background:"#FFFFFF",
              width: `120px`,
              height: `120px`,
            },
          },{
            type: "text",
            text: '主题：'+data.info.title,
            css: [
              {
                width:bWidth,
                textAlign:"right",
                bottom: `70px`,
                right: `20px`,
                color: "#0b7387",
                fontSize: "16px",
              },
            ],
          },
          {
            type: "text",
            text: "- 长按识别 立即观看 -",
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `480px`,
                left: `0px`,
                color: "#167390",
                fontSize: "17px",
              },
            ],
          },
        ],
      }
  }

  function poster2(data) {
    return {
        width: bWidth,
        height: bHeight,
        borderRadius:'10px',
        background: '#ffffff',
        views: [
        {
            type: "image",
            url: "/storage/bg/post2.png",
            css: {
              width: bWidth,
              height: bHeight,
              borderRadius:'10px',
              top: "0px",
              bottom:"0px"
            },
          },
          {
            type: "image",
            url: data.info.avatar,
            css: {
              width: `80px`,
              height: `80px`,
              margin: "0 auto",
              top: `100px`,
              left: `${(document.documentElement.clientWidth-80) / 2}px`,
              borderRadius:'100%',
              borderColor:'rgba(255,255,255,0.8)',
              borderWidth:'3px',
            },
          },
          {
            type: "text",
            text: data.info.nickname,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `200px`,
                left: `0px`,
                color: "#ef9443",
                fontSize: "20px",
              },
            ],
          },
          {
            type: "text",
            text: "邀请您一起看直播",
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `230px`,
                left: `0px`,
                color: "#955419",
                fontSize: "17px",
              },
            ],
          },{
            type: "text",
            text: '主题：'+data.info.title,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                bottom: `70px`,
                left: `0px`,
                color: "#955419",
                fontSize: "16px",
              },
            ],
          },
          {
            type: "text",
            text: data.info.times,
            css: [
              {
                width:bWidth,
                left: `0px`,
                bottom:'30px',
                textAlign:"center",
                color: "#955419",
                fontSize: "17px",
              },
            ],
          },
          {
            type: "qrcode",
            content: data.info.codeUrl,
            css: {
              top: `360px`,
              left: `${document.documentElement.clientWidth / 3}px`,
              color: data.info.has_auth?'#ff0000':"#ef9443",
              borderWidth:"8px",
              borderColor:"#FFFFFF",
              background:"#FFFFFF",
              width: `${document.documentElement.clientWidth / 3}px`,
              height: `${document.documentElement.clientWidth / 3}px`,
            },
          },
          {
            type: "text",
            text: "- 长按识别 立即观看 -",
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `530px`,
                left: `0px`,
                color: "#ddd",
                fontSize: "17px",
              },
            ],
          },
        ],
    }
  }

  function poster3(data) {
    return {
        width: bWidth,
        height: bHeight,
        borderRadius:'10px',
        background: '#ffffff',
        views: [
        {
            type: "image",
            url: "/storage/bg/post3.png",
            css: {
              width: bWidth,
              height: bHeight,
              borderRadius:'10px',
              top: "0px",
              bottom:"0px"
            },
          },
          {
            type: "image",
            url: data.info.avatar,
            css: {
              width: `80px`,
              height: `80px`,
              margin: "0 auto",
              top: `100px`,
              left: `${(document.documentElement.clientWidth-80) / 2}px`,
              borderRadius:'100%',
              borderColor:'rgba(255,255,255,0.8)',
              borderWidth:'3px',
            },
          },
          {
            type: "text",
            text: data.info.nickname,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `200px`,
                left: `0px`,
                color: "#f3f3f3",
                fontSize: "20px",
              },
            ],
          },
          {
            type: "text",
            text: "邀请您一起看直播",
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `230px`,
                left: `0px`,
                color: "#f3f3f3",
                fontSize: "16px",
              },
            ],
          },{
            type: "text",
            text: '主题：'+data.info.title,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                bottom: `70px`,
                left: `0px`,
                color: "#f3b60f",
                fontSize: "16px",
              },
            ],
          },
          {
            type: "text",
            text: data.info.times,
            css: [
              {
                width:bWidth,
                bottom: `40px`,
                left: `0px`,
                bottom:'30px',
                textAlign:"center",
                color: "#f3b60f",
                fontSize: "17px",
              },
            ],
          },
          {
            type: "qrcode",
            content: data.info.codeUrl,
            css: {
              top: `360px`,
              left: `${(document.documentElement.clientWidth-120) / 2}px`,
              color: data.info.has_auth?'#ff0000':"#ef9443",
              borderWidth:"8px",
              borderColor:"#FFFFFF",
              background:"#FFFFFF",
              width: `120px`,
              height: `120px`,
            },
          },
          {
            type: "text",
            text: "- 长按识别 立即观看 -",
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `510px`,
                left: `0px`,
                color: "#999999",
                fontSize: "17px",
              },
            ],
          },
        ],
    }
  }
  function poster4(data) {
    return {
        width: bWidth,
        height: bHeight,
        borderRadius:'10px',
        background: '#ffffff',
        views: [
        {
            type: "image",
            url: "/storage/bg/post4.png",
            css: {
              width: bWidth,
              height: bHeight,
              borderRadius:'10px',
              top: "0px",
              bottom:"0px"
            },
          },
          {
            type: "image",
            url: data.info.avatar,
            css: {
              width: `60px`,
              height: `60px`,
              margin: "0 auto",
              top: `30px`,
              left: `30px`,
              borderRadius:'100%',
              borderRadius:'100%',
              borderColor:'rgba(255,255,255,0.8)',
              borderWidth:'3px',
            },
          },
          {
            type: "text",
            text: data.info.nickname,
            css: [
              {
                width:`200px`,
                textAlign:"left",
                top: `30px`,
                left: `110px`,
                color: "#f67c7c",
                fontSize: "24px",
              },
            ],
          },
          {
            type: "text",
            text: "邀请您一起看直播",
            css: [
              {
                width:`300px`,
                textAlign:"left",
                top: `70px`,
                left: `110px`,
                color: "#f3f3f3",
                fontSize: "16px",
              },
            ],
          },{
            type: "text",
            text: '主题：'+data.info.title,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                bottom: `70px`,
                left: `0px`,
                color: "#f3f3f3",
                fontSize: "16px",
              },
            ],
          },
          {
            type: "text",
            text: data.info.times,
            css: [
              {
                width:bWidth,
                bottom: `40px`,
                left: `0px`,
                textAlign:"center",
                color: "#dddddd",
                fontSize: "17px",
              },
            ],
          },
          {
            type: "qrcode",
            content: data.info.codeUrl,
            css: {
              top: `180px`,
              left: `${(document.documentElement.clientWidth-160) / 2}px`,
              color: data.info.has_auth?'#ff0000':"#be5347",
              borderWidth:"6px",
              borderColor:"#ffffff",
              background:"#FFFFFF",
              width: `160px`,
              height: `160px`,
            },
          },
          {
            type: "text",
            text: "- 长按识别 立即观看 -",
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `370px`,
                left: `0px`,
                color: "#999999",
                fontSize: "17px",
              },
            ],
          },
        ],
    }
  }

  function poster5(data) {
    return {
        width: bWidth,
        height: bHeight,
        borderRadius:'10px',
        background: '#ffffff',
        views: [
        {
            type: "image",
            url: "/storage/bg/post5.png",
            css: {
              width: bWidth,
              height: bHeight,
              borderRadius:'10px',
              top: "0px",
              bottom:"0px"
            },
          },
          {
            type: "image",
            url: data.info.avatar,
            css: {
              width: `80px`,
              height: `80px`,
              margin: "0 auto",
              top: `100px`,
              left: `${(document.documentElement.clientWidth-80) / 2}px`,
              borderRadius:'100%',
              borderColor:'rgba(255,255,255,0.8)',
              borderWidth:'3px',
            },
          },
          {
            type: "text",
            text: data.info.nickname,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `210px`,
                left: `0px`,
                color: "#f3f3f3",
                fontSize: "20px",
              },
            ],
          },
          {
            type: "text",
            text: "邀请您一起看直播",
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `240px`,
                left: `0px`,
                color: "#f3f3f3",
                fontSize: "16px",
              },
            ],
          },{
            type: "text",
            text: '主题：'+data.info.title,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                bottom: `70px`,
                left: `0px`,
                color: "#ffcc00",
                fontSize: "16px",
              },
            ],
          },
          {
            type: "text",
            text: data.info.times,
            css: [
              {
                width:bWidth,
                top: `${document.documentElement.clientWidth / 2 +60}px`,
                left: `0px`,
                bottom:'30px',
                textAlign:"center",
                color: "#ffcc00",
                fontSize: "17px",
              },
            ],
          },
          {
            type: "qrcode",
            content: data.info.codeUrl,
            css: {
              top: `330px`,
              left: `${ (document.documentElement.clientWidth-150)/2 }px`,
              color: data.info.has_auth?'#ff0000':"#3c47a3",
              borderWidth:"8px",
              borderColor:"#FFFFFF",
              background:"#FFFFFF",
              width: `150px`,
              height: `150px`,
            },
          },
          {
            type: "text",
            text: "- 长按识别 立即观看 -",
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `510px`,
                left: `0px`,
                color: "#f3f3f3",
                fontSize: "17px",
              },
            ],
          },
        ],
    }
  }
  
  function poster6(data) {
    return {
        width: bWidth,
        height: bHeight,
        borderRadius:'10px',
        background: '#ffffff',
        views: [
        {
            type: "image",
            url: "/storage/bg/post6.png",
            css: {
              width: bWidth,
              height: bHeight,
              borderRadius:'10px',
              top: "0px",
              bottom:"0px"
            },
          },
          {
            type: "image",
            url: data.info.avatar,
            css: {
              width: `80px`,
              height: `80px`,
              margin: "0 auto",
              top: `100px`,
              left: `${(document.documentElement.clientWidth-80) / 2}px`,
              borderRadius:'100%',
              borderColor:'rgba(255,255,255,0.8)',
              borderWidth:'3px',
            },
          },
          {
            type: "text",
            text: data.info.nickname,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `210px`,
                left: `0px`,
                color: "#1c6764",
                fontSize: "20px",
              },
            ],
          },
          {
            type: "text",
            text: "邀请您一起看直播",
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `240px`,
                left: `0px`,
                color: "#1c6764",
                fontSize: "16px",
              },
            ],
          },{
            type: "text",
            text: '主题：'+data.info.title,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                bottom: `70px`,
                left: `0px`,
                color: "#1c6764",
                fontSize: "16px",
              },
            ],
          },
          {
            type: "text",
            text: data.info.times,
            css: [
              {
                width:bWidth,
                top: `${document.documentElement.clientWidth / 2 +60}px`,
                left: `0px`,
                bottom:'30px',
                textAlign:"center",
                color: "#1c6764",
                fontSize: "17px",
              },
            ],
          },
          {
            type: "qrcode",
            content: data.info.codeUrl,
            css: {
              top: `320px`,
              left: `${(document.documentElement.clientWidth-150) / 2}px`,
              color: data.info.has_auth?'#ff0000':"#1c6764",
              borderWidth:"8px",
              borderColor:"#f3f3f3",
              background:"#FFFFFF",
              width: `150px`,
              height: `150px`,
            },
          },
          {
            type: "text",
            text: "- 长按识别 立即观看 -",
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `500px`,
                left: `0px`,
                color: "#1c6764",
                fontSize: "17px",
              },
            ],
          },
        ],
    }
  }

  function poster7(data) {
    return {
        width: bWidth,
        height: bHeight,
        borderRadius:'10px',
        background: '#ffffff',
        views: [
        {
            type: "image",
            url: "/storage/bg/post7.png",
            css: {
              width: bWidth,
              height: bHeight,
              borderRadius:'10px',
              top: "0px",
              bottom:"0px"
            },
          },
          {
            type: "image",
            url: data.info.avatar,
            css: {
              width: `80px`,
              height: `80px`,
              margin: "0 auto",
              top: `100px`,
              left: `${(document.documentElement.clientWidth-80) / 2}px`,
              borderRadius:'100%',
              borderColor:'rgba(255,255,255,0.8)',
              borderWidth:'3px',
            },
          },
          {
            type: "text",
            text: data.info.nickname,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `210px`,
                left: `0px`,
                color: "#0b7387",
                fontSize: "20px",
              },
            ],
          },
          {
            type: "text",
            text: "邀请您一起看直播",
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `240px`,
                left: `0px`,
                color: "#0b7387",
                fontSize: "16px",
              },
            ],
          },{
            type: "text",
            text: '主题：'+data.info.title,
            css: [
              {
                width:bWidth,
                textAlign:"center",
                bottom: `70px`,
                left: `0px`,
                color: "#0b7387",
                fontSize: "16px",
              },
            ],
          },{
            type: "text",
            text: data.info.times,
            css: [
              {
                width:bWidth,
                top: `${document.documentElement.clientWidth / 2 +60}px`,
                left: `0px`,
                bottom:'30px',
                textAlign:"center",
                color: "#0b7387",
                fontSize: "17px",
              },
            ],
          },
          {
            type: "qrcode",
            content: data.info.codeUrl,
            css: {
              top: `340px`,
              left: `${(document.documentElement.clientWidth-150) / 2}px`,
              color: data.info.has_auth?'#ff0000':"#26a1b8",
              borderWidth:"8px",
              borderColor:"#ffffff",
              background:"#FFFFFF",
              width: `150px`,
              height: `150px`,
            },
          },
          {
            type: "text",
            text: "- 长按识别 立即观看 -",
            css: [
              {
                width:bWidth,
                textAlign:"center",
                top: `520px`,
                left: `0px`,
                color: "#f3f3f3",
                fontSize: "17px",
              },
            ],
          },
        ],
    }
  }
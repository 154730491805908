<template>
  <div class="hello">
    <van-sticky>
      <van-nav-bar
        title="联系方式"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
      ></van-nav-bar>
    </van-sticky>
    <div style="text-align: center">
      <div style="text-align: center; padding: 20px 0">
        <van-uploader
          v-model="fileList"
          :preview-image="true"
          :max-count="1"
          :after-read="afterRead"
        ></van-uploader>
      </div>
      <div style="color: #999; font-size: 14px; line-height: 24px">
        请上传您的微信二维码<br />以便新用户联系您进行认证
      </div>
    </div>
  </div>
</template>
  
<script>
import { ref } from "vue";
import { showImagePreview } from "vant";
export default {
  name: "roomList",
  data() {
    return {
      loading: false,
      qrcodeUrl: false,
    };
  },
  setup() {
    const fileList = ref([]);
    return {
      fileList,
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      this.$http
        .postData("/User/getUserInfo", {})
        .then((res) => {
          this.qrcodeUrl = res.qrcode && res.qrcode != "" ? res.qrcode : false;
          this.fileList = [];
          if (res.qrcode && res.qrcode != "") {
            this.fileList.push({
              name: res.nickname,
              url: res.qrcode,
            });
          }
        })
        .catch((error) => {});
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    viewImg() {
      showImagePreview([this.qrcodeUrl]);
    },
    afterRead(file, name) {
      let params = new FormData(); //创建form对象
      params.append("files", file.file);
      this.$http
        .uploadPic("/Upload/uploadQrcode", params)
        .then((res) => {
          localStorage.setItem("userInfo", JSON.stringify(res));
          this.getUserInfo();
        })
        .catch((error) => {});
    },
  },
};
</script>
  <style scoped>
</style>
  
<template>
    <div class="hello">
        <van-nav-bar
        :title="pageTitle"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        >
        <template #right v-if="step==1">
            <div style="border-radius: 4px;background-color:#1989fa;font-size:0.8rem;text-align: center; color: #fff;padding:4px 8px;">
                <van-icon name="plus" style="color: #fff;" size="12" />创建
            </div>
        </template>
        </van-nav-bar>
        <!-- 直播间列表 -->
        <room-list v-if="step==1"></room-list>
        <!-- 创建直播间 -->
        <create-room v-if="step==2" @callBack="afterSave"></create-room>
    </div>
</template>
  
<script>
import RoomList from '../components/RoomList.vue'
import CreateRoom from '../components/CreateRoom.vue'
  export default {
    name: 'admin',
    data(){
        return {
            userInfo:false,
            step:1,
            pageTitle:'房间管理'
        }
    },
    components:{
        RoomList,
        CreateRoom
    },
    mounted() {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.actName = (this.$route.params.actName!=null && this.$route.params.actName) ? this.$route.params.actName : false
      window.document.title = this.pageTitle
    },
    methods: {
        onClickLeft(){
            if(this.step==1){
                this.$router.go(-1)
            }else{
                this.step = 1
                window.document.title = this.pageTitle = "房间管理"
            }
        },
        afterSave(res){
            this.step = 1
            window.document.title = this.pageTitle = "房间管理"
        },
        onClickRight(){
            this.step = 2
            window.document.title = this.pageTitle = "创建房间"
        }
    },
  }
  </script>
  <style scoped>
    
  </style>
  
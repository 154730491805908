<template>
  <div class="conts">
    <div class="add">
      <van-button
        round
        style="width: 100px"
        size="small"
        type="primary"
        @click.stop="addLink"
        >创建链接</van-button
      >
    </div>

    <!-- 创建链接 -->
    <van-popup
      v-model:show="showLinkBox"
      round
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <van-cell-group inset label-width="auto">
        <van-field
          v-model="saveData.title"
          :maxlength="10"
          label="备注"
          type="text"
          placeholder="选填,方便自己区分"
        ></van-field>
        <van-field label="自动认证">
          <template #input>
            <div>
              <div><van-switch v-model="saveData.has_auth" /></div>
              <div style="font-size: 0.6rem; color: #cc0000">
                开启后,用户识别进入自动认证
              </div>
            </div>
          </template>
        </van-field>
        <van-field label="设为团队长">
          <template #input>
            <div>
              <div><van-switch v-model="saveData.has_grade" /></div>
              <div style="font-size: 0.6rem; color: #cc0000">
                开启后,用户将自动认证为团队长
              </div>
            </div>
          </template>
        </van-field>
        <van-field
          label="锁客上限"
          v-if="saveData.has_auth || saveData.has_grade"
        >
          <template #input>
            <div>
              <div>
                <van-stepper
                  v-model="saveData.max_count"
                  :min="0"
                  :max="9999999"
                />
              </div>
              <div style="font-size: 0.6rem; color: #cc0000">
                免认证模式必须设置锁客上限
              </div>
            </div>
          </template>
        </van-field>
        <van-field label="线上发放">
          <template #input>
            <div>
              <div>
                <van-switch
                  :active-value="1"
                  :inactive-value="0"
                  v-model="saveData.online"
                />
              </div>
              <div
                v-if="saveData.online"
                style="font-size: 0.6rem; color: #cc0000"
              >
                请确保【营销费用】充足
              </div>
              <div v-else style="font-size: 0.6rem; color: #cc0000">
                关闭后，奖励自行在线下发放
              </div>
            </div>
          </template>
        </van-field>
        <van-field label="最低金额">
          <template #input>
            <div>
              <div>
                <van-stepper
                  v-model="saveData.amount_min"
                  step="0.01"
                  :decimal-length="2"
                  :min="0"
                  :max="10"
                />元
              </div>
              <div style="font-size: 0.6rem; color: #cc0000">最低金额0元</div>
            </div>
          </template>
        </van-field>
        <van-field label="最高金额">
          <template #input>
            <van-stepper
              v-model="saveData.amount_max"
              step="0.01"
              :decimal-length="2"
              :min="0"
              :max="10"
            />元
            <div style="font-size: 0.6rem; color: #cc0000">最高金额10元</div>
          </template>
        </van-field>
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="primary" size="small" @click="saveLink"
          >保存</van-button
        >
      </div>
    </van-popup>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        :loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          class="itemList"
          :class="
            !item.c_status || !item.r_status || !item.status ? 'disitem' : ''
          "
          v-for="item in dataList"
          :key="item.id"
        >
          <div class="title">
            名称: {{ item.caster_title }}
            <van-tag
              round
              v-if="!item.c_status || !item.r_status || !item.status"
              style="
                margin-right: 4px;
                background-color: rgba(220, 100, 11, 0.8);
              "
              type="warning"
              >已停用</van-tag
            >
            <van-tag
              round
              v-if="item.has_auth > 0"
              style="
                margin-right: 4px;
                background-color: rgba(200, 90, 220, 0.8);
              "
              type="warning"
              >证</van-tag
            >
            <van-tag
              round
              v-if="item.has_grade > 0"
              style="
                margin-right: 4px;
                background-color: rgba(110, 150, 220, 0.8);
              "
              type="warning"
              >团</van-tag
            >
            <van-tag
              round
              v-if="!item.online"
              style="
                margin-right: 4px;
                background-color: rgba(105, 45, 180, 0.8);
              "
              type="warning"
              >线下</van-tag
            >
          </div>
          <div class="amount" v-if="item.amount_min != item.amount_max">
            奖励:
            <span class="price"
              >￥{{ item.amount_min / 100 + "~" + item.amount_max / 100 }}</span
            >
          </div>
          <div class="amount" v-else>
            奖励: <span class="price">￥{{ item.amount_min / 100 }}</span>
          </div>
          <div class="amount">
            锁客:
            <span class="price"
              >{{ item.count_info.user_count }}人
              <span v-if="item.has_auth || item.has_grade"
                >(上限{{ item.max_count }})</span
              ></span
            >
            、发放红包
            <span class="price">{{ item.count_info.prize_count }}个</span>
            (合<span class="price">{{ item.count_info.prize_amount }}</span
            >)
          </div>
          <!-- <div class="times">创建时间 {{ item.create_time }}</div> -->
          <div class="right_btn">
            <div class="item1" @click.stop="makeShare(item)">
              <van-icon name="qr" />生成海报
            </div>
            <div class="item4" @click.stop="goMyLink(item)">直接访问</div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { showToast } from "vant";
export default {
  name: "shareLinkList",
  props: {
    casterId: {
      default: 0,
    },
    roomId: {
      default: 0,
    },
    setAuth: {
      default: 0,
    },
    collectId: {
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      refreshing: false,
      finished: false,
      error: false,
      dataList: [],
      per_page: 10,
      last_page: 0,
      current_page: 0,
      total: 0,
      showLinkBox: false,
      saveData: {
        has_auth: 0,
        has_grade: 0,
        max_count: 0,
        caster_id: "",
        caster_title: "",
        room_id: "",
        room_title: "",
        title: "",
        online: 1,
        amount_min: 0.1,
        amount_max: 3,
      },
    };
  },
  mounted() {
    this.onRefresh();
  },
  setup(props, { emit }) {
    function makeShare(val) {
      emit("onCallBack", val);
    }
    return { makeShare };
  },
  methods: {
    addLink() {
      this.saveData.caster_id = this.casterId;
      this.saveData.room_id = this.roomId;
      this.showLinkBox = true;
    },
    onRefresh() {
      this.dataList = [];
      this.current_page = 0;
      this.refreshing = true;
      this.onLoad();
    },
    onLoad() {
      if (this.inLoad) {
        return;
      }
      this.inLoad = true;
      this.$http
        .postData("/RowList/getList", {
          table: "ShareLink",
          hide_success: true,
          search: {
            scence: "shareLink",
            caster_id: this.casterId,
            collect_id: this.collectId,
            room_id: this.roomId,
          },
          pageInfo: {
            page_size: this.per_page,
          },
          page: this.current_page + 1,
        })
        .then((res) => {
          this.inLoad = false;
          this.refreshing = false;

          res.data.map((item) => {
            this.dataList.push(item);
          });
          this.current_page = res.current_page;
          this.total = res.total;

          if (res.last_page == res.current_page || res.last_page == 0) {
            this.loading = false;
            this.finished = true;
          }
        })
        .catch((error) => {
          this.inLoad = false;
          this.finished = true;
          this.refreshing = false;
        });
    },
    goMyLink(item) {
      let codeUrl = "https://m.qianbaiyou88.com/detail/" + item.code;
      if (codeUrl != window.location.href) {
        window.location.href = codeUrl;
      } else {
        showToast("已经在该页面");
      }
    },
    saveLink() {
      if (!this.saveData.caster_id) {
        showToast("请选择房间场次");
        return;
      }
      if (this.saveData.has_grade) {
        this.saveData.has_auth = 1;
      }
      if (
        (this.saveData.has_auth || this.saveData.has_grade) &&
        parseInt(this.saveData.max_count) == 0
      ) {
        showToast("请设置锁客上限");
        return;
      }
      if (parseInt(this.saveData.amount_min * 100) < 0) {
        showToast("最低金额不低于0元");
        return;
      }
      if (parseInt(this.saveData.amount_max * 100) > 1000) {
        showToast("最高金额不大于10元");
        return;
      }
      this.$http
        .postData("/ShareLink/makeLink", {
          ...this.saveData,
          hide_success: false,
        })
        .then((res) => {
          this.showLinkBox = false;
          this.onRefresh();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
  <style scoped>
.conts {
  min-height: 50vh;
}
.add {
  position: fixed;
  z-index: 10;
  bottom: 20px;
  left: 20px;
  right: 20px;
  text-align: center;
}
.itemList {
  padding: 10px;
  margin: 10px;
  margin-top: 0px;
  font-size: 12px;
  line-height: 30px;
  background: #ffffff;
  margin-top: 20px;
  box-shadow: 0 4px 10px 0px rgba(30, 157, 249, 0.5);
  position: relative;
}
.disitem {
  box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.3);
}
.itemList .title {
  font-size: 12px;
  line-height: 30px;
}
.itemList .amount {
}
.itemList .amount .price {
  color: #cc0000;
}
.times {
  color: #666666;
}
.right_btn {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 10px;
}
.right_btn .item1 {
  background: rgb(56 141 212);
  border-radius: 6px;
  height: 24px;
  line-height: 24px;
  padding: 0 10px;
  font-size: 0.7rem;
  color: #ffffff;
}
.right_btn .item2 {
  background: rgb(237, 157, 60);
  border-radius: 6px;
  height: 24px;
  line-height: 24px;
  padding: 0 10px;
  font-size: 0.7rem;
  color: #ffffff;
  margin: 20px 0;
  margin-bottom: 0;
}
.right_btn .item3 {
  background: rgb(198, 198, 198);
  border-radius: 6px;
  height: 24px;
  line-height: 24px;
  padding: 0 10px;
  font-size: 0.7rem;
  color: #ffffff;
  margin: 20px 0;
  margin-bottom: 0;
}
.right_btn .item4 {
  background: rgb(48, 204, 150);
  border-radius: 6px;
  height: 24px;
  line-height: 24px;
  padding: 0 10px;
  font-size: 0.7rem;
  color: #ffffff;
  margin: 20px 0;
  margin-bottom: 0;
}
</style>
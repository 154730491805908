<template>
    <div class="hello">
    <van-sticky>
    <van-nav-bar :title="pageTitle" :left-text="leftText" :right-text="rightText" left-arrow @click-left="onClickLeft" @click-right="onClickRight">
      <!-- <template #right>
          <van-icon name="chart-trending-o" size="18" />
        </template> -->
    </van-nav-bar>
    <van-tabs v-model:active="nowTab" @click-tab="onClickTab" sticky class="countBox">
        <van-tab :title="item.title+item.value" :name="item.tag" :key="idx" v-for="(item,idx) in countInfo"></van-tab>
    </van-tabs>
    <van-notice-bar :scrollable="false" v-if="tips" left-icon="volume-o" scrollable :text="tips" />
    </van-sticky>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          :loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getDataList"
          >
            <!-- 用户数据 -->
            <div v-for="(item,idx) in dataList" :key="idx">
                <div class="user_list" @click="selectUser(item)">
                    <div class="avatar">
                    <van-image style="width: 100%;height: 100%;" round :src="item.avatar" />
                    </div>
                    <div class="head">
                        <div>
                        <div class="title">
                            {{ item.nickname }}
                        </div>
                        <div>
                            <van-tag style="margin-right: 4px;" v-if="item.auth==1" round type="success">证</van-tag>
                            <van-tag style="margin-right: 4px;" v-else round color="#aaaaaa">未认证</van-tag>
                            <van-tag round v-if="item.grade>0" style="margin-right: 4px;" type="warning">团</van-tag>
                            <van-tag round v-if="item.zuli>0" style="margin-right: 4px;background-color:rgba(18,40,180,0.8);" type="warning">助理</van-tag>
                            <van-tag round v-if="item.is_zb>0" style="margin-right: 4px;background-color:rgba(18,163,220,0.8);" type="warning">主</van-tag>
                            <van-tag round v-if="item.black>0" style="margin-right: 4px;background-color:rgba(0,0,0,0.6);" type="warning">黑</van-tag>
                            <van-tag round v-if="item.comment_status==0" style="margin-right: 4px;background-color:rgba(0,0,0,0.6);" type="warning">禁</van-tag>
                        </div>
                        <div class="mark">{{ item.ip_info?item.ip_info.province+'.'+item.ip_info.city+'.'+item.ip_info.area:'-' }}</div>
                        </div>
                        <div class="times" style="line-height: 26px;">
                        <span>邀请 <span style="color: #cc0000;">{{ item.share_count_info.childCount }}</span> 人</span>
                        <br/>
                        <span>观看 <span style="color: #cc0000;">{{ item.share_count_info.times }}</span></span>
                        <br/>
                        <span>红包 <span style="color: #cc0000;">{{ item.share_count_info.prizeCount }}</span></span>
                        </div>
                    </div>
                </div>
            </div>
          <template v-if="searchForm.actTag=='question_list'">
            <!-- 答题奖励 -->
            <div class="user_list" v-for="(item,idx) in dataList" :key="idx">
              <div class="avatar">
                  <img :src="item.avatar"/>
              </div>
              <div class="head">
                <div style="text-align: left;">
                  <div class="title">{{ item.nickname }}</div>
                  <div class="times">{{ item.create_time }}</div>
                </div>
                <div class="infos">
                  <div class="price">￥{{ item.amount }}</div>
                </div>
              </div>
            </div>
          </template>
        
          </van-list>
        </van-pull-refresh>

      <!-- 对用户进行操作 -->
      <van-popup v-model:show="showUserAction" round position="bottom" :style="{ minHeight: '30%' }">
        <template #default>
                <div class="apps_new">
                    <div class="title van-hairline--bottom">【管理】{{selectItem.nickname}}</div>
                        <van-grid :column-num="4" :gutter="10" square>
                            <van-grid-item v-for="(menu,idx) in userOptions" :key="idx" @click="selectUserActionDo(menu)">
                                <div class="item">
                                    <van-icon class="icon" :style="{color:menu.color}" :name="menu.icon" />
                                    <div>{{ menu.name }}</div>
                                </div>
                            </van-grid-item>
                        </van-grid>
                </div>
            </template>
        </van-popup>
    </div>
</template>
  
<script>
import { showToast,showConfirmDialog } from 'vant';
  export default {
    name: 'userData',
    props: {
      pageTitle: {
            type:String,
            default:"标题"
        },
        leftText: {
            type:String,
            default:"返回"
        },
        tips: {
            type:String,
            default:""
        },rightText: {
            type:String,
            default:""
        },
        detail: {
            type:Object,
            default:{}
        },
    },
    setup (props,{emit}) {
        function onCallBack(val){
            emit('callBack',val); 
        }
        function clickRightCallBack(val){
            emit('clickRight',val); 
        }
        return {onCallBack,clickRightCallBack}
    },
    data(){
        return {
            nowTab:'all',
          activeName:'share_list',//当前查询项目
          showUserAction:false,
          userInfo:false,//用户信息
          loading:false,
          finished:false,
          refreshing:false,
          error:false,
          dataList:[],
          per_page:10,
          last_page:0,
          current_page:0,
          total:0,
          searchForm:{
            actTag:'all'
          },
          userOptions:[],
          showUserAction:false,
          countInfo:[],
          casterInfo:false
        }
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.casterInfo = JSON.parse(localStorage.getItem('casterInfo'))
        window.document.title = this.pageTitle
        if(!this.detail.caster_id || !this.detail.room_id){
            showToast('参数错误')
            return
        }
        this.searchForm = {
          scence:this.detail.scence,
          actTag:"all",
          room_id:this.detail.room_id,
          caster_id:this.detail.caster_id,
        }
    },
    methods: {
      selectUser(item){
          // 团队长操作用户
          this.selectItem = item
          this.userOptions = []
          if(item.auth>0){
            this.userOptions.push({ name: '取消认证', icon: 'good-job' ,act:'auth_cancle',color:'#666666'})
          }else{
            this.userOptions.push({ name: '认证', icon: 'good-job' ,act:'auth_ok',color:'#23cc77'})
          }
          if(item.grade>0){
            this.userOptions.push({ name: '取消团队长', icon: 'manager' ,act:'down_grade',color:'#666666'})
          }else{
            this.userOptions.push({ name: '设为团队长', icon: 'manager' ,act:'up_grade',color:'#1ed6e0'})
          }
          if(item.zuli>0){
            this.userOptions.push({ name: '取消助理', icon: 'good-job' ,act:'down_zuli',color:'#666666'})
          }else{
            this.userOptions.push({ name: '设为助理', icon: 'good-job' ,act:'up_zuli',color:'#1ed6e0'})
          }
          if(item.black>0){
            this.userOptions.push({ name: '恢复', icon: 'cross' ,act:'in_black',color:'#23cc77'})
          }else{
            this.userOptions.push({ name: '踢出房间', icon: 'cross' ,act:'out_black',color:'#666666'})
          }
          if(item.comment_status==0){
            this.userOptions.push({ name: '开启评论', icon: 'cross' ,act:'open_comment',color:'#23cc77'})
          }else{
            this.userOptions.push({ name: '禁止评论', icon: 'cross' ,act:'close_comment',color:'#666666'})
          }
          this.showUserAction = true
      },
      selectUserActionDo(opt){
        if(opt.act=='auth_ok' || opt.act=='auth_cancle'){
            // 进行认证操作
            this.changeAuthStatus('auth',`确认【${opt.name}】吗？`)
          }else if(opt.act=='down_grade' || opt.act=='up_grade'){
            // 进行认证操作
            this.changeAuthStatus('grade',`确认【${opt.name}】吗？`)
          }else if(opt.act=='in_black' || opt.act=='out_black'){
            // 进行认证操作
            this.changeAuthStatus('black',`确认【${opt.name}】吗？`)
          }else if(opt.act=='down_zb' || opt.act=='up_zb'){
            // 设置主播身份
            this.changeAuthStatus('is_zb',`确认【${opt.name}】吗？`)
          }else if(opt.act=='down_zuli' || opt.act=='up_zuli'){
            // 设置助理身份
            this.changeAuthStatus('zuli',`确认【${opt.name}】吗？`)
          }else if(opt.act=='open_comment' || opt.act=='close_comment'){
            // 修改禁言状态
            this.changeAuthStatus('comment_status',`确认【${opt.name}】吗？`)
          }else{
              showToast("不支持的操作")
          }
      },
      changeAuthStatus(field,tips){
            showConfirmDialog({
              title: '请确认',
              message:tips
            }).then(() => {
            this.$http.postData('/ShareLog/changeStatus',{
                user_id:this.selectItem.user_id,
                caster_id:this.selectItem.caster_id,
                share_id:this.selectItem.share_id,
                field:field,
                hide_success:false
            }).then(res=>{
                this.showUserAction = false
                this.onRefresh()
            }).catch(error=>{
            })
            })
            .catch(() => {
            // on cancel
            });
        },
      onRefresh(){
            this.dataList=[]
            this.current_page=0
            this.refreshing = true
            this.getDataList()
        },
      onClickTab(val){
        this.nowTab = val.name
        this.searchForm.actTag = val.name
        this.onRefresh()
    },
      onClickLeft(){
        this.$router.go(-1)
        return
      },
      onClickRight(){
        // this.getDataCount()
      },
      onClickItem(val){
        this.onCallBack(val)
      },
      getDataCount(){
        this.$http.postData("/RowList/getDataCount",{...this.searchForm,table:'ShareLog'}).then(res=>{
            this.countInfo = res
        }).catch(error=>{
            console.log(error)
        })
      },
      getDataList(){
        if(this.inLoad){
          return
        }
        this.inLoad = true
        this.$http.postData('/RowList/getList',{
          table:'ShareLog',
          search:this.searchForm,
          pageInfo:{
            page_size: this.per_page,
          },
          page: this.current_page+1,
          hide_success:true
        }).then(res=>{
            this.inLoad = false
            this.loading = false
            this.refreshing = false
            res.data.map(item=>{
              this.dataList.push(item)
            })
            this.current_page = res.current_page
            this.total = res.total

            if(res.last_page == res.current_page || res.last_page==0){
                this.finished = true
            }
            if(res.current_page == 1){
                this.getDataCount()
            }
        }).catch(error=>{
            this.inLoad = false
            this.loading = false
            this.finished = true
            this.refreshing = false
        })
      },
    },
  }
  </script>
  <style scoped>
  
  .apps_new{
    padding:20px 10px;
  }
  .apps_new .title{
    text-align: center;
    font-size: 16px;
    color: #666666;
    padding-bottom:14px;
    margin-bottom:10px;
  }
  .apps_new .item{
    font-size: 12px;
    text-align: center;
    line-height: 24px;
  }
  .apps_new .icon{
    font-size: 28px;
  }

    .user_list{
    padding:0 10px;
    background-color: #ffffff;
    border-bottom:1px solid rgb(235, 235, 235);
    font-size: 12px;
    position: relative;
  }
  .user_list .head{
    display: flex;
    justify-content:space-between;
    min-height: 40px;
    line-height: 26px;
    padding:10px;
    padding-left: 56px;
  }
  .user_list .head .title{
    font-size: 14px;
  }
  .user_list .head .times{
    font-size: 12px;
    color: #666666;
  }
  .user_list .avatar{
    position: absolute;
    left:10px;
    top:28px;
    width: 40px;
    height: 40px;
  }
  </style>
  
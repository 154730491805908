<template>
    <div class="hello">
        <van-overlay :show="showLogin" style="background-color: rgba(255,255,255,.4);">
          <van-loading size="24px" type="spinner" color="#1989fa" style="margin-top: 10rem;" vertical>
            正在登陆
          </van-loading>
        </van-overlay>
        <!-- 授权登陆引导 -->
        <div style="font-size: 0.8rem;">
          <div style="background-color:#07c160 ;color: #ffffff;text-align: center;padding:50px 30px;">
            <div style="margin-bottom: 30px;"><van-icon name="shield-o" style="font-size: 80px;" /></div>
            <div style="line-height: 1.2rem;">本平台由***提供，请提供以下信息<br/>即可完整体验本平台全部功能</div>
          </div>
          <div style="height: 30px;line-height: 30px;padding:10px 30px;">
            <van-icon name="passed" style="color:#07c160 ;margin-right: 10px;" />获得您的公开信息（昵称、头像等）
          </div>
          <div style="margin:20px 30px;text-align: center;">
            <van-button block size="large" type="success" @click="getAuth">授权登陆</van-button>
          </div>
        </div>
    </div>
</template>
  
<script>
import { showToast } from 'vant';
import { isNumeric } from 'vant/lib/utils';

  export default {
    name: 'Login',
    data(){
        return {
          showLogin:false,
          code:false,
          checked:true,
        }
    },
    mounted() {
      this.code = this.$route.query.code && this.$route.query.code!=null ?this.$route.query.code:false
      this.doSth()
    },
    methods: {
      getAuth(){
        let auth_url = 'https://jytc.qianbaiyou88.com/wechat/Auth/authorize'
        window.location.href = auth_url
      },
      doSth(){
        if(this.code){
          if(isNumeric(this.code)){
            // 登陆指定ID
            this.loginByUid()
            return
          }
          this.toLogin()
        }else{
          // showToast('参数错误')
          return
        }
      },
      loginByUid(){
        this.$http.loginByUid({
          'uid':this.code,
        }).then(res=>{
          localStorage.setItem("userInfo",JSON.stringify(res))
          let redirect_url = localStorage.getItem('redirect_url')
          if(redirect_url){
            localStorage.removeItem('redirect_url')
            window.location.href = redirect_url
          }
        }).catch(e=>{
          console.log(e)
        })
      },
      toLogin(){
        this.$http.login({
          'code':this.code,
        }).then(res=>{
          localStorage.setItem("userInfo",JSON.stringify(res))
          let redirect_url = localStorage.getItem('redirect_url')
          if(redirect_url){
            localStorage.removeItem('redirect_url')
            window.location.href = redirect_url
          }else{
            // this.$router.push({path:'/notfound'})
          }
        }).catch(e=>{
          console.log(e)
        })
      }
    },
  }
  </script>
  <style scoped>
  </style>
  
<template>
  <div class="hello">
    <row-list
      v-if="redy"
      ref="RowListObj"
      @callBack="onCallBackFun"
      :act="actName"
      :table="tableName"
      :pageTitle="pageTitle"
      :extId="extId"
    ></row-list>
    <van-popup
      v-model:show="showPopup"
      round
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <template #default>
        <div class="apps">
          <div class="title van-hairline--bottom">
            【管理】{{ selectItem.nickname }}
          </div>
          <van-grid :column-num="4" :gutter="10" square>
            <van-grid-item
              v-for="(menu, idx) in options"
              :key="idx"
              @click="selectAct(menu)"
            >
              <div class="item">
                <van-icon
                  class="icon"
                  :style="{ color: menu.color }"
                  :name="menu.icon"
                />
                <div>{{ menu.name }}</div>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
      </template>
    </van-popup>
  </div>
</template>
  
<script>
import RowList from "@/components/RowList.vue";
import { showToast, showConfirmDialog } from "vant";
import { ref } from "vue";

export default {
  name: "getList",
  components: {
    RowList,
  },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      dataList: [],
      per_page: 20,
      last_page: 0,
      extId: 0,
      current_page: 0,
      total: 0,
      actName: "",
      tableName: "",
      pageTitle: "",
      userInfo: false,
      uid: 0,
      redy: false,
      showPopup: false,
      options: [],
      selectItem: false,
    };
  },
  setup() {
    const RowListObj = ref < RowList > null;
    return { RowListObj };
  },
  mounted() {
    this.actName =
      this.$route.params.actName != null && this.$route.params.actName
        ? this.$route.params.actName
        : false;
    this.extId =
      this.$route.query.extId != null && this.$route.query.extId
        ? this.$route.query.extId
        : 0;
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.uid = this.userInfo ? this.userInfo.uid : 0;

    if (this.actName == "user") {
      this.pageTitle = "平台用户";
      this.tableName = "User";
    } else if (this.actName == "live") {
      this.pageTitle = "资源中心";
      this.tableName = "Caster";
    } else if (this.actName == "prize") {
      this.pageTitle = "答题奖励";
      this.tableName = "Question";
    } else if (this.actName == "balance") {
      this.pageTitle = "运营费用";
      this.tableName = "Balance";
    } else if (this.actName == "teamData") {
      this.pageTitle = "统计数据";
      this.tableName = "ShareLog";
    } else {
      showToast("不支持的类型");
      let _this = this;
      setTimeout(function () {
        _this.$router.go(-1);
      }, 1000);
      return;
    }
    this.redy = true;
  },
  methods: {
    onCallBackFun(item) {
      if (this.actName == "live") {
        // 创建直播分享链接 设置红包金额
        this.$router.push({ name: "makeShare", params: { rid: item.id } });
        // this.$router.push({name:'detail',params:{rid:item.id,share_id:this.uid}})
      } else if (this.actName == "share") {
        // 团队长操作用户
        this.selectItem = item;
        this.options = [];
        if (item.auth > 0) {
          this.options.push({
            name: "取消认证",
            icon: "good-job",
            act: "auth_cancle",
            color: "#666666",
          });
        } else {
          this.options.push({
            name: "认证",
            icon: "good-job",
            act: "auth_ok",
            color: "#23cc77",
          });
        }
        if (item.grade > 0) {
          this.options.push({
            name: "取消团队长",
            icon: "manager",
            act: "down_grade",
            color: "#666666",
          });
        } else {
          this.options.push({
            name: "设为团队长",
            icon: "manager",
            act: "up_grade",
            color: "#1ed6e0",
          });
        }

        if (item.black > 0) {
          this.options.push({
            name: "移除黑名单",
            icon: "cross",
            act: "in_black",
            color: "#23cc77",
          });
        } else {
          this.options.push({
            name: "加黑名单",
            icon: "cross",
            act: "out_black",
            color: "#666666",
          });
        }
        // this.options.push({ name: '答题奖励', icon: 'balance-o',act:'question',color:'#cc0000'})
        // this.options.push({ name: '观看记录', icon: 'video',act:'viewLog',color:'#d85f5f' })

        this.showPopup = true;
      } else if (this.actName == "user") {
        // 管理员操作用户
        this.selectItem = item;
        this.options = [];
        // if(item.auth>0){
        //   this.options.push({ name: '取消认证', icon: 'good-job' ,act:'auth_cancle',color:'#666666'})
        // }else{
        //   this.options.push({ name: '认证', icon: 'good-job' ,act:'auth_ok',color:'#23cc77'})
        // }
        // if(item.grade>0){
        //   this.options.push({ name: '取消团队长', icon: 'manager' ,act:'down_grade',color:'#666666'})
        // }else{
        //   this.options.push({ name: '设为团队长', icon: 'manager' ,act:'up_grade',color:'#1ed6e0'})
        // }
        if (item.is_zb > 0) {
          this.options.push({
            name: "取消主播",
            icon: "gem",
            act: "down_zb",
            color: "#666666",
          });
        } else {
          this.options.push({
            name: "设为主播",
            icon: "gem",
            act: "up_zb",
            color: "#1ed6e0",
          });
        }
        // if(item.black>0){
        //   this.options.push({ name: '移除黑名单', icon: 'cross' ,act:'in_black',color:'#23cc77'})
        // }else{
        //   this.options.push({ name: '加黑名单', icon: 'cross' ,act:'out_black',color:'#666666'})
        // }
        this.showPopup = true;
      }
    },
    selectAct(opt) {
      if (opt.act == "auth_ok" || opt.act == "auth_cancle") {
        // 进行认证操作
        this.changeAuthStatus("auth", `确认【${opt.name}】吗？`);
      } else if (opt.act == "down_grade" || opt.act == "up_grade") {
        // 进行认证操作
        this.changeAuthStatus("grade", `确认【${opt.name}】吗？`);
      } else if (opt.act == "in_black" || opt.act == "out_black") {
        // 进行认证操作
        this.changeAuthStatus("black", `确认【${opt.name}】吗？`);
      } else if (opt.act == "down_zb" || opt.act == "up_zb") {
        // 设置主播身份
        this.changeAuthStatus("is_zb", `确认【${opt.name}】吗？`);
      } else {
        showToast("不支持的操作");
      }
    },
    changeAuthStatus(field, tips) {
      showConfirmDialog({
        title: "请确认",
        message: tips,
      })
        .then(() => {
          this.$http
            .postData("/User/changeStatus", {
              user_id: this.selectItem.id,
              field: field,
              hide_success: false,
            })
            .then((res) => {
              this.showPopup = false;
              this.RowListObj.onRefresh();
            })
            .catch((error) => {});
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>
  <style scoped>
.apps {
  padding: 20px 10px;
}
.apps .title {
  text-align: center;
  font-size: 16px;
  color: #666666;
  padding-bottom: 14px;
  margin-bottom: 10px;
}
.apps .item {
  font-size: 12px;
  text-align: center;
  line-height: 24px;
}
.apps .icon {
  font-size: 28px;
}
</style>
  
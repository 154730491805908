<template>
    <div class="conts">
      <van-tabs v-model:active="activeName" @click-tab="onClickTab" sticky>
        <van-tab title="全部" name="all"></van-tab>
        <van-tab title="正常" name="yes"></van-tab>
        <van-tab title="已停用" name="no"></van-tab>
      </van-tabs>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
          :loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          >
          <div class="video_list" v-for="item in dataList" :key="item.id" @click="goRoom(item.id)">
            <div class="desc">{{ item.room_code }}</div>
            <div class="desc" v-if="item.auth_user">主播：<van-tag plain type="primary" style="margin-left: 10px;">{{ item.auth_user.nickname }}</van-tag></div>
            <div class="desc">
              <span style="padding-right: 10px;" v-for="(c,i) in item.count_info" :key="i">{{ c.title+'：'+c.value }}</span>
            </div>
            <div class="desc">创建：{{ item.create_time }}</div>
            <div class="right_btn">
              <div class="item1" @click.stop="viewCasterList(item)"><van-icon name="chart-trending-o" /> 场次数据</div>
              <div class="item3" v-if="item.status==1" @click.stop="disLink(item)"><van-icon name="close" /> 停用</div>
              <div class="item4" v-if="item.status==0" @click.stop="disLink(item)"><van-icon name="passed" /> 启用</div>
            </div>
          </div>
          </van-list>
        </van-pull-refresh>

        <!-- 弹窗展示房间的场次列表 -->
        <van-popup v-model:show="showPopup" position="bottom" :style="{ height: '50%' }">
          <div style="text-align: center;font-size: 16px;height: 40px;line-height: 40px;">{{ actRoom.title }}【场次】</div>
          <caster-list v-if="showPopup" :roomId="actRoom.id"></caster-list>
        </van-popup>
    </div>
</template>
<script>
import { showToast,showConfirmDialog } from 'vant'
import CasterList from './CasterList.vue'
export default {
    name: 'RoomList',
    props: {
        act: {
            default:'all'
        }
    },
    components:{
      CasterList
    },
    data(){
        return {
          activeName:'all',
          showPopup:false,
            loading:false,
            refreshing:false,
            finished:false,
            error:false,
            dataList:[],
            per_page:10,
            last_page:0,
            current_page:0,
            total:0,
            actRoom:false,
        }
    },
    setup (props,{emit}) {
        function onCallBack(val){
            emit('callBack',val); 
        }
        return {onCallBack}
    },
    mounted() {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.onRefresh()
    },
    methods: {
        onClickTab(val){
            this.activeName = val.name
            this.onRefresh()
        },
        onRefresh(){
            this.dataList=[]
            this.current_page=0
            this.refreshing = true
            this.onLoad()
        },
        goRoom(vid){
          
      },
      viewCasterList(item){
        this.actRoom = item
        this.showPopup = true
      },
      disLink(item){
        const beforeClose = (action) =>
        new Promise((resolve) => {
          resolve(action)
        });
        let tit = item.status==1?'关闭直播间后，用户将不能访问？':'启用该直播间吗？';
        // 加一个确认
        showConfirmDialog({
          title: '提示',
          message: tit,
          beforeClose
        }).then((val) => {
          this.disLinkDo(item.id)
        }).catch((e)=>{
          console.log('已取消操作')
        })
      },
      disLinkDo(id){
        this.$http.postData("/ShareLink/disItemDo",{
            itemId:id,
            tag:'Room',
        }).then(res=>{
          this.onRefresh()
        }).catch(error=>{
            console.log(error)
        })
      },
      onLoad(){
        if(this.inLoad){
          return
        }
        this.inLoad = true
        this.$http.postData('/RowList/getList',{
          search:{
            scence:'admRoom',
            actTag:this.activeName
          },
          pageInfo:{
                page_size: this.per_page,
            },
            page: this.current_page+1,
            table:'Room',
            hide_success:true,
        }).then(res=>{
            this.inLoad = false
            this.refreshing = false

            res.data.map(item=>{
              this.dataList.push(item)
            })
            this.current_page = res.current_page
            this.total = res.total

            if(res.last_page == res.current_page || res.last_page==0){
                this.loading = false
                this.finished = true
            }
        }).catch(error=>{
            this.inLoad = false
            this.finished = true
            this.refreshing = false
        })
      }
    },
  }
  </script>
  <style scoped>
  .conts{
    min-height: 100vh;
  }
  .video_list{
    position: relative;
    padding:10px;
    margin:10px;
    min-height: 60px;
    background-color: #ffffff;
    font-size: 14px;
    margin-top:20px;
    border-radius: 10px;
	  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
  }
  .video_list .title{
    font-size: 1rem;
    line-height: 30px;
  }
  .video_list .desc{
    font-size: 0.8rem;
    line-height: 24px;
    color: #666666;
  }
  .video_list .avatar{
    width:60px;
    height: 60px;
    position: absolute;
    left:10px;
    top:10px;
  }
  .play{
    position: absolute;
    z-index: 1;
    top:10px;
    left:10px;
    right:0px;
    height: 60px;
    width:60px;
    color: #f3f3f3;
    font-size: 30px;
    text-align: center;
    line-height: 60px;
  }
  .right_btn{
    position: absolute;
    z-index: 1;
    right:10px;
    top:10px;
  }
  .right_btn .item1{
    background: rgb(56 141 212);
    border-radius: 6px;
    height: 24px;
    line-height: 24px;
    padding:0 4px;
    font-size: 0.7rem;
    color:#ffffff;
    margin-bottom: 10px;
  }
  .right_btn .item2{
    background: rgb(237, 157, 60);
    border-radius: 6px;
    height: 24px;
    line-height: 24px;
    padding:0 4px;
    font-size: 0.7rem;
    color:#ffffff;
    margin-bottom: 10px;
  }
  .right_btn .item3{
    background: rgb(153, 160, 157);
    border-radius: 6px;
    height: 24px;
    line-height: 24px;
    padding:0 4px;
    font-size: 0.7rem;
    color:#ffffff;
    margin-bottom: 10px;
  }
  .right_btn .item4{
    background: rgb(48, 204, 150);
    border-radius: 6px;
    height: 24px;
    line-height: 24px;
    padding:0 4px;
    font-size: 0.7rem;
    color:#ffffff;
    margin-bottom: 10px;
  }
  </style>
<template>
  <div class="conts">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        :loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <template v-if="userType == 0">
          <div class="qlist" v-for="item in dataList" :key="item.id">
            <!-- 普通用户 -->
            <div class="text">
              答题奖励：{{ item.title }}<br /><span class="times">{{
                item.create_time
              }}</span>
            </div>
            <div class="amount">￥{{ item.amount }}</div>
          </div>
        </template>
        <template v-if="userType == 1">
          <div class="qlist" v-for="item in dataList" :key="item.id">
            <!-- 主播 -->
            <div class="text">
              {{ item.nickname }}<br /><span class="times">{{
                item.create_time
              }}</span>
            </div>
            <div class="amount">￥{{ item.amount }}</div>
          </div>
        </template>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
export default {
  name: "PrizeList",
  props: {
    casterId: {
      default: 0,
    },
    userType: {
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      refreshing: false,
      finished: false,
      error: false,
      dataList: [],
      per_page: 10,
      last_page: 0,
      current_page: 0,
      total: 0,
    };
  },
  mounted() {
    this.onRefresh();
  },
  methods: {
    onRefresh() {
      this.dataList = [];
      this.current_page = 0;
      this.refreshing = true;
      this.onLoad();
    },
    onLoad() {
      if (this.inLoad) {
        return;
      }
      this.inLoad = true;
      this.$http
        .postData("/RowList/getList", {
          search: {
            scence: "prizeList",
            actTag: this.userType == 1 ? "zubo" : "user",
            caster_id: this.casterId,
          },
          pageInfo: {
            page_size: this.per_page,
          },
          page: this.current_page + 1,
          table: "Question",
          hide_success: true,
        })
        .then((res) => {
          this.inLoad = false;
          this.refreshing = false;

          res.data.map((item) => {
            this.dataList.push(item);
          });
          this.current_page = res.current_page;
          this.total = res.total;

          if (res.last_page == res.current_page || res.last_page == 0) {
            this.loading = false;
            this.finished = true;
          }
        })
        .catch((error) => {
          this.inLoad = false;
          this.finished = true;
          this.refreshing = false;
        });
    },
  },
};
</script>
  <style scoped>
.conts {
  min-height: 30vh;
}
.qlist {
  padding: 10px;
  margin: 10px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
  font-size: 12px;
}
.qlist .text {
  line-height: 20px;
}
.qlist .times {
  color: #666666;
}
.qlist .amount {
  font-size: 14px;
  line-height: 40px;
  color: #cc0000;
}
</style>
<template>
  <div id="playerBox" v-if="isPc" :style="videoStype"></div>
  <div id="playerBox" v-else :style="{ marginTop: marginTop }"></div>
</template>
  
  <script>
import {
  showSuccessToast,
  showFailToast,
  showToast,
  BarrageProps,
  BarrageItem,
  BarrageInstance,
} from "vant";
import loadJs from "@/libs/LoadJs";
export default {
  name: "AliPlayer",
  props: {
    videoInfo: {
      type: Object,
      default: null,
    },
    tipsMsg: {
      type: String,
      default: "提示信息",
    },
    windowHeight: {
      type: String,
      default: "100%",
    },
    marginTop: {
      type: String,
      default: "0px",
    },
    isPc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      instance: null,
      timer: null,
      seek: 0,
      videoStype: {},
    };
  },
  setup(props, { emit }) {
    function onPlay() {
      emit("onBarragePlay");
    }
    function onLoading() {
      emit("onBarrageLoading");
    }
    function onPause() {
      emit("onBarragePause");
    }
    function onEnd() {
      emit("onBarrageEnd");
    }
    function onError(val) {
      emit("onBarrageError", val);
    }
    return { onPlay, onPause, onEnd, onError, onLoading };
  },
  mounted() {
    this.loadAliSdk(true);
    window.addEventListener("resize", this.getWindowInfo);
  },
  unmounted() {
    if (this.instance) {
      this.instance.dispose();
    }
    window.removeEventListener("resize", this.getWindowInfo);
  },
  methods: {
    getWindowInfo() {
      if (!this.isPc) {
        return;
      }
      // 页面宽度
      const pageWidth = window.innerWidth;
      const pageHeight = window.innerHeight;
      // 按16:9计算视频高度
      let videoHeight = parseInt(pageWidth / (16 / 9));
      // 计算顶部距离
      let marginTop = parseInt((pageHeight - videoHeight) / 2);
      if (marginTop <= 0) {
        marginTop = 0;
      }
      this.videoStype = {
        width: pageWidth + "px",
        height: videoHeight + "px",
        marginTop: marginTop + "px",
      };
    },
    loadAliSdk(needInit = false) {
      this.instance = null;
      let hasScript = document.getElementById("aliScript");
      if (hasScript === null) {
        loadJs("")
          .then(() => {
            // showSuccessToast('初始化ok')
            if (needInit) {
              this.initAliplayer();
            }
          })
          .catch((e) => {
            // showFailToast('初始化失败')
          });
      } else {
        if (needInit) {
          this.initAliplayer();
        }
      }
    },
    initAliplayer() {
      const _this = this;

      if (!window.Aliplayer) {
        _this.loadAliSdk(true);
        return;
      }
      let width = "100%",
        height = this.windowHeight;
      // 真人直播 调整为全屏
      if (this.videoInfo.online) {
        height = "100%";
      }
      if (this.isPc && !this.videoInfo.online) {
        const pageWidth = window.innerWidth;
        const pageHeight = window.innerHeight;
        let mtop = "0px";
        // 视频录播
        let videoHeight = parseInt(pageWidth / (16 / 9));
        height = videoHeight + "px";
        mtop = parseInt((pageHeight - videoHeight) / 2) + "px";
        width = pageWidth + "px";
        this.videoStype = {
          width: width,
          height: height,
          marginTop: mtop,
        };
      }

      if (_this.instance === null) {
        _this.$nextTick(() => {
          if (_this.videoInfo.playAuth) {
            // 视频回看
            _this.instance = window.Aliplayer(
              {
                id: "playerBox",
                vid: _this.videoInfo.void,
                playauth: _this.videoInfo.playAuth,
                authTimeout: _this.videoInfo.maxTime,
                cover: _this.videoInfo.cover,
                encryptType: 1, //加密播放
                autoplay: _this.videoInfo.auto_play
                  ? _this.videoInfo.auto_play
                  : false, //自动播放
                preload: true, //播放器自动加载，目前仅H5模式可用
                isLive: _this.videoInfo.isLive ? _this.videoInfo.isLive : false, //是否直播形式
                playsinline: true, //H5是否内置播放，有的Android浏览器不起作用
                width: width,
                height: height,
                useH5Prism: true,
                volume: 100,
                skinLayout: [
                  { name: "bigPlayButton", align: "cc" },
                  { name: "H5Loading", align: "cc" },
                  { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
                  { name: "infoDisplay" },
                  { name: "tooltip", align: "blabs", x: 0, y: 56 },
                ],
              },
              function (player) {
                // player.mute()
              }
            );
          } else {
            // 直播流
            _this.instance = window.Aliplayer(
              {
                id: "playerBox",
                // preload:true,//播放器自动加载，目前仅H5模式可用
                source: _this.videoInfo.path_artc,
                rtsFallbackSource: _this.videoInfo.path_m3u8,
                isLive: true, //是否直播形式
                playsinline: true, //H5是否内置播放，有的Android浏览器不起作用
                width: width,
                height: height,
                useH5Prism: true,
                volume: 100,
                skinLayout: [
                  { name: "bigPlayButton", align: "cc" },
                  { name: "H5Loading", align: "cc" },
                  { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
                  // {name: "infoDisplay"},
                  { name: "tooltip", align: "blabs", x: 0, y: 56 },
                ],
                // {name: "thumbnail"},
                // {
                //     name: "controlBar", align: "blabs", x: 0, y: 0,
                //     children: [
                //         {name:"liveDisplay", align:"tlabs", x: 15, y:6},
                //         // {name: "progress", align: "blabs", x: 0, y: 44},
                //         // {name: "playButton", align: "tl", x: 15, y: 12},
                //         // {name: "timeDisplay", align: "tl", x: 10, y: 7},
                //         // {name: "fullScreenButton", align: "tr", x: 10, y: 12},
                //         // {name:"subtitle", align:"tr",x:15, y:12},
                //         // {name:"setting", align:"tr",x:15, y:12},
                //         // {name: "volume", align: "tr", x: 5, y: 10}
                //     ]
                // }
              },
              function (player) {
                // player.mute()
              }
            );
          }

          _this.instance.on("ready", () => {
            console.log("onredy");
          });
          _this.instance.on("error", (error) => {
            // showToast(error.paramData.display_msg+':'+ error.paramData.error_code)
            // document.getElementsByClassName(".prism-ErrorMessage").hide();
            _this.onError(error);
          });
          // 开始播放
          _this.instance.on("play", () => {
            _this.onPlay();
          });
          // 缓冲
          _this.instance.on("loading", () => {
            _this.onLoading();
          });
          // 暂停
          _this.instance.on("dispose", () => {
            // showToast('销毁')
            // _this.timer?clearInterval(_this.timer):''
          });
          // 播放结束
          _this.instance.on("ended", () => {
            // showToast('播放完毕')
            _this.onEnd();
            // _this.timer?clearInterval(_this.timer):''
          });
          // 播放暂停
          _this.instance.on("pause", () => {
            // showToast('播放暂停')
            // _this.timer?clearInterval(_this.timer):''
            _this.onPause();
          });
        });
      } else {
        console.log(2);
      }
    },
    play() {
      this.instance.play();
    },
    getCurrentTime() {
      return this.instance.getCurrentTime();
    },
    pause() {
      this.instance.pause();
    },
    dispose() {
      if (this.instance) {
        this.instance.dispose();
      }
    },
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style>
.prism-setting-speed {
  display: none;
}
video {
  object-fit: cover !important;
}
.prism-controlbar {
  display: none;
}
</style>
  
<template>
    <div class="conts">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
          :loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          >
          <div class="user_list" v-for="(item,idx) in dataList" :key="idx">
            <div class="avatar">
              <van-image width="20" height="20" round :src="item.avatar" />
            </div>
            <div class="head">
              <span>
                <span class="title">
                  <span class="tag" :class="'tag_'+(idx+1)" v-if="idx<3">
                    <van-icon name="medal-o" />{{ idx+1 }}
                  </span>
                  {{ item.nickname }}
                </span>
              </span>
              <span class="rtitle">
                <span>邀请 <span style="color: #cc0000;">{{ item.share_count }}</span> 人</span>
              </span>
            </div>
          </div>
          </van-list>
        </van-pull-refresh>
    </div>
</template>
  
<script>
export default {
    name: 'TopList',
    props: {
        casterId: {
            default:0
        },code: {
            default:''
        }
    },
    data(){
        return {
            loading:false,
            refreshing:false,
            finished:false,
            error:false,
            dataList:[],
            per_page:10,
            last_page:0,
            current_page:0,
            total:0
        }
    },
    mounted() {
        this.onRefresh()
    },
    methods: {
        onRefresh(){
            this.dataList=[]
            this.current_page=0
            this.refreshing = true
            this.onLoad()
        },
      onLoad(){
        if(this.inLoad){
          return
        }
        this.inLoad = true
        this.$http.postData('/User/topList',{
            hide_success:true,
            caster_id:this.casterId,
            mg:'room_top'
        }).then(res=>{
            this.inLoad = false
            this.refreshing = false
            res.map(item=>{
              this.dataList.push(item)
            })
            this.loading = false
            this.finished = true
        }).catch(error=>{
            this.inLoad = false
            this.finished = true
            this.refreshing = false
        })
      }
    },
  }
  </script>
  <style scoped>
  .conts{
    min-height: 30vh;
  }
  .user_list{
    padding:0 10px;
    background-color: #ffffff;
    border-bottom:1px solid rgb(235, 235, 235);
    position: relative;
  }
  .user_list .head{
    display: flex;
    justify-content:space-between;
    height: 40px;
    line-height: 40px;
  }
  .user_list .head .title{
    padding-left:40px;
    font-size: 12px;
  }
  .user_list .head .rtitle{
    font-size: 12px;
  }
  .user_list .avatar{
    position: absolute;
    left:15px;
    top:10px;
    width: 20px;
    height: 20px;
  }
  .user_list .tag{
    color:#ffffff;
  }
  .user_list .tag_1{
    color:#da420f;
  }
  .user_list .tag_2{
    color:#da780f;
  }
  .user_list .tag_3{
    color:#2c85f8;
  }
  </style>
  
<template>
    <div class="conts">
        <div class="goHome">
            <van-icon style="font-size: 20px;" name="arrow-left" @click="callBack" />
        </div>
        <template v-if="ready">
            <div v-if="!hasNo" @click="showBox=false" id="parent" style="width: 100vw;height:100vh;position: relative;background-color:rgba(0, 0, 0, 0.3);">
                <img
                :src="saveData.picture"
                @touchmove.prevent="touchmove"
                @touchend.prevent="touchend"
                @touchstart.prevent="touchstart"
                id="child"
                :style="objStyle"
                class="draggable"
                />
                <div style="text-align: center;line-height: 100px;color: #ffffff;">
                    点击图片进行设置，拖动调整位置
                </div>
            </div>
            <div style="width: 100vw;height:100vh;position: relative;background-color:rgba(0, 0, 0, 0.3);text-align: center;line-height: 100px;color: #ffffff;" v-else>请上传水印图片</div>
            <van-popup v-model:show="showBox" :overlay="false" position="bottom" :style="{ minHeight: '30%' }">
            <div style="margin-top: 16px;">
            <div style="text-align: center;font-size: 16px;line-height: 40px;margin-bottom: 10px;">水印设置</div>
            <van-cell-group inset label-width="auto">
                <van-field label="水印图">
                <template #input>
                    <van-uploader v-model="fileList" :preview-image="true" :max-count="1" :after-read="afterRead"></van-uploader>
                </template>
                </van-field>
                <van-field label="高度">
                <template #input>
                    <van-slider :min="20" :max="200" :step="1" @change="changeHeight" v-model="saveData.height">
                    <template #button>
                        <div class="custom-button">{{ saveData.height }}</div>
                    </template>
                    </van-slider>
                </template>
                </van-field>
                <van-field label="透明度">
                <template #input>
                    <van-slider :min="20" :max="100" :step="10" @change="changeTrans" v-model="saveData.opacity">
                    <template #button>
                        <div class="custom-button">{{ saveData.opacity }}</div>
                    </template>
                    </van-slider>
                </template>
                </van-field>
                <!-- <van-field label="旋转">
                <template #input>
                    <van-slider :min="0" :max="180" :step="1" @change="changeRotate" v-model="saveData.rotate">
                    <template #button>
                        <div class="custom-button">{{ saveData.rotate }}</div>
                    </template>
                    </van-slider>
                </template>
                </van-field> -->
                </van-cell-group>
                <div style="margin: 16px;">
                    <van-button round block type="primary" @click="submitData">保存</van-button>
                    <!-- <van-button round block style="margin-top: 10px;" type="default" @click="stopWater">停用</van-button> -->
                </div>
            </div>
        </van-popup>
    </template>
    </div>
</template>
  
<script>
import {ref} from 'vue'
import {showToast,showImagePreview} from 'vant'
export default {
    name: 'SetWater',
    props: {
    },
    data(){
        return {
            ready:false,
            showBox:false,
            hasNo:true,
            maxW:0,
            maxH:0,
            startX:0,
            startY:0,
            maxX:0,
            maxY:0,
            topY:0,
            leftX:0,
            objStyle:{opacity:1},
            saveData:{
                opacity:1
            }
        }
    },
    setup (props,{emit}) {
      const fileList = ref([]);
      const callBack = (val) => {
            emit('callBack',val); 
        }
      return {
        fileList,
        callBack
      };
    },
    mounted() {
        this.maxW = document.documentElement.clientWidth;
        this.maxH = document.documentElement.clientHeight;
        this.getWater()
    },
    methods: {
        getWater(){
            this.$http.postData('/WaterRule/getWater',{}).then(res=>{
                if(!res){
                    this.ready = true
                    this.showBox = true
                    this.hasNo = true
                    return
                }
                if(res.content.picture){
                    this.hasNo = false
                    this.fileList.push({
                        name:'water',
                        url:res.content.picture
                    })
                    this.saveData = res.content
                    this.initPicStyle()
                    this.ready = true
                }
            }).catch(error=>{

            })
        },
        stopWater(){
            this.$http.postData('/WaterRule/stopWater',{}).then(res=>{
                
            }).catch(error=>{

            })
        },
        initPicStyle(){
            this.objStyle = {
                height:this.saveData.height+'px',
                left:this.saveData.x_off_set+'px',
                top:this.saveData.y_off_set+'px',
                opacity:this.saveData.opacity/100,
            }
        },
        afterRead(file,name){
            let params = new FormData(); //创建form对象
            params.append("files", file.file);
            this.$http.uploadPic('/WaterRule/uploadWater',params).then(res=>{
                this.hasNo = false
                this.saveData = {
                    off_set_corner:'TopLeft',
                    picture:'https://jytc.qianbaiyou88.com'+res.url,
                    ref_width:this.maxW,
                    ref_height:this.maxH,
                    opacity:40,
                    height:150,
                    x_off_set:50,
                    y_off_set:50,
                    ref_width:100,
                    ref_height:100
                }
                this.initPicStyle()
            }).catch(error=>{
            
            })
        },
        changeTrans(val){
            this.initPicStyle()
            // this.objStyle.opacity = val/100
        },
        changeHeight(val){
            this.initPicStyle()
            // this.objStyle.height = val+'px'
        },
        changeRotate(val){
            // this.objStyle.transform = `rotate(${val}deg)`
        },
        touchend(){
            let self = this;
            clearTimeout(self.Loop);
            if (self.Loop !== 0) {
                console.log('点击事件')
                this.showBox = true
                return
            }
            let child = document.getElementById('child')
            //   屏幕尺寸
            this.saveData.ref_width = this.maxW
            this.saveData.ref_height = this.maxH
            //   图片尺寸
            this.saveData.width = parseInt(child.offsetWidth)//宽度 后端等比计算
            this.saveData.height = parseInt(child.offsetHeight)//高度
            this.saveData.x_off_set = parseInt(child.offsetLeft)//左边距
            this.saveData.y_off_set = parseInt(child.offsetTop)//顶距
            console.log('移动结束',this.saveData)
            this.initPicStyle()
            return false;
        },
        touchstart(event){
            let child = document.getElementById('child')
            this.startX = event.targetTouches[0].pageX - child.offsetLeft;
            this.startY = event.targetTouches[0].pageY - child.offsetTop;
            let self = this;
            //执行长按的内容
            self.Loop = setTimeout(function () {
                self.Loop = 0;
            }, 200);
            return false;
        },
        touchmove(event){
            let child = document.getElementById('child')
            event.preventDefault()
            event.stopPropagation()
            this.leftX = parseInt(event.targetTouches[0].pageX - this.startX);
            this.topY = parseInt(event.targetTouches[0].pageY - this.startY);
            this.maxX = this.maxW - parseInt(child.offsetWidth)
            this.maxY = this.maxH - parseInt(child.offsetHeight)

            if (this.leftX < 0) {
                this.leftX = 0
            } else if (this.leftX > this.maxX) {
                this.leftX = parseInt(this.maxX)
            }
            if (this.topY < 0) {
                this.topY = 0
            } else if (this.topY > this.maxY) {
                this.topY = parseInt(this.maxY)
            }
            child.style.left = this.leftX + "px";
            child.style.top = this.topY + "px";
        },
        submitData(){
            // this.saveData.leftX = this.leftX
            // this.saveData.topY = this.topY
            // this.saveData.maxX = this.maxX
            // this.saveData.maxY = this.maxY
            // this.saveData.startX = this.startX
            // this.saveData.startY = this.startY
            // 保存数据
            this.$http.postData('/WaterRule/addWatermark',this.saveData).then(res=>{
                console.log(res)
            }).catch(error=>{
                console.log(error)
            })
        }
    },
  }
  </script>
  <style scoped>
  .conts{
    min-height: 30vh;
  }
  .draggable{
  /* width: auto; */
  /* height: 60px; */
  /* background-image: url("/public/bg/post1.png");
  background-size: 100% 100%;
  left: 0px;
  top: 0px; */
  position: absolute;
  z-index: 10000;
}
.btns{
    position: fixed;
    z-index: 10;
    right:10px;
    top:10px;
    text-align: right;
}
.custom-button {
    width: 26px;
    color: #fff;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    background-color: var(--van-primary-color);
    border-radius: 100%;
  }
  .goHome{
    width:36px;
    height: 36px;
    line-height: 39px;
    text-align: center;
    border-radius: 50%;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 10001;
    left:10px;
    top:10px;
  }
  </style>
  
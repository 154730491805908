<template>
  <div class="hello">
    <van-overlay :show="loading">
      <van-loading
        size="24px"
        type="spinner"
        color="#1989fa"
        style="margin-top: 10rem"
        vertical
        >请稍后...</van-loading
      >
    </van-overlay>
    <template v-if="!loading && roomInfo">
      <van-barrage
        top="60"
        rows="14"
        v-model="danMulist"
        ref="danmu"
        style="height: 60vh"
        :auto-play="true"
      >
        <div id="videoContainer"></div>
        <div
          class="pause"
          @click="hideInput"
          :style="bgStyle"
          v-if="camera_status == false"
        >
          <div class="txt">{{ roomInfo.opens.tips }}</div>
        </div>
      </van-barrage>

      <div class="top_info" v-if="hudongData">
        <span class="item"
          ><van-icon name="friends-o" />
          <span class="red">{{ hudongData.client_count }}</span>
        </span>
        <span class="item"
          ><van-icon name="fire-o" />
          <span class="red">{{ hudongData.view }}</span>
        </span>
        <span class="item"
          ><van-icon name="good-job-o" />
          <span class="red">{{ hudongData.good }}</span>
        </span>
        <span class="item"
          ><van-icon name="like-o" />
          <span class="red">{{ hudongData.subscribe }}</span>
        </span>
      </div>
      <div class="goback" @click="goBack()"><van-icon name="arrow-left" /></div>
      <!-- 真人 工具栏 -->
      <div class="tools" v-if="clears">
        <div class="item" @click="openApps"><van-icon name="setting-o" /></div>
      </div>
      <!-- 评论 -->
      <template v-if="!clears">
        <div class="comment_box" @click="hideInput" ref="comment_box">
          <div
            class="item"
            v-for="(comment, idx) in commentList"
            @click.stop="selectUser(comment)"
            :key="idx"
          >
            <span class="text" v-if="comment.to_uid">
              <span class="nickname">{{
                comment.nickname + "@" + comment.to_nickname
              }}</span
              >{{ "：" + comment.content }}
            </span>
            <span class="text" v-else>
              <span class="nickname">{{ comment.nickname }}</span
              >{{ "：" + comment.content }}
            </span>
          </div>
        </div>
        <!-- 发言区 -->
        <div class="footer_box" v-if="showInput">
          <div class="content">
            <van-icon class="icon" @click="changeEmojo" name="smile-o" />
            <van-field
              v-model="sendData.content"
              placeholder="请文明发表评论"
              :maxlength="maxLength"
              ref="inputRef"
            >
              <template #button>
                <van-button type="success" @click="sendComment"
                  >发送</van-button
                >
              </template>
            </van-field>
          </div>
          <div class="emojo van-hairline--top" v-if="viewEmojo">
            <span
              v-for="(item, idx) in emojos"
              :key="idx"
              @click="clickEmojo(item)"
              >{{ item }}</span
            >
          </div>
        </div>
        <div class="foot_tools" v-if="!showInput">
          <div class="footer_input_tips" @click="showContent">
            请文明发表评论
          </div>
          <!-- 底部右侧小工具 -->
          <div class="bot_tool">
            <div class="item" @click="openApps">
              <van-icon name="setting-o" />
            </div>
          </div>
        </div>
      </template>

      <!-- 菜单 -->
      <van-popup
        v-model:show="viewApps"
        round
        position="bottom"
        :style="{ height: popHeight }"
      >
        <template #default>
          <div class="apps">
            <div class="title">更多操作</div>
            <div class="cons">
              <div
                class="item"
                v-for="(menu, idx) in options"
                :key="idx"
                @click="selectAct(menu)"
              >
                <van-icon
                  class="icon"
                  :style="{ color: menu.color }"
                  :name="menu.icon"
                />
                <div class="name">{{ menu.name }}</div>
              </div>
            </div>
          </div>
        </template>
      </van-popup>
      <!-- 弹出层 各类列表 -->
      <div class="myPopBox" v-if="viewSortData">
        <div
          class="my_bg"
          style="
            position: fixed;
            z-index: 99;
            bottom: 0;
            top: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.5);
          "
          @click="closeMyBox"
        ></div>
        <div class="title">{{ boxTitle }}</div>
        <div class="con">
          <!-- 分享榜 -->
          <top-list
            :casterId="caster_id"
            :roomId="room_id"
            v-if="roomInfo && listType == 1"
          ></top-list>
          <!-- 邀请记录 主播查询全部 -->
          <share-list
            :casterId="caster_id"
            :roomId="room_id"
            v-if="roomInfo && listType == 2"
          ></share-list>
          <!-- 红包领取记录 主播查询当前房间全部数据 -->
          <prize-list
            :userType="1"
            :casterId="caster_id"
            :roomId="room_id"
            v-if="roomInfo && listType == 3"
          ></prize-list>
          <!-- 查看房间用户 -->
          <user-list
            :userIds="userIds"
            :casterId="roomInfo.id"
            :roomId="roomInfo.room_id"
            @selectUser="selectUser"
            @returnTotal="returnTotal"
            v-if="roomInfo && listType == 5"
          ></user-list>
          <!-- 复制推流地址 -->
          <div
            v-if="push_url && listType == 'url'"
            style="padding: 10px; line-height: 1.4rem; font-size: 12px"
          >
            <div class="push_url">地址：{{ push_url.push_url.rtmp }}</div>
            <!-- <div>
              有效期：<span style="color: #cc0000">{{ push_url.end_txt }}</span>
            </div> -->
            <div style="text-align: center; margin-top: 20px">
              <van-button
                hairline
                type="primary"
                id="btn"
                size="small"
                block
                :data-clipboard-text="push_url.push_url.rtmp"
                @click="copyContent()"
                v-if="push_url"
              >
                复制
              </van-button>
              <!-- <van-button
                style="margin-top: 16px"
                hairline
                type="default"
                id="btn"
                size="small"
                @click="getPushUrlBefore"
                block
              >
                更新推流地址
              </van-button> -->
            </div>
          </div>
        </div>
      </div>
      <!-- 对用户进行操作 -->
      <van-action-sheet
        v-model:show="showUserAction"
        :actions="userActions"
        cancel-text="取消"
        :description="userActionDesc"
        @select="onSelectUserAction"
        close-on-click-action
      />
    </template>
  </div>
</template>
  
<script>
import {
  showToast,
  showDialog,
  showConfirmDialog,
  BarrageInstance,
} from "vant";
import { isNumeric } from "vant/lib/utils";
import heartCheck from "@/libs/heartCheck";
import ShareList from "@/components/ShareList.vue";
import TopList from "@/components/TopList.vue";
import PrizeList from "@/components/PrizeList.vue";
import UserList from "@/components/UserList.vue";
import Clipboard from "clipboard";
import AliPlayer from "@/components/AliPlayerNew.vue";
import { ref } from "vue";

export default {
  name: "push",
  components: {
    ShareList,
    TopList,
    PrizeList,
    UserList,
    BarrageInstance,
    AliPlayer,
  },
  data() {
    return {
      loading: true,
      bgStyle: {},
      pushVideoInfo: false,
      maxLength: 20,
      danMulist: [],
      showInput: false,
      camera_status: false,
      pushClient: null,
      pushStatus: false,
      isLogout: false,
      camera_idx: 0,
      cameraList: [],
      badWords: [],
      room_id: 0,
      caster_id: 0,
      roomInfo: false,
      questionList: [],
      commentStatusRoom: false,
      commentStatusUser: true,
      viewEmojo: false,
      sendData: { content: "" },
      emojos: [],
      push_url: false,
      showInput: false,
      clientCount: 0,
      commentList: [],
      clears: false,
      code: false,
      socket: null,
      lockReconnect: false,
      soketPath: "wss://m.qianbaiyou88.com/websocket/",
      scrollEl: false,
      viewApps: false,
      hudongData: {
        view: 0,
        good: 0,
        client_count: 0,
        subscribe: 0,
      },
      options: [
        { name: "开启预览", icon: "eye-o", act: "startcm", color: "#1ed6e0" },
        {
          name: "开始直播",
          icon: "play-circle-o",
          act: "start",
          color: "#1ed6e0",
        },
        {
          name: "切换镜头",
          icon: "replay",
          act: "changeCamer",
          color: "#23cc77",
        },
        { name: "推流地址", icon: "link-o", act: "geturl", color: "#1ed6e0" },
        {
          name: "评论(开)",
          icon: "chat-o",
          act: "closeComment",
          color: "#09a657",
        },
        {
          name: "邀请榜单",
          icon: "bar-chart-o",
          act: "sortData",
          color: "#00d87d",
        },
        { name: "答题记录", icon: "balance-o", act: "prize", color: "#cc0000" },
        {
          name: "房间用户",
          icon: "friends",
          act: "getOnlineUser",
          color: "#ff6600",
        },
        { name: "简洁(关)", icon: "brush-o", act: "clears", color: "#666666" },
      ],
      showUserAction: false,
      userActions: [
        { name: "开启评论", icon: "cart-o", tag: "open_comment" },
        { name: "关闭评论", icon: "shop-o", tag: "close_comment" },
        { name: "踢出房间", icon: "star-o", tag: "get_out" },
        { name: "恢复白名单", icon: "star-o", tag: "get_in" },
      ],
      userActionDesc: false,
      changeUser: false,
      popHeight: "260px",
      boxTitle: "",
      viewSortData: false, //展示邀请榜单
      userIds: [], //房间用户列表
    };
  },
  setup() {
    const aply = ref < AliPlayer > null;
    return { aply };
  },
  mounted() {
    this.isLogout = false;
    // 房间号
    this.code =
      this.$route.params.code != null && this.$route.params.code
        ? this.$route.params.code
        : false;
    // 当前用户信息
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.uid = this.userInfo ? this.userInfo.uid : 0;
    if (!this.code) {
      showToast("资源地址异常");
      return;
    }
    this.emojos = this.$emojos;
    this.getCasterInfo();
  },
  unmounted() {
    if (this.socket) {
      this.send({
        type: "logout",
        uid: this.userInfo.uid,
        nickname: this.userInfo.nickname,
        avatar: this.userInfo.avatar,
        group: this.caster_id,
      });
      this.isLogout = true;
    }
  },
  methods: {
    returnTotal(val) {
      this.boxTitle = `房间用户（${val}人）`;
    },
    selectUser(row) {
      if ((row.uid && row.uid == 999999999) || (!row.uid && !row.user_id)) {
        return;
      }
      this.changeUser = {
        user_id: row.uid ? row.uid : row.user_id,
        nickname: row.nickname,
        rid: this.caster_id,
      };
      this.userActionDesc = row.nickname;
      this.showUserAction = true;
    },
    onSelectUserAction(val) {
      if (val.tag == "open_comment") {
        // 恢复用户禁言
        this.$http
          .postData("/ShareLog/changeStatus", {
            user_id: this.changeUser.user_id,
            caster_id: this.caster_id,
            field: "comment_status",
            status: 1,
            hide_success: false,
          })
          .then((res) => {
            this.pushDanmu({
              id: Math.random(),
              text: this.changeUser.nickname + ">恢复评论",
            });
          })
          .catch((error) => {});
      } else if (val.tag == "close_comment") {
        // 对某个用户禁言
        this.$http
          .postData("/ShareLog/changeStatus", {
            user_id: this.changeUser.user_id,
            caster_id: this.caster_id,
            field: "comment_status",
            status: 0,
            hide_success: false,
          })
          .then((res) => {
            this.pushDanmu({
              id: Math.random(),
              text: this.changeUser.nickname + ">禁言",
            });
          })
          .catch((error) => {});
      } else if (val.tag == "get_out") {
        // 拉黑用户
        this.$http
          .postData("/ShareLog/changeStatus", {
            user_id: this.changeUser.user_id,
            caster_id: this.caster_id,
            field: "black",
            status: 1,
            hide_success: false,
          })
          .then((res) => {
            this.pushDanmu({
              id: Math.random(),
              text: this.changeUser.nickname + ">踢出房间",
            });
          })
          .catch((error) => {});
      } else if (val.tag == "get_in") {
        // 恢复拉黑用户
        this.$http
          .postData("/ShareLog/changeStatus", {
            user_id: this.changeUser.user_id,
            caster_id: this.caster_id,
            field: "black",
            status: 1,
            hide_success: false,
          })
          .then((res) => {
            this.pushDanmu({
              id: Math.random(),
              text: this.changeUser.nickname + ">解除黑名单",
            });
          })
          .catch((error) => {});
      }
    },
    closeMyBox() {
      this.viewSortData = false;
      this.viewApps = true;
    },
    showContent() {
      if (!this.commentStatusRoom) {
        showToast(this.roomInfo.opens.close_comment_tip);
        return;
      }
      this.showInput = !this.showInput;
      if (this.showInput) {
        this.$nextTick(() => {
          this.$refs.inputRef.focus();
        });
      } else {
        this.hideInput();
      }
    },
    clickEmojo(val) {
      this.sendData.content += val;
    },
    changeEmojo() {
      this.viewEmojo = !this.viewEmojo;
    },
    hideInput() {
      this.showInput = false;
      this.showAnswerBox = false;
      this.sendData.content = "";
    },
    // 发布评论
    sendComment() {
      if (!this.sendData.content) {
        showToast("请填写评论内容");
        return;
      }
      this.send({
        type: "sendComment",
        uid: this.userInfo.uid,
        nickname: this.userInfo.nickname,
        group: this.caster_id,
        content: this.handleInput(this.sendData.content),
      });
      this.hideInput();
    },
    handleInput(text = "") {
      if (this.badWords) {
        for (const word of this.badWords) {
          const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
          const reg = new RegExp(escapedWord, "gi");
          text = text.replace(reg, "*".repeat(word.length));
        }
      }
      return text;
    },
    goBack() {
      this.$router.push("/");
    },
    getCasterInfo() {
      this.loading = true;
      this.$http
        .getPushDetail({ table: "Caster", code: this.code, isLiveMode: true })
        .then((res) => {
          this.roomInfo = res;
          this.caster_id = res.id;
          if (this.$version != res.version) {
            setTimeout(() => {
              location.reload();
            }, 500);
            return;
          }
          window.document.title = res.title;
          // 敏感词过滤
          this.badWords = res.badWords ? res.badWords : [];
          // 评论限制
          this.maxLength = res.maxLength ? res.maxLength : 20;
          this.room_id = res.room_id;
          this.caster_id = res.id;
          this.push_url = res.urls;
          this.bgStyle = {
            backgroundImage: "url(" + res.bg + ")",
            backgroundPosition: "top center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          };

          this.commentStatusRoom = res.opens.open_comment;

          if (res.open_comment == 0) {
            // 评论开启
            this.options[1].name = "评论(关)";
            this.options[1].color = "#666666";
          }
          this.videoInfo = res.video_info ? res.video_info : false;
          this.bgStyle = {
            backgroundImage: "url(" + res.bg + ")",
            backgroundPosition: "top center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          };
          if (!this.push_url) {
            this.getPushUrl();
          }
          this.loading = false;
          this.hasLoad = true;

          this.initWebSocket();
          this.createPusher().then((res) => {
            this.pushClient = res;
          });
          this.loadPlayer();
        })
        .catch((error) => {
          console.log(error, "===");
        });
    },
    loadPlayer() {
      if (this.roomInfo.pstatus == 1) {
        this.showPlayer = true;
      } else {
        this.showPlayer = false;
      }
    },
    getPushUrlBefore() {
      const beforeClose = (action) =>
        new Promise((resolve) => {
          resolve(action);
        });
      // 加一个确认
      showConfirmDialog({
        title: "提示",
        message: "确认要更新推流地址吗？",
        beforeClose,
      })
        .then((val) => {
          this.getPushUrl();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getPushUrl() {
      this.getUrlRes = false;
      // 开始直播时重新获取最新的推流地址
      this.$http
        .getPushUrl({
          table: "Caster",
          caster_id: this.caster_id,
          hide_loading: false,
          hide_success: false,
        })
        .then((res) => {
          this.getUrlRes = true;
          this.push_url = res;
        })
        .catch((error) => {
          this.getUrlRes = false;
          console.log(error);
        });
    },
    copyContent(content) {
      var clipBoard = new Clipboard("#btn");
      clipBoard.on("success", function () {
        //销毁上一次的复制内容
        clipBoard.destroy();
        clipBoard = new Clipboard("#btn");
        showToast("复制成功");
      });
      clipBoard.on("error", function () {
        showToast("复制失败");
      });
    },
    async startPush() {
      if (!this.push_url) {
        showToast("推流地址不存在");
        return;
      }
      if (!this.pushClient) {
        showToast("推流模块启动失败");
        return;
      }
      if (this.roomInfo.pstatus == 1) {
        showToast("请确认是否在其他端正在推流");
        return;
      }
      this.pushClient.startPush(this.push_url.push_url.artc);
      this.pushStatus = true;
      this.options[1] = {
        name: "停止直播",
        icon: "play-circle-o",
        act: "start",
        color: "#666666",
      };
    },
    stopPush() {
      if (this.pushClient) {
        this.pushClient.stopPush();
        this.stopCamera();
        this.pushStatus = false;
      }

      this.options[0] = {
        name: "开启预览",
        icon: "eye-o",
        act: "startcm",
        color: "#1ed6e0",
      };
      this.options[1] = {
        name: "开始直播",
        icon: "play-circle-o",
        act: "start",
        color: "#1ed6e0",
      };
    },
    changeCamera() {
      // 更换摄像头
      if (this.cameraList.length < 2) {
        showToast("不支持");
        return;
      }
      this.camera_idx = this.camera_idx == 1 ? 0 : 1;
      this.startCamera();
    },
    async startCamera() {
      if (!this.cameraList || !this.cameraList.length) {
        showToast("未发现摄像头");
        return;
      }
      const videoEl = this.pushClient.setRenderView("videoContainer");
      // 如果希望将本地预览静音（推出去的流不会静音）
      videoEl.muted = true;
      videoEl.controls = false;
      videoEl.setAttribute("autoplay", true);
      videoEl.setAttribute("playsinline", true);
      videoEl.setAttribute("x-webkit-airplay", true);
      videoEl.setAttribute("x5-video-player-type", "h5");
      videoEl.setAttribute("x5-video-player-fullscreen", true);
      videoEl.setAttribute("webkit-playsinline", true);

      videoEl.style.width = "100vw";
      videoEl.style.height = "100vh";
      videoEl.style.backgroundColor = "#000000";
      videoEl.style.background = `url(${this.roomInfo.bg}) no-repeat`;
      videoEl.style.backgroundSize = "100% 100%";
      videoEl.style.backgroundPosition = "top center";

      videoEl.style.objectFit = "cover";
      // videoEl.style.objectFit = "fill";
      // videoEl.style.objectFit = "none";

      this.pushClient.setVideoQuality("1080p_2");
      // 打开摄像头
      await this.pushClient.startCamera(
        this.cameraList[this.camera_idx].deviceId
      );
      // 打开麦克风
      await this.pushClient.startMicrophone();
      this.camera_status = true;
    },
    async stopCamera() {
      // 关闭摄像头
      await this.pushClient.stopCamera();
      // 关闭麦克风
      await this.pushClient.stopMicrophone();
      this.camera_status = false;
      this.pushStatus = false;
    },
    async createPusher() {
      let _this = this;
      // 创建推流实例
      const pushClient = AliRTSPusher.createClient();

      const checkResult = await AliRTSPusher.checkSupport();
      const deviceManager = await pushClient.getDeviceManager();
      // 获取摄像头列表
      const cameraList = await deviceManager.getCameraList();
      this.cameraList = cameraList;
      // 获取麦克风列表
      const micList = await deviceManager.getMicList();
      // console.log('deviceManager: ', deviceManager);
      // console.log('cameraList: ', cameraList);
      // console.log('micList: ', micList);
      // 监听错误事件
      pushClient.on("error", (err) => {
        showToast("出错了");
        this.pushVideoInfo = false;
        // setTimeout(function () {
        //   location.reload();
        // }, 600);
      });
      pushClient.on("connectStatusChange", (err) => {
        // console.log('==',err.status)
        if (parseInt(err.status) == 1) {
          showToast("推流已链接");
        } else if (parseInt(err.status) == 2) {
          showToast("推流开始");
        } else if (parseInt(err.status) == 3) {
          // 连接断开 或网络异常
          showToast("网络异常");
          this.pushVideoInfo = false;
          setTimeout(function () {
            location.reload();
          }, 600);
        }
      });
      pushClient.on("stats", (err) => {
        // 记录时时推流视频信息
        _this.pushVideoInfo = err.video;
      });
      return pushClient;
    },
    initWebSocket: function () {
      if (typeof WebSocket === "undefined") {
        showToast("您的浏览器不支持socket");
      } else {
        if (this.socket) {
          console.log("已链接");
          return;
        }
        // 实例化socket
        try {
          this.socket = new WebSocket(
            this.soketPath + "?token=" + this.userInfo.token
          );
          // 监听socket连接
          this.socket.onopen = this.open;
          // 监听socket错误信息
          this.socket.onerror = this.error;
          // 监听socket消息
          this.socket.onmessage = this.getMessage;
          // 监听断开
          this.socket.onclose = this.close;
        } catch (error) {
          console.log(error);
        }
      }
    },
    open: function () {
      // 心跳检测
      heartCheck.setSocket(this.socket, this.caster_id).reset().start();
    },
    close: function () {
      this.socket = null;
      this.lockReconnect = false;
      console.log("socket断开");
      this.reconnect();
    },
    // 断开重连操作
    reconnect() {
      if (this.isLogout) return;
      if (this.lockReconnect) return;
      this.lockReconnect = true;
      let _this = this;
      //没连接上会一直重连，设置延迟避免请求过多
      setTimeout(function () {
        _this.initWebSocket();
        _this.lockReconnect = false;
      }, 2000);
    },
    error: function (e) {
      console.log("socket连接失败", e);
    },
    selectAct(opt) {
      if (opt.act == "closeComment") {
        // 更改房间评论状态
        this.commentStatusRoom = !this.commentStatusRoom;

        this.$http
          .postData("/ShareLog/changeStatus", {
            caster_id: this.caster_id,
            field: "open_comment",
            status: this.commentStatusRoom ? 1 : 0,
            hide_success: false,
          })
          .then((res) => {
            if (this.commentStatusRoom) {
              opt.name = "评论(开)";
              opt.color = "#09a657";
            } else {
              opt.name = "评论(关)";
              opt.color = "#666666";
            }
            this.pushDanmu({
              id: Math.random(),
              text: this.commentStatusRoom ? "开启评论" : "关闭评论",
            });
          })
          .catch((error) => {});
      }
      if (opt.act == "changeCamer") {
        // 切换摄像头
        this.changeCamera();
      } else if (opt.act == "startcm") {
        if (!this.camera_status) {
          // 未开启
          if (!this.cameraList || !this.cameraList.length) {
            showToast("未发现摄像头");
            return;
          }
          showToast("预览开启");
          opt.name = "关闭预览";
          opt.icon = "closed-eye";
          opt.color = "#666666";
          // 开启预览
          this.startCamera();
        } else {
          if (this.pushStatus) {
            showToast("请先关闭直播");
            return;
          }
          showToast("预览关闭");
          opt.name = "开启预览";
          opt.icon = "eye-o";
          opt.color = "#1ed6e0";

          this.stopCamera();
        }
      } else if (opt.act == "start") {
        // 如果是推流状态 则执行停止操作
        if (this.pushStatus) {
          const beforeClose = (action) =>
            new Promise((resolve) => {
              resolve(action);
            });
          // 加一个确认
          showConfirmDialog({
            title: "提示",
            message: "确认停止直播吗",
            beforeClose,
          })
            .then((val) => {
              this.camera_status = false;
              this.stopPush();
            })
            .catch((e) => {
              console.log("已取消操作");
            });
        } else {
          if (!this.camera_status) {
            showToast("请先开启预览");
            return;
          }
          if (!this.push_url) {
            showToast("请更新推流地址");
            return;
          }
          const beforeClose = (action) =>
            new Promise((resolve) => {
              resolve(action);
            });
          // 加一个确认
          showConfirmDialog({
            title: "提示",
            message: "请确认已准备就绪，开启直播吗？",
            beforeClose,
          })
            .then((val) => {
              this.startPush();
            })
            .catch((e) => {
              console.log("已取消操作");
            });
        }
      } else if (opt.act == "getOnlineUser") {
        // 查询房间用户
        // this.send({
        //   type: "getUids",
        //   uid: this.userInfo.uid,
        //   mg_id: this.userInfo.uid,
        //   group: this.caster_id, //房间号进行分组
        // });

        this.listType = 5;
        this.viewSortData = true;
        this.boxTitle = "房间用户";
      } else if (opt.act == "geturl") {
        // 获取推流地址
        this.viewSortData = true;
        this.boxTitle = "推流地址";
        this.listType = "url";
      } else if (opt.act == "clears") {
        this.clears = !this.clears;
        this.danMulist = [];
        if (this.clears) {
          showToast("简洁模式开启");
          opt.name = "简洁(开)";
          opt.color = "#71d85f";
        } else {
          showToast("简洁模式关闭");
          opt.name = "简洁(关)";
          opt.color = "#666666";
        }
      } else if (opt.act == "sortData") {
        // 房间的邀请榜单
        this.viewSortData = true;
        this.boxTitle = "邀请榜单";
        this.listType = 1;
      } else if (opt.act == "myShare") {
        // 我的邀约
        this.viewSortData = true;
        this.boxTitle = "我的邀约";
        this.listType = 2;
      } else if (opt.act == "prize") {
        this.viewSortData = true;
        this.boxTitle = "答题记录";
        this.listType = 3;
      } else {
        showToast("未知操作");
      }
      this.viewApps = false;
    },
    getMessage: function (msg) {
      let data = JSON.parse(JSON.parse(JSON.stringify(msg.data)));

      if (data.hudong != null) {
        this.hudongData = { ...data.hudong };
      }
      if (data.client_count != null) {
        this.hudongData.client_count =
          data.client_count - 1 >= 0 ? data.client_count - 1 : 0;
      }
      let disMsg = ["sendComment", "caster", "changeRoomStatus"];

      if (disMsg.indexOf(data.type) !== -1) {
        // 允许的消息 可以展示
        if (data.type != "caster") {
          this.pushComment(data);
        }
      }

      if (data.type == "connect") {
        // 连接成功 绑定用户
        this.send({
          type: "login",
          uid: this.userInfo.uid,
          mg_id: this.userInfo.uid,
          nickname: this.userInfo.nickname,
          group: this.caster_id, //房间号进行分组
        });
      } else if (data.type == "ping") {
        // 收到 ping
      } else if (data.type == "caster") {
        // 直播状态变化
        showToast(data.live_msg);
        // 弃用
        if (data.live_status == "play") {
          this.pushStatus = true;
          // this.roomInfo.pstatus = 1;
          // this.videoInfo = data.videoInfo;
        } else {
          this.pushStatus = false;
          // this.roomInfo.pstatus = 2;
          // this.videoInfo = false;
        }
        // this.loadPlayer();
      } else if (data.type == "login") {
        this.pushDanmu({
          id: Math.random(),
          text: data.content,
        });
      } else if (data.type == "logout") {
        this.pushDanmu({
          id: Math.random(),
          text: data.content,
        });
      } else if (data.type == "updateTime") {
        // 更新累计时长
        this.totalTime = data.content.times;
        this.canSubmitQuestion = data.content.can_submit;
        this.noticeTips = data.content.msg;
      } else if (data.type == "getUids") {
        // 查询房间用户
        this.userIds = [];
        let tmp = [];
        Object.keys(data.content).map(function (key) {
          tmp.push(parseInt(data.content[key]));
        });
        this.userIds = tmp;
        this.listType = 5;
        this.viewSortData = true;
        this.boxTitle = "房间用户";
      } else if (data.type == "hudong") {
        // 互动
        if (
          (data.act_type == "good" && data.hudong.hasGood) ||
          (data.act_type == "subscribe" && data.hudong.hasSubscribe)
        ) {
          this.pushDanmu({
            id: Math.random(),
            text: data.nickname + ":" + data.content,
          });
        }
      } else if (data.type == "close") {
        console.log(data.content);
      } else if (data.type == "sendComment") {
      } else if (data.type == "clearMsg") {
        let status = data.status;
        if (status) {
          this.commentList = [];
        } else {
          showToast(data.content);
        }
      } else if (data.type == "changeRoomAuth") {
        // 主播端
        let act_type = data.act_type;
        if (act_type == "caster_status" && data.status == false) {
          // 关闭房间
          let _this = this;
          showToast("房间已关闭");
          setTimeout(function () {
            _this.$router.go(-1);
          }, 1500);
        }
      }
    },
    pushComment(data) {
      this.commentList.push(data);
      let tmp = this.commentList.slice(-100);
      this.commentList = [...tmp];
      let _this = this;
      setTimeout(function () {
        _this.topScrollClick();
      }, 10);
    },
    pushDanmu(data) {
      // 增加弹幕
      if (this.clears) {
        return;
      }
      this.danMulist.push(data);
      let tmp = this.danMulist.slice(-100);
      this.danMulist = [...tmp];
      console.log(this.danMulist);
    },
    // 发送消息给被连接的服务端
    send: function (params) {
      this.socket.send(JSON.stringify(params));
    },
    topScrollClick() {
      this.$nextTick(() => {
        this.scrollEl = this.$refs.comment_box;
        if (this.scrollEl) {
          this.scrollEl.scrollTo({
            top: this.scrollEl.scrollHeight,
            behavior: "smooth",
          });
        }
      });
    },
    openApps() {
      this.viewApps = true;
    },
  },
};
</script>
  <style scoped>
.play_status {
  position: fixed;
  z-index: 100;
  right: 10px;
  top: 10px;
  line-height: 30px;
  font-size: 24px;
}
.goback {
  width: 36px;
  height: 36px;
  line-height: 39px;
  text-align: center;
  border-radius: 50%;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.3);
  position: fixed;
  z-index: 100;
  left: 10px;
  top: 10px;
}
#videoContainer {
  width: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000;
}

.top_info {
  position: fixed;
  z-index: 20;
  right: 0px;
  left: 0px;
  top: 20px;
  font-size: 18px;
  text-align: center;
}
.top_info .item {
  background: rgba(255, 255, 255, 0.5);
  text-align: center;
  color: #ddd;
  padding: 6px 8px;
  border-radius: 4px;
  margin: 0 4px;
  color: #cc0000 !important;
}
.top_info .red {
  color: #cc0000 !important;
}
.play_area_b {
  background: #000000;
  overflow: hidden;
}
.bgbox2 {
  background: #ffffff;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  /* background: url('../../public/bg/bg1.jpg') top center no-repeat; */
  background-size: 100% 100%;
  text-align: center;
  line-height: 400px;
}
.pause {
  position: fixed;
  z-index: 11;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  overflow: hidden;
  text-align: center;
}
.pause .txt {
  margin-top: 100px;
  color: #4f4f4f;
}
.roomInfo {
  position: absolute;
  z-index: 12;
  top: 0px;
  left: 0px;
  right: 0px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}
.roomInfo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.tools {
  position: fixed;
  z-index: 20;
  right: 0px;
  top: 310px;
  width: 60px;
  height: auto;
  font-size: 12px;
}
.tools .item {
  display: block;
  margin: 10px auto;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.3);
  text-align: center;
  font-size: 20px;
  line-height: 36px;
  color: #333333;
}
.footer_box {
  position: fixed;
  z-index: 21;
  bottom: 0;
  height: auto;
  left: 0px;
  right: 0px;
  background: #ffffff;
}
.footer_box .content {
  display: flex;
  justify-content: left;
}
.van-field__control {
  font-size: 2rem !important;
}
.footer_box .emojo {
  padding: 10px;
  overflow: hidden;
  overflow-y: scroll;
  height: auto;
  min-height: 80px;
  max-height: 160px;
}
.footer_box .emojo span {
  line-height: 40px;
  font-size: 30px;
  margin: 4px;
}
.footer_box .icon {
  font-size: 36px;
  line-height: 64px;
  color: #333333;
  padding-left: 6px;
}

.foot_tools {
  position: fixed;
  left: 10px;
  bottom: 10px;
  right: 0px;
  z-index: 22;
  display: flex;
  justify-content: space-between;
}

.foot_tools .footer_input_tips {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 14px;
  height: 36px;
  width: 100%;
  line-height: 36px;
  padding: 0 10px;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
  color: #666666;
}
.foot_tools .bot_tool {
  flex-basis: 70px;
}

.foot_tools .bot_tool .item {
  float: left;
  margin-left: 10px;
  height: 36px;
  width: 36px;
  font-size: 22px;
  line-height: 36px;
  border-radius: 50%;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
}
.foot_tools .bot_tool .red {
  color: #cc0000;
}

.comment_box {
  padding: 10px;
  height: 50vh;
  position: fixed;
  left: 0;
  right: 10px;
  bottom: 50px;
  z-index: 12;
  color: #333;
  overflow: scroll;
  overflow-x: hidden;
}
.comment_box .item {
  width: 100%;
  position: relative;
  z-index: 10;
  margin-bottom: 4px;
}
.comment_box .item .nickname {
  color: #e6b100;
  font-weight: bolder;
  font-size: 1.5rem;
  -webkit-text-size-adjust: 100%;
}
.comment_box .item .text {
  color: #ffffff;
  font-size: 1.5rem;
  -webkit-text-size-adjust: 100%;
  padding: 2px 5px;
  border-radius: 10px;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.3); /* 背景颜色 */
  white-space: pre-line;
  word-break: break-all;
  border-radius: 4px;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.push_url {
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  white-space: pre-line;
  word-break: break-all;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.apps {
}
.apps .title {
  background-color: #ffffff;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  color: #666666;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(235, 235, 235);
}
.apps .cons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.apps .item {
  font-size: 12px;
  text-align: center;
  line-height: 24px;
  width: 25%;
  margin: 10px 0;
}
.apps .name {
  font-size: 12px;
  line-height: 20px;
}
.apps .icon {
  font-size: 20px;
  line-height: 20px;
  width: 20px;
  height: 20px;
}

/* 自定义内容 */
.myPopBox {
}
.myPopBox .con {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  background-color: #ffffff;
  overflow-y: scroll;
}
.myPopBox .title {
  position: fixed;
  z-index: 101;
  left: 0;
  right: 0;
  bottom: 50%;
  background-color: #ffffff;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  border-bottom: 1px solid rgb(235, 235, 235);
  border-radius: 10px 10px 0 0;
}
</style>
  
<template>
  <div class="hello">
    <van-sticky>
      <van-nav-bar
        :title="pageTitle"
        :left-text="leftText"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
      >
        <template #right v-if="act == 'share' || act == 'user'">
          <van-icon name="search" size="18" />
        </template>
        <template #right v-if="act == 'balance'">
          <van-icon name="user" size="18" />
        </template>
        <template #right v-if="act == 'teamData'">
          <van-button size="mini" type="primary">保存</van-button>
        </template>
      </van-nav-bar>

      <van-tabs
        v-model:active="activeName"
        @click-tab="onClickTab"
        sticky
        v-if="act == 'share' || act == 'user'"
        class="countBox"
      >
        <van-tab
          :title="item.title + item.value"
          :name="item.tag"
          :key="idx"
          v-for="(item, idx) in shareCount"
        ></van-tab>
      </van-tabs>
      <div v-if="act == 'prize'" class="countBox">
        <van-tag
          round
          type="primary"
          v-for="(item, idx) in shareCount"
          plain
          :key="idx"
          size="large"
          style="margin: 0 6px"
          >{{ item.title }} {{ item.value }}{{ item.tag }}</van-tag
        >
      </div>
      <div
        v-if="act == 'teamData' && teamDataInfo && inLoad == false"
        class="countBox_b"
      >
        <div v-if="teamDataInfo.caster" class="bitem">
          {{ teamDataInfo.caster }}
        </div>
        <div class="bitem" style="margin-bottom: 0px">
          <span>总邀约 {{ teamDataInfo.total }}人</span>
          <span>观看 {{ teamDataInfo.view }}人</span>
          <span>红包 {{ teamDataInfo.prize }}个</span>
        </div>
      </div>
      <template v-if="act == 'balance'">
        <div class="balanceBox">
          <div class="total">
            <span class="small">余额</span>{{ userInfo.balance }}
          </div>
          <span class="recharge" @click="showRechargeBox = true">充值</span>
          <div class="abc" style="margin-top: 20px">
            <van-tag
              round
              type="primary"
              v-for="(item, idx) in shareCount"
              plain
              :key="idx"
              size="large"
              style="margin: 0 6px"
              >{{ item.title }} ￥{{ item.value }}</van-tag
            >
          </div>
        </div>
        <div class="countBox" style="text-align: left">
          <van-tag
            round
            type="primary"
            v-for="(item, idx) in dateArr"
            @click="clickTab(idx)"
            :plain="activeTab == idx ? false : true"
            :key="idx"
            size="large"
            style="margin: 0 6px"
            >{{ item.title }}</van-tag
          >
        </div>
      </template>
    </van-sticky>
    <!-- 充值 -->
    <van-popup
      v-model:show="showRechargeBox"
      round
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <div style="margin-top: 16px">
        <div
          style="
            text-align: center;
            font-size: 20px;
            height: 50px;
            line-height: 40px;
            margin-bottom: 20px;
          "
        >
          账户充值
        </div>
        <van-cell-group inset label-width="auto">
          <van-field
            v-model="rechargeData.amount"
            label="金额"
            type="digit"
            placeholder="输入整数，最低10元"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button
            round
            block
            type="primary"
            native-type="submit"
            @click="rechargeDo"
            >充值</van-button
          >
        </div>
      </div>
    </van-popup>
    <!-- 筛选框 -->
    <van-popup
      v-model:show="showPopup"
      round
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <div style="margin-top: 16px">
        <van-cell-group inset label-width="auto">
          <!-- <van-field  label="用户身份">
                    <template #input>
                    <van-radio-group v-model="searchForm.grade" direction="horizontal">
                        <van-radio name="0">全部</van-radio>
                        <van-radio name="1">普通</van-radio>
                        <van-radio name="2">团队长</van-radio>
                    </van-radio-group>
                    </template>
                </van-field>
                <van-field  label="认证状态">
                    <template #input>
                    <van-radio-group v-model="searchForm.auth" direction="horizontal">
                        <van-radio name="0">全部</van-radio>
                        <van-radio name="1">未认证</van-radio>
                        <van-radio name="2">已认证</van-radio>
                    </van-radio-group>
                    </template>
                </van-field> -->
          <van-field
            v-model="searchForm.keyword"
            label="关键词"
            placeholder="请输入用户名"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button
            round
            block
            type="primary"
            native-type="submit"
            @click="toSearch"
            >查询</van-button
          >
        </div>
      </div>
    </van-popup>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        :loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getDataList"
      >
        <!-- 余额日志 -->
        <template v-if="act == 'balance'">
          <div
            class="balance"
            v-for="(item, idx) in dataList"
            :key="idx"
            @click="onClickItem(item)"
          >
            <div>
              <div class="title">{{ item.remark ?? "-" }}</div>
              <div class="times">{{ item.create_time }}</div>
            </div>
            <div class="price" v-if="parseFloat(item.amount) > 0">
              {{ item.amount }}
            </div>
            <div class="price" style="color: #14bf31" v-else>
              {{ item.amount }}
            </div>
          </div>
        </template>
        <!-- 我的分享 -->
        <template v-if="act == 'teamData'">
          <div v-for="(item, idx) in dataList" :key="idx">
            <div class="user_list">
              <div class="con">
                <!-- <div class="avatar">
                  <van-image
                    style="width: 100%; height: 100%"
                    round
                    :src="item.avatar"
                  />
                </div> -->
                <div class="head">
                  <div>
                    <div class="title">
                      {{ item.nickname }}
                      <span>
                        <van-tag
                          style="margin-right: 4px"
                          v-if="item.auth == 1"
                          round
                          type="success"
                          >证</van-tag
                        >
                        <van-tag
                          style="margin-right: 4px"
                          v-else
                          round
                          color="#aaaaaa"
                          >未认证</van-tag
                        >
                        <van-tag
                          round
                          v-if="item.grade > 0"
                          style="margin-right: 4px"
                          type="warning"
                          >团</van-tag
                        >
                        <van-tag
                          round
                          v-if="item.is_zb > 0"
                          style="
                            margin-right: 4px;
                            background-color: rgba(18, 163, 220, 0.8);
                          "
                          type="warning"
                          >主</van-tag
                        >
                        <van-tag
                          round
                          v-if="item.zuli > 0"
                          style="
                            margin-right: 4px;
                            background-color: rgba(90, 140, 220, 0.8);
                          "
                          type="warning"
                          >助理</van-tag
                        >
                        <van-tag
                          round
                          v-if="item.black > 0"
                          style="
                            margin-right: 4px;
                            background-color: rgba(0, 0, 0, 0.6);
                          "
                          type="warning"
                          >黑</van-tag
                        >
                        <van-tag
                          round
                          v-if="item.comment_status == 0"
                          style="
                            margin-right: 4px;
                            background-color: rgba(0, 0, 0, 0.6);
                          "
                          type="warning"
                          >禁</van-tag
                        >
                      </span>
                    </div>

                    <div class="mark">
                      答题：正确{{
                        item.share_count_info.questionSuccess
                      }}次，错误{{ item.share_count_info.questionFail }}次
                    </div>
                    <!-- <div class="mark" v-if="item.ip_info">
                      {{
                        item.ip_info
                          ? item.ip_info.province +
                            "." +
                            item.ip_info.city +
                            "." +
                            item.ip_info.area
                          : "-"
                      }}
                    </div> -->
                  </div>
                  <div class="times" style="line-height: 26px">
                    <template v-if="item.grade > 0">
                      <span
                        >邀请
                        <span style="color: #cc0000">{{
                          item.share_count_info.childCount
                        }}</span>
                        人</span
                      >
                      <br />
                    </template>
                    <span
                      >观看
                      <span style="color: #cc0000">{{
                        item.share_count_info.times
                      }}</span></span
                    >
                    <br />
                    <span
                      >红包
                      <span style="color: #cc0000">{{
                        item.share_count_info.prizeCount
                      }}</span></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 管理员查看全部用户 弃用 -->
        <template v-if="act == 'user'">
          <div
            class="user_list"
            v-for="(item, idx) in dataList"
            :key="idx"
            @click="onClickItem(item)"
          >
            <div v-if="!(item.nickname == '微信用户' && !item.parent_user_id)">
              <div class="avatar">
                <img :src="item.avatar" />
              </div>
              <div class="head">
                <div style="text-align: left">
                  <div class="title" v-if="item.is_admin == 0">
                    {{ item.nickname }}
                  </div>
                  <div
                    class="title"
                    v-if="item.is_admin == 1"
                    style="color: #cc0000"
                  >
                    {{ item.nickname }}

                    <!-- <van-tag style="margin-right: 4px;" v-if="item.auth==1" round type="success">证</van-tag>
                    <van-tag style="margin-right: 4px;" v-else round color="#aaaaaa">未认证</van-tag>
                    <van-tag round v-if="item.grade>0" style="margin-right: 4px;" type="warning">团</van-tag> -->
                    <van-tag
                      round
                      v-if="item.is_zb > 0"
                      style="
                        margin-right: 4px;
                        background-color: rgba(18, 163, 220, 0.8);
                      "
                      type="warning"
                      >主</van-tag
                    >
                    <van-tag
                      round
                      v-if="item.black > 0"
                      style="
                        margin-right: 4px;
                        background-color: rgba(0, 0, 0, 0.6);
                      "
                      type="warning"
                      >黑</van-tag
                    >
                  </div>
                  <div class="times">
                    红包：{{ item.countInfo.prizeCount }} /
                    {{ item.countInfo.prizeTotal }}
                  </div>
                  <div class="times">
                    观看：{{ item.countInfo.viewCount }} /
                    {{ item.countInfo.times }}
                  </div>
                  <div class="mark">
                    归属地：{{
                      item.ip_info
                        ? item.ip_info.province +
                          "." +
                          item.ip_info.city +
                          "." +
                          item.ip_info.area
                        : "-"
                    }}
                  </div>
                  <!-- <div class="times">{{ item.create_time }}</div> -->
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="act == 'prize'">
          <!-- 答题奖励 弃用 -->
          <div class="qlist" v-for="item in dataList" :key="item.id">
            <div class="text">
              {{ item.title }}<br />
              <span class="answer">a{{ item.create_time }}</span>
            </div>
            <div class="amount">￥{{ item.amount }}</div>
          </div>
        </template>
      </van-list>
    </van-pull-refresh>

    <!-- 未认证用户 -->
    <van-popup
      v-model:show="showDataPng"
      style="width: 80%"
      :close-on-click-overlay="true"
      round
    >
      <div class="qrcodeBox">
        <div
          style="text-align: center; width: 100%; height: 100%; margin: 0 auto"
        >
          <van-image
            fit="cover"
            style="width: 100%; height: 70vh"
            :src="pngUrl"
          >
          </van-image>
        </div>
        <div
          style="
            padding: 10px;
            line-height: 24px;
            font-size: 12px;
            text-align: center;
          "
        >
          请长按保存到手机
        </div>
      </div>
    </van-popup>
  </div>
</template>
  
<script>
import wx from "weixin-js-sdk"; // 引入微信JS-SDK库
import { showToast, showNotify } from "vant";
import html2canvas from "html2canvas";
export default {
  name: "RowList",

  props: {
    act: {
      type: String,
      default: "",
    },
    pageTitle: {
      type: String,
      default: "标题",
    },
    leftText: {
      type: String,
      default: "返回",
    },
    table: {
      type: String,
      default: "",
    },
    extId: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    function onCallBack(val) {
      emit("callBack", val);
    }
    function clickRightCallBack(val) {
      emit("clickRight", val);
    }
    return { onCallBack, clickRightCallBack };
  },
  data() {
    return {
      activeName: "all",
      userInfo: false,
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      showRechargeBox: false,
      dataList: [],
      per_page: 15,
      last_page: 0,
      current_page: 0,
      total: 0,
      showPopup: false,
      sortBy: "id",
      sortWay: "desc",
      rechargeData: {
        amount: "",
      },
      searchForm: {
        tag: "all",
        keyword: "",
        actTag: "",
      },
      shareCount: false,
      activeTab: 0,
      countUrl: false,
      teamDataInfo: false,
      showDataPng: false,
      pngUrl: false,
      dateArr: [
        {
          title: "今天",
          tag: "today",
        },
        {
          title: "昨天",
          tag: "yesterday",
        },
        {
          title: "本周",
          tag: "week",
        },
        {
          title: "本月",
          tag: "month",
        },
        {
          title: "全部",
          tag: "all",
        },
      ],
    };
  },
  mounted() {
    this.searchForm.actTag = this.act;

    if (this.act == "share") {
      this.searchForm.scence = "team_share_list";
    } else if (this.act == "teamData") {
      this.searchForm.scence = "share_from_detail";
      this.searchForm.caster_id = this.extId;
      this.per_page = 100000;
      // this.sortBy = "times";
      // this.sortWay = "desc";
    } else if (this.act == "prize") {
      this.searchForm.scence = "prizeList";
    } else if (this.act == "user") {
      this.searchForm.scence = "allUser";
    } else if (this.act == "balance") {
      this.searchForm.scence = "mybalance";
      this.activeName = "today";
      this.searchForm.tag = "today";
    }
    this.onRefresh();
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    window.document.title = this.pageTitle;
  },
  methods: {
    async savePageAsImage() {
      try {
        const element = document.body;
        const canvas = await html2canvas(element);
        const img = canvas.toDataURL("image/png");
        let _this = this;
        showNotify({
          message: "正在准备数据，请稍后!",
          color: "#ad0000",
          background: "#ffe1e1",
          duration: 3000,
        });
        setTimeout(function () {
          _this.showDataPng = true;
          _this.pngUrl = img;
        }, 3000);
        return;
        // 创建一个a标签模拟点击进行下载
        const a = document.createElement("a");
        a.href = img;
        a.download = "数据.png";
        a.click();
      } catch (error) {
        console.error("发生错误:", error);
        showToast("保存图片时发生错误");
      }
    },
    getUserInfo() {
      this.$http.postData("/User/getUserInfo", {}).then((res) => {
        localStorage.setItem("userInfo", JSON.stringify(res));
        this.userInfo = res;
      });
    },
    onRefresh() {
      this.dataList = [];
      this.current_page = 0;
      this.refreshing = true;
      this.finished = false;
      this.getDataList();
      if (this.act == "balance") {
        this.getUserInfo();
      }
    },
    toStartLive(item) {
      this.$router.push({
        path: "/live/push",
        query: {
          rid: item.id,
        },
      });
    },
    onClickTab(val) {
      this.activeName = val.name;
      if (this.act == "share" || this.act == "user") {
        this.searchForm.tag = val.name;
      } else if (this.act == "balance") {
        this.searchForm.tag = val.name;
      }
      this.onRefresh();
    },
    clickTab(idx) {
      this.activeTab = idx;
      if (this.act == "share" || this.act == "user") {
        this.searchForm.tag = this.shareCount[idx].tag;
      } else if (this.act == "balance") {
        this.searchForm.tag = this.dateArr[idx].tag;
      }
      this.onRefresh();
    },
    onClickLeft() {
      this.$router.go(-1);
      return;
      this.$router.push({ path: "/" });
    },
    onClickRight() {
      if (this.act == "share" || this.act == "user") {
        // 弹出搜索框
        this.showPopup = true;
      } else if (this.act == "teamData") {
        window.scrollTo({
          top: 0,
          behavior: "instant",
        });
        let _this = this;
        setTimeout(function () {
          _this.savePageAsImage();
        }, 100);
      }
    },
    onClickItem(val) {
      this.onCallBack(val);
    },
    toSearch() {
      this.dataList = [];
      this.current_page = 0;
      this.refreshing = true;
      this.showPopup = false;
      this.getDataList();
    },
    getDataList() {
      if (this.inLoad) {
        return;
      }
      if (!this.table || this.table == "") {
        showToast("参数错误");
        return;
      }
      this.inLoad = true;
      this.$http
        .postData("/RowList/getList", {
          table: this.table,
          pageInfo: {
            page_size: this.per_page,
            sortBy: this.sortBy,
            sortWay: this.sortWay,
          },
          page: this.current_page + 1,
          search: { ...this.searchForm },
          hide_success: true,
        })
        .then((res) => {
          this.inLoad = false;
          this.loading = false;
          this.refreshing = false;
          if (this.act == "teamData") {
            this.dataList = [];
            let array = [...res.data];
            array.sort((a, b) => b.times - a.times);

            let viewUser = 0,
              prizeCount = 0;
            array.map((item) => {
              this.dataList.push(item);
              if (item.times > 0) {
                viewUser += 1;
              }
              if (item.share_count_info.questionSuccess > 0) {
                prizeCount += 1;
              }
            });
            // 显示统计数据
            this.teamDataInfo = {
              total: res.total,
              view: viewUser,
              prize: prizeCount,
              caster: res.returnCaster ? res.returnCaster.title : false,
            };
            console.log(this.teamDataInfo);
          } else {
            this.teamDataInfo = false;
            res.data.map((item) => {
              this.dataList.push(item);
            });
          }

          this.current_page = res.current_page;
          this.total = res.total;

          if (res.last_page == res.current_page || res.last_page == 0) {
            this.finished = true;
          }
          if (res.current_page == 1) {
            if (this.act == "share") {
              this.getDataCount({
                scence: "my_share_list",
                table: "User",
              });
            } else if (this.act == "user") {
              this.getDataCount({
                scence: "all_user",
                table: "User",
              });
            } else if (this.act == "prize") {
              this.getDataCount({
                scence: "my_prize",
                table: "Question",
              });
            } else if (this.act == "balance") {
              this.getDataCount({
                scence: "balance",
                table: "Balance",
              });
            }
          }
        })
        .catch((error) => {
          this.inLoad = false;
          this.loading = false;
          this.finished = true;
          this.refreshing = false;
        });
    },
    getDataCount(tmp) {
      this.$http
        .postData("/RowList/getDataCount", {
          scence: tmp.scence,
          table: tmp.table,
          search: this.searchForm,
          hide_loading: true,
          hide_success: true,
          hide_erro: true,
        })
        .then((res) => {
          this.shareCount = res;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rechargeDo() {
      // if(!this.isWx()){
      //   showToast('请在微信中操作');
      //   return
      // }
      if (parseInt(this.rechargeData.amount) <= 0) {
        showToast("请确认充值金额");
        return;
      }
      this.$http
        .postData("/Recharge/applyOrder", {
          amount: this.rechargeData.amount,
          hide_success: false,
        })
        .then((res) => {
          this.rechargeData.amount = "";
          this.showRechargeBox = false;
          this.pullPay(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    isWx() {
      var ua = navigator.userAgent.toLowerCase(); //判断是否是微信浏览器
      var isWeixin = ua.indexOf("micromessenger") != -1; //判断是否
      return isWeixin;
    },
    pullPay(item) {
      this.loading = true;
      let that = this;
      this.$http
        .postData("/Pay/unify", {
          oid: item.id,
          pay_way: "wxpay",
          openid: this.userInfo.open_id ? this.userInfo.open_id : "",
          in_wx: this.isWx(),
        })
        .then((res) => {
          this.loading = false;
          const payInfo = res; // 后端返回的支付参数
          if (payInfo.trade_type == "JSAPI") {
            // 调用微信JS-SDK库的支付接口
            wx.config({
              appId: payInfo.appId,
              timestamp: payInfo.timestamp,
              nonceStr: payInfo.nonceStr,
              signature: payInfo.paySign,
              jsApiList: ["chooseWXPay"],
            });
            wx.ready(function () {
              wx.chooseWXPay({
                appId: payInfo.appId,
                timestamp: payInfo.timestamp,
                nonceStr: payInfo.nonceStr,
                package: payInfo.package,
                signType: payInfo.signType,
                paySign: payInfo.paySign,
                success: function (res) {
                  // 支付成功后的回调函数
                  showToast("支付成功");
                  that.getUserInfo();
                  that.onRefresh();
                },
                fail: function (res) {
                  // 支付失败后的回调函数
                  showToast(res.errMsg);
                  console.log("支付失败", res);
                },
              });
            });
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getUserInfo() {
      this.$http
        .postData("/User/getUserInfo", {})
        .then((res) => {
          localStorage.setItem("userInfo", JSON.stringify(res));
          this.userInfo = res;
        })
        .catch((error) => {});
    },
  },
};
</script>
  <style scoped>
.user_list {
  border-bottom: 1px solid rgb(235, 235, 235);
}
.user_list .footer {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  padding: 0px 10px;
  margin-bottom: 10px;
}
.user_list .con {
  padding: 0 10px;
  background-color: #ffffff;
  font-size: 12px;
  position: relative;
}
.user_list .head {
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  line-height: 26px;
  padding: 10px;
  /* padding-left: 56px; */
}
.user_list .head .title {
  font-size: 14px;
}
.user_list .head .times {
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}
.user_list .avatar {
  position: absolute;
  left: 10px;
  top: 16px;
  width: 40px;
  height: 40px;
}

.balance {
  margin: 10px;
  background-color: #ffffff;
  font-size: 14px;
  border-radius: 6px;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.balance .title {
  font-size: 14px;
  color: #000000;
  line-height: 30px;
  height: 30px;
}
.balance .times {
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  color: #666666;
}
.balance .price {
  color: #cc0000;
  line-height: 60px;
  font-size: 14px;
}
.infos {
  text-align: right;
  font-size: 12px;
  padding-right: 15px;
  line-height: 20px;
}
.infos .price {
  color: #cc0000;
  line-height: 30px;
  height: 30px;
  font-size: 14px;
}

.video_list {
  position: relative;
  padding: 10px;
  margin: 10px;
  min-height: 100px;
  padding-left: 120px;
  background-color: #ffffff;
  font-size: 14px;
  margin-top: 20px;
  border-radius: 6px;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.3);
}
.video_list .title {
  font-size: 1rem;
  line-height: 30px;
}
.video_list .desc {
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: #666666;
}
.video_list .avatar {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 10px;
  top: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.play {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
  right: 0px;
  height: 100px;
  width: 100px;
  color: #ffffff;
  font-size: 40px;
  text-align: center;
  line-height: 100px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
}
.live_tag {
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 10px;
  right: 0px;
}
/* 
  .video_list{
    position: relative;
    padding:10px;
    margin:10px;
    background-color: #ffffff;
    font-size: 14px;
    margin-top:20px;
    border-radius: 10px;
			box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.3);
  }
  .video_list .title{
    font-size: 1rem;
    line-height: 30px;
  }
  .video_list .desc{
    font-size: 0.8rem;
    line-height: 30px;
    color: #666666;
  }
  .video_list .avatar{
    width:100%;
    height: 160px;
  }
  .video_list .content{
    color: #666666;
    line-height: 24px;
    padding:10px;
    font-size: 0.8rem;
  }
  .play{
    position: absolute;
    z-index: 1;
    top:0px;
    left:0px;
    right:0px;
    height: 100%;
    color: #ffffff;
    font-size: 50px;
    text-align: center;
    line-height: 160px;
  } */

.van-cell {
  padding: 0;
  height: 40px;
  line-height: 40px;
}
.van-radio {
  padding: 0;
  height: 40px;
  line-height: 40px;
}

.countBox {
  padding: 10px;
  background-color: #fff;
  text-align: center;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
}
.countBox_b {
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
}
.countBox_b .bitem {
  height: 24px;
  line-height: 24px;
  margin-bottom: 8px;
}
.countBox_b span {
  margin-right: 10px;
}

.qlist {
  padding: 10px;
  margin: 10px;
  margin-top: 0px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.3);
}
.qlist .text {
  font-size: 14px;
  line-height: 30px;
}
.qlist .answer {
  color: #888888;
}
.qlist .amount {
  font-size: 14px;
  line-height: 60px;
  color: #cc0000;
}
.balanceBox {
  background: linear-gradient(135deg, #2770ba 0%, #00a1d3 40%) no-repeat;
  min-height: 30px;
  padding: 40px 20px;
}
.balanceBox .total {
  font-size: 40px;
  color: #ffffff;
}
.balanceBox .small {
  font-size: 14px;
  color: #ffffff;
  padding-right: 10px;
}
.balanceBox .recharge {
  display: block;
  width: auto;
  text-align: center;
  border-radius: 6px;
  line-height: 20px;
  padding: 4px 14px;
  color: #ffffff;
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 76px;
  font-size: 14px;
  background: linear-gradient(135deg, #e49f29 0%, #e59408 40%) no-repeat;
}
</style>
  
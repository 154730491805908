// 心跳检测，每隔一段时间检测连接状态，如果处于连接中，就像Server主动发送消息，来重置Server段与客户端的最大连接时间，如果已经断开，发起重连
var heartCheck = {
    // 9分钟发起一次心跳，比Server端设置的连接时间稍微小一点，在接近断开的情况下以通信的方式去重置连接时间
    timeout: 30000,
    serverTimeoutObj: null,
    socket:null,
    group:0,
    reset: function () {
        clearTimeout(this.serverTimeoutObj);
        return this;
    },
    setSocket(obj,group){
        this.socket=obj
        this.group=group
        return this
    },
    start: function () {
        let _this = this
        this.serverTimeoutObj = setInterval(function () {
            if (_this.socket.readyState == 1) {
                // console.log("连接状态，发送消息保持连接");
                _this.socket.send(JSON.stringify({
                    type:'ping',
                    group:_this.group
                }));
                // 如果获取到消息，说明连接正常，重置心跳检测
                _this.reset().start();
            } else {
                console.log("断开连接，尝试重连");
            }
        }, this.timeout)
    }
};

export default heartCheck
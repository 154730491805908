function loadJs(src="") {
    return new Promise((resolve,reject)=>{
        let hasScript = document.getElementById('aliScript')
        if(hasScript){
            console.log('不重复加载')
            resolve();
        }
        let link = document.createElement('link');
        link.href="/aliJs/aliplayer-min.css";
        // link.href="https://g.alicdn.com/apsara-media-box/imp-web-player/2.16.3/skins/default/aliplayer-min.css"
        link.rel="stylesheet";
        link.id= 'aliStyle'
        document.head.appendChild(link)
        
        let scripta = document.createElement('script');
        scripta.type = "text/javascript";
        scripta.src= "/aliJs/aliplayercomponents.min.js";
        scripta.id= 'aliCommentsScript'
        document.head.appendChild(scripta)
    
        let script = document.createElement('script');
        script.type = "text/javascript";
        script.src= "/aliJs/aliplayer-h5-min.js";
        script.id= 'aliScript'
    
        document.head.appendChild(script);
            script.onload = ()=>{
                resolve();
            }
            script.onerror = ()=>{
                reject();
            }
        })
    }
    export default loadJs
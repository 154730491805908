<template>
    <div class="conts">
        <van-pull-refresh v-if="ready" v-model="refreshing" @refresh="onRefresh">
          <van-list
          :loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getDataList"
          >
          <div class="video_list" v-for="(item,idx) in dataList" :key="idx">
            <div class="desc">
              <span>{{ item.title }}</span>
                <van-tag style="margin:0 5px;" v-if="item.type==1" plain type="danger">{{ item.type_txt }}</van-tag>
                <van-tag style="margin:0 5px;" v-if="item.type==2" plain type="success">{{ item.type_txt }}</van-tag>
                <van-tag v-if="item.pstatus==0" plain type="primary">{{ item.pstatus_txt }}</van-tag>
                <van-tag v-if="item.pstatus==1" plain type="success">{{ item.pstatus_txt }}</van-tag>
                <van-tag v-if="item.pstatus==2" plain type="warning">{{ item.pstatus_txt }}</van-tag>
                <van-tag v-if="item.pstatus==3" plain type="danger">{{ item.pstatus_txt }}</van-tag>
            </div>
            <div class="desc">栏目： {{ item.collect_title }}</div>
            <div class="desc">时间: {{ item.opens.times[0] }}</div>
            <div class="desc" style="display: flex;justify-content: space-between;margin-right:100px;">
                <span v-for="(i,c) in item.caster_count_data" :key="c">{{ i.title+'：'+i.value }}</span>
            </div>
            <div class="right_btn">
              <div class="item1" @click.stop="viewData(item,1)">邀约用户</div>
              <div class="item2" @click.stop="viewData(item,2)">红包数据</div>
            </div>
          </div>
          </van-list>
        </van-pull-refresh>
    </div>
</template>
<script>
export default {
    name: 'casterList',
    props: {
        roomId: {
            default:0
        }
    },
    data(){
        return {
            ready:false,
            loading:false,
            refreshing:false,
            finished:false,
            error:false,
            dataList:[],
            per_page:10,
            last_page:0,
            current_page:0,
            total:0
        }
    },
    mounted() {
        this.searchForm = {
            scence:'caster_list',
            room_id:this.roomId,
            actTag:'admin'
        }
        this.ready = true
    },
    methods: {
        onRefresh(){
            this.dataList=[]
            this.current_page=0
            this.refreshing = true
            this.getDataList()
        },
        viewData(item,type){
        // 主播查看数据
        let tmp = [
          'admCaster',
          item.room_id,
          item.id,
          type
        ]
        localStorage.setItem('casterInfo',JSON.stringify({
          collect_title:item.collect_title,
          title:item.title
        }))
        this.$router.push({name:'viewData',params:{spm:tmp.join('#')}})
      },
        getDataList(){
        if(this.inLoad){
          return
        }
        this.inLoad = true
        this.$http.postData('/RowList/getList',{
          table:'Caster',
          search:{...this.searchForm},
          pageInfo:{
            page_size: this.per_page,
          },
          page: this.current_page+1,
          hide_success:true
        }).then(res=>{
            this.inLoad = false
            this.loading = false
            this.refreshing = false
            res.data.map(item=>{
              this.dataList.push(item)
            })
            this.current_page = res.current_page
            this.total = res.total

            if(res.last_page == res.current_page || res.last_page==0){
                this.finished = true
            }
        }).catch(error=>{
            this.inLoad = false
            this.loading = false
            this.finished = true
            this.refreshing = false
        })
      },
    },
  }
  </script>
  <style scoped>
  .conts{
    min-height: 30vh;
  }
  .video_list{
    position: relative;
    padding:10px;
    margin:10px;
    min-height: 100px;
    background-color: #ffffff;
    font-size: 14px;
    margin-top:20px;
    border-radius: 6px;
	box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.3);
  }
  .video_list .title{
    font-size: 1rem;
    line-height: 30px;
  }
  .video_list .desc{
    font-size: 0.8rem;
    line-height: 1.5rem;
    color: #666666;
  }
  .video_list .avatar{
    width: 100px;
    height: 100px;
    position: absolute;
    left:10px;
    top:10px;
    border-radius: 10px;
    overflow: hidden;
  }
  .play{
    position: absolute;
    z-index: 1;
    top:10px;
    left:10px;
    right:0px;
    height: 100px;
    width:100px;
    color: #ffffff;
    font-size: 40px;
    text-align: center;
    line-height: 100px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
  }
  .right_btn{
    position: absolute;
    z-index: 1;
    right:10px;
    top:10px;
  }
  .right_btn .item1{
    background: rgb(56 141 212);
    border-radius: 6px;
    height: 24px;
    line-height: 24px;
    padding:0 10px;
    font-size: 0.7rem;
    color:#ffffff;
  }
  .right_btn .item2{
    background: rgb(237, 157, 60);
    border-radius: 6px;
    height: 24px;
    line-height: 24px;
    padding:0 10px;
    font-size: 0.7rem;
    color:#ffffff;
    margin: 9px 0;
  }
  .right_btn .item3{
    background: rgb(153, 160, 157);
    border-radius: 6px;
    height: 24px;
    line-height: 24px;
    padding:0 10px;
    font-size: 0.7rem;
    color:#ffffff;
  }
  .right_btn .item4{
    background: rgb(48, 204, 150);
    border-radius: 6px;
    height: 24px;
    line-height: 24px;
    padding:0 10px;
    font-size: 0.7rem;
    color:#ffffff;
  }
  </style>
<template>
  <div class="hello">
    <van-sticky>
      <van-nav-bar
        :title="pageTitle"
        :left-text="leftText"
        :right-text="rightText"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
      >
        <template #right>
          <van-icon name="chart-trending-o" size="18" />
        </template>
      </van-nav-bar>
      <van-notice-bar
        :scrollable="false"
        v-if="tips"
        left-icon="volume-o"
        scrollable
        :text="tips"
      />
    </van-sticky>

    <van-popup
      v-model:show="showPopup"
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <div>
        <div
          style="
            text-align: center;
            font-size: 16px;
            height: 40px;
            line-height: 40px;
          "
        >
          总数据
        </div>
        <van-cell-group
          inset
          label-width="auto"
          v-if="countInfo && countInfo.length"
        >
          <van-field
            v-for="(item, idx) in countInfo"
            input-align="right"
            :key="idx"
            v-model="item.value"
            readonly
            :label="item.title"
          >
            <template #button v-if="item.unit">{{ item.unit }}</template>
          </van-field>
        </van-cell-group>
        <div
          v-else
          style="
            height: 40px;
            text-align: center;
            color: #666666;
            line-height: 40px;
          "
        >
          暂无数据
        </div>
      </div>
    </van-popup>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        :loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getDataList"
      >
        <!-- 答题奖励 -->
        <div class="user_list" v-for="(item, idx) in dataList" :key="idx">
          <div class="avatar">
            <img :src="item.avatar" />
          </div>
          <div class="head">
            <div style="text-align: left">
              <div class="title">{{ item.nickname }}</div>
              <div class="times">{{ item.create_time }}</div>
            </div>
            <div class="infos">
              <div class="price">￥{{ item.amount }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
  
<script>
import { showToast, showNotify } from "vant";
export default {
  name: "prizeData",
  props: {
    pageTitle: {
      type: String,
      default: "标题",
    },
    leftText: {
      type: String,
      default: "返回",
    },
    tips: {
      type: String,
      default: "",
    },
    rightText: {
      type: String,
      default: "",
    },
    detail: {
      type: Object,
      default: {},
    },
  },
  setup(props, { emit }) {
    function onCallBack(val) {
      emit("callBack", val);
    }
    function clickRightCallBack(val) {
      emit("clickRight", val);
    }
    return { onCallBack, clickRightCallBack };
  },
  data() {
    return {
      nowTab: "all",
      userInfo: false, //用户信息
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      dataList: [],
      per_page: 20,
      last_page: 0,
      current_page: 0,
      total: 0,
      searchForm: {},
      showPopup: false,
      casterInfo: false,
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.casterInfo = JSON.parse(localStorage.getItem("casterInfo"));
    window.document.title = this.pageTitle;
    if (!this.detail.caster_id || !this.detail.room_id) {
      showToast("参数错误");
      return;
    }
    this.searchForm = {
      scence: this.detail.scence,
      room_id: this.detail.room_id,
      caster_id: this.detail.caster_id,
    };
  },
  methods: {
    onRefresh() {
      this.dataList = [];
      this.current_page = 0;
      this.refreshing = true;
      this.getDataList();
    },
    onClickTab(val) {
      this.nowTab = val.name;
      this.searchForm.actTag = val.name;
      this.onRefresh();
    },
    onClickLeft() {
      this.$router.go(-1);
      return;
    },
    onClickRight() {
      this.getDataCount();
    },
    onClickItem(val) {
      this.onCallBack(val);
    },
    getDataCount() {
      this.$http
        .postData("/RowList/getDataCount", {
          ...this.searchForm,
          table: "Caster",
        })
        .then((res) => {
          this.countInfo = res;
          this.showPopup = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataList() {
      if (this.inLoad) {
        return;
      }
      this.inLoad = true;
      this.$http
        .postData("/RowList/getList", {
          table: "Question",
          search: this.searchForm,
          pageInfo: {
            page_size: this.per_page,
          },
          page: this.current_page + 1,
          hide_success: true,
        })
        .then((res) => {
          this.inLoad = false;
          this.loading = false;
          this.refreshing = false;
          res.data.map((item) => {
            this.dataList.push(item);
          });
          this.current_page = res.current_page;
          this.total = res.total;

          if (res.last_page == res.current_page || res.last_page == 0) {
            this.finished = true;
          }
        })
        .catch((error) => {
          this.inLoad = false;
          this.loading = false;
          this.finished = true;
          this.refreshing = false;
        });
    },
  },
};
</script>
  <style scoped>
.user_list {
  margin: 10px;
  background-color: #ffffff;
  font-size: 14px;
  border-radius: 6px;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.3);
  position: relative;
}
.user_list .head {
  padding-left: 60px;
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
}
.user_list .head .title {
  font-size: 14px;
  color: #000000;
  line-height: 30px;
  height: 30px;
}
.user_list .head .times {
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  color: #666666;
}
.user_list .head .mark {
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  color: #666666;
}
.user_list .avatar {
  position: absolute;
  left: 15px;
  top: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.user_list .avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.user_list .infos {
  line-height: 50px;
  padding-right: 10px;
  color: #ff0000;
}
</style>
  
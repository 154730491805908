import axios from 'axios'
import { showLoadingToast,showSuccessToast,showToast,showFailToast,closeToast } from 'vant';
import router from '@/router'

/**
 * @description 记录和显示错误
 * @param {Error} error 错误对象
 */

// 加载效果
let loading={
  show:function(){
    showLoadingToast({
        message: '加载中...',
        forbidClick: false,
        duration:0,
      })
  },hide:function(){
    closeToast({closeAll:true})
  }
}

let msgObj={
  success:function(msg){
    showToast(msg)
  },error:function(msg){
    showToast(msg)
  }
}


const http = axios.create({
    baseURL:'/wechat',
    timeout:60000,
    withCredentials:true,//正式使用时关闭
    headers:{}
})

http.interceptors.request.use(config => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    let timeStamp = new Date().getTime()
    let token = ''
    if(userInfo){
      token = userInfo.token
    }
    config.headers={
        "Content-Type":'application/json',
        Authorization:token,
        timeStamp:timeStamp
    }
    if(config.hasOwnProperty("upload") && config.upload==true){
      config.headers={
        "Content-Type":'multipart/form-data',
        Authorization:token,
        timeStamp:timeStamp
      }
    }

    if(!(config.hasOwnProperty("hide_loading") && config.hide_loading==true)){
      loading.show()
    }
    return config
  },
  error => {
    // 发送失败
    return Promise.reject(error)
})

// 响应拦截
http.interceptors.response.use(
    response => {
      loading.hide()
      const res = response.data
      
      if ( res.code === undefined || res.code != 0 ) {
        if(!(response.config.hasOwnProperty("hide_error") && response.config.hide_error==true)){
          msgObj.error(res.msg)
        }
        return Promise.reject(res)
      } else {
        if(!(response.config.hasOwnProperty("hide_success") && response.config.hide_success==true)){
          msgObj.success(res.msg)
        }
        return res.data;
      }
    },
    error => {
      loading.hide()
      let errorMsg = '出错了'
      const status = error.response?error.response.status:500
      switch (parseInt(status)) {
        case 400: 
          errorMsg = error.response.data.msg?error.response.data.msg:'请求出错了';
          break
        case 401:
          errorMsg = error.response.data.msg?error.response.data.msg:'未授权，请登录';
          setTimeout(() => {
            localStorage.removeItem('userInfo')
            localStorage.setItem('redirect_url',location.href)
            router.push({ name: 'login' })
          }, 500)
        break
        case 403: errorMsg = '拒绝访问'; break
        case 404: errorMsg = `请求出错: ${error.response.config.url}`; break
        case 408: errorMsg = '请求超时'; break
        case 500: errorMsg = '请求出错了'; break
        case 501: errorMsg = '服务未实现'; break
        case 502: errorMsg = '网关错误'; break
        case 503: errorMsg = '服务不可用'; break
        case 504: errorMsg = '网关超时'; break
        case 505: errorMsg = 'HTTP版本不受支持'; break
        default: break
      }
      if(error.config && !(error.config.hasOwnProperty("hide_error") && error.config.hide_error==true)){
        msgObj.error(errorMsg)
      }
      return Promise.reject(error.response.data)
})

export default http
<template>
  <div class="hello" v-if="ready">
    <!-- <van-sticky>
        <van-nav-bar v-if="userInfo.is_admin" left-text="返回" left-arrow @click-left="onClickLeft" :title="step==1?'我的直播间':'创建场次'"></van-nav-bar>
        <van-nav-bar v-else :title="step==1?'我的直播间':'创建场次'" @click-right="onClickRight"></van-nav-bar>
      </van-sticky> -->

    <template v-if="step == 1">
      <!-- 栏目 -->
      <div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            :loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <van-collapse
              v-model="activeNames"
              v-for="(collect, idx) in dataList"
              :key="idx"
              accordion
            >
              <!-- 循环栏目 -->
              <van-collapse-item :title="collect.title" :name="'collect' + idx">
                <template #value>
                  共
                  <span style="color: #cc0000">{{
                    collect.caster_list.length
                  }}</span>
                  场
                </template>
                <!-- 循环场次 -->
                <template
                  v-if="collect.caster_list && collect.caster_list.length"
                >
                  <div
                    class="collective"
                    v-for="(item, idx2) in collect.caster_list"
                    :key="idx2"
                  >
                    <div class="thumb">
                      <van-image
                        fit="cover"
                        style="width: 100%; height: 100%"
                        :src="item.thumb"
                      />
                    </div>
                    <div class="desc" style="color: #333333">
                      名称：<span style="margin-right: 4px">{{
                        item.title
                      }}</span>
                      <van-tag
                        plain
                        type="danger"
                        style="
                          background-color: rgba(0, 0, 0, 0.4);
                          color: #fff;
                        "
                        v-if="item.status == 0"
                        >已停用</van-tag
                      >
                      <template v-else>
                        <van-tag
                          plain
                          type="primary"
                          v-if="item.pstatus == 0"
                          >{{ item.pstatus_txt }}</van-tag
                        >
                        <van-tag
                          plain
                          type="success"
                          v-if="item.pstatus == 1"
                          >{{ item.pstatus_txt }}</van-tag
                        >
                        <van-tag
                          plain
                          type="warning"
                          v-if="item.pstatus == 2"
                          >{{ item.pstatus_txt }}</van-tag
                        >
                        <van-tag
                          plain
                          type="success"
                          v-if="item.pstatus >= 3"
                          >{{ item.pstatus_txt }}</van-tag
                        >
                      </template>
                      <van-tag
                        style="margin: 0 5px"
                        v-if="item.type == 1"
                        plain
                        type="danger"
                        >{{ item.type_txt }}</van-tag
                      >
                      <van-tag
                        style="margin: 0 5px"
                        v-if="item.type == 2"
                        plain
                        type="success"
                        >{{ item.type_txt }}</van-tag
                      >
                    </div>
                    <div class="desc">时间：{{ item.opens.times[0] }}</div>
                    <div class="desc" v-if="item.type == 1">
                      回看：
                      <van-tag v-if="item.replay_status" plain type="success">{{
                        item.replay_status_txt
                      }}</van-tag>
                      <van-tag v-else type="success">{{
                        item.replay_status_txt
                      }}</van-tag>

                      <van-tag
                        style="margin-left: 5px"
                        v-if="item.replay"
                        plain
                        @click.stop="
                          disLink(item.id, 'replay', '确认要关闭回看吗？')
                        "
                        type="success"
                        >开启</van-tag
                      >
                      <van-tag
                        style="margin-left: 5px"
                        v-else
                        plain
                        @click.stop="
                          disLink(item.id, 'replay', '确认要开启回看吗？')
                        "
                        type="warning"
                        >关闭</van-tag
                      >
                    </div>
                    <div class="desc">
                      <span
                        v-for="(i, c) in item.caster_count_data"
                        style="margin-right: 10px"
                        :key="c"
                        >{{ i.title + "：" + i.value + i.unit }}</span
                      >
                    </div>
                    <div class="right_btn">
                      <div
                        class="item item2"
                        @click.stop="viewData(collect.title, item, 1)"
                      >
                        邀约
                      </div>
                      <div
                        class="item item3"
                        @click.stop="viewData(collect.title, item, 2)"
                      >
                        红包
                      </div>

                      <div class="item item4" @click.stop="showMakeLink(item)">
                        分享
                      </div>
                      <div
                        class="item item1"
                        @click.stop="goPush(item)"
                        v-if="item.type == 1"
                      >
                        进入房间
                      </div>
                      <!-- <div class="item item1" @click.stop="goPush(item)" v-if="item.type==1 && item.pstatus==0">开播</div>
                    <div v-if="item.type==1 && item.pstatus>=3 && item.replay==1" class="item item1" @click.stop="viewReplay(item)">回看</div>
                    <div v-if="item.type==1 && item.pstatus==2" class="item item2" @click.stop="resetCaster(item.id)">恢复</div> -->
                    </div>
                  </div>
                </template>
                <div class="nodata" v-else>没有数据</div>
              </van-collapse-item>
            </van-collapse>
          </van-list>
        </van-pull-refresh>
      </div>
      <div class="add_collect">
        <van-button
          style="width: 80px"
          size="small"
          type="default"
          @click="addCollect"
          >新增栏目</van-button
        >
        <van-button
          style="width: 80px; margin-left: 10px"
          size="small"
          type="primary"
          @click="addCaster"
          >创建场次</van-button
        >
        <!-- <van-button style="width:80px;margin-left: 10px;" size="small" type="success" @click="waterSet">水印设置</van-button> -->
      </div>
    </template>
    <!-- 创建场次 -->
    <create-caster
      v-if="step == 2"
      :roomId="roomInfo ? roomInfo.id : 0"
      @callBack="afterSave"
    ></create-caster>
    <!-- 设置水印 -->
    <set-water v-if="step == 3" @callBack="backWater"></set-water>
    <!-- 生成海报 -->
    <QrcodeNew
      v-if="showPosterBox"
      @onClose="onClosePoster"
      :info="posterInfo"
    />
    <!-- 创建链接并分享 主播的链接仅团队长可访问 -->
    <van-popup
      v-model:show="showMakeLinkBox"
      round
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <div style="margin-top: 16px">
        <div
          style="
            text-align: center;
            font-size: 16px;
            line-height: 40px;
            margin-bottom: 10px;
          "
        >
          链接设置
        </div>
        <van-cell-group inset label-width="auto">
          <van-field label="自动认证">
            <template #input>
              <div>
                <div><van-switch v-model="saveLinkData.has_auth" /></div>
                <div style="font-size: 0.6rem; color: #cc0000">
                  开启后,用户识别进入自动认证
                </div>
              </div>
            </template>
          </van-field>
          <van-field label="设为团队长">
            <template #input>
              <div>
                <div><van-switch v-model="saveLinkData.has_grade" /></div>
                <div style="font-size: 0.6rem; color: #cc0000">
                  开启后,用户将自动认证为团队长
                </div>
              </div>
            </template>
          </van-field>
          <van-field
            label="锁客上限"
            v-if="saveLinkData.has_auth || saveLinkData.has_grade"
          >
            <template #input>
              <div>
                <div>
                  <van-stepper
                    v-model="saveLinkData.max_count"
                    :min="0"
                    :max="9999999"
                  />
                </div>
                <div style="font-size: 0.6rem; color: #cc0000">
                  免认证模式必须设置锁客上限
                </div>
              </div>
            </template>
          </van-field>
        </van-cell-group>
        <div style="margin: 16px">
          <van-button
            round
            block
            type="primary"
            native-type="submit"
            @click="submitLinkDo"
            >生成海报</van-button
          >
        </div>
      </div>
    </van-popup>

    <!-- 创建栏目 -->
    <van-popup
      v-model:show="showCollectBox"
      round
      position="bottom"
      :style="{ minHeight: '30%' }"
    >
      <div style="margin-top: 16px">
        <div
          style="
            text-align: center;
            font-size: 16px;
            line-height: 40px;
            margin-bottom: 10px;
          "
        >
          新增栏目
        </div>
        <van-cell-group inset label-width="auto">
          <van-field
            v-model="saveCollectData.title"
            :maxlength="10"
            label="栏目名称"
            type="text"
            placeholder="填写名称"
          ></van-field>
        </van-cell-group>
        <div style="margin: 16px">
          <van-button round block type="primary" @click="addCollectDo"
            >保 存</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>
  
<script>
import QrcodeNew from "@/components/QrcodeNew.vue";
import CreateCaster from "../components/CreateCaster.vue";
import SetWater from "../components/SetWater.vue";
import { showToast, showConfirmDialog } from "vant";
export default {
  name: "myRoom",
  components: {
    CreateCaster,
    QrcodeNew,
    SetWater,
  },
  data() {
    return {
      step: 0,
      ready: false,
      roomInfo: false,
      loading: false,
      showBox: false,
      finished: false,
      refreshing: false,
      activeNames: "",
      error: false,
      dataList: [],
      showPosterBox: false,
      posterInfo: {},
      per_page: 10,
      last_page: 0,
      current_page: 0,
      total: 0,
      showMakeLinkBox: false,
      nowCaster: false,
      showCollectBox: false,
      inload: false,
      saveLinkData: {
        has_auth: 0,
        has_grade: 0,
        type: 1,
        max_count: 0,
        caster_id: "",
        room_id: "",
        title: "",
        online: 1,
        amount_min: 0,
        amount_max: 0,
        collect_id: 0,
      },
      saveCollectData: {
        title: "",
      },
    };
  },
  watch: {
    step(newName, oldName) {
      if (newName == 1) {
        window.document.title = "我的直播间";
      } else if (newName == 2) {
        window.document.title = "创建场次";
      } else if (newName == 3) {
        window.document.title = "水印管理";
      }
    },
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // if (!this.userInfo.is_zb) {
    //   showToast("没有权限");
    //   return;
    // }
    // this.getRoomInfo();
    this.step = 1;
    this.ready = true;
    this.inload = false;
  },
  methods: {
    getRoomInfo() {
      this.inload = true;
      this.$http
        .postData("/Room/getRoomInfo", {
          hide_success: true,
        })
        .then((res) => {
          this.roomInfo = res;
          this.step = 1;
          this.ready = true;
          this.inload = false;
        })
        .catch((error) => {
          this.inload = false;
          console.log(error);
        });
    },
    backWater() {
      this.step = 1;
    },
    onRefresh() {
      this.dataList = [];
      this.current_page = 0;
      this.refreshing = true;
      this.onLoad();
    },
    addCollect() {
      this.showCollectBox = true;
      this.saveCollectData = {
        title: "",
      };
    },
    viewReplay(item) {
      if (item.share_link_list) {
        this.$router.push({ path: "/detail/" + item.share_link_list.code });
      }
    },
    addCollectDo() {
      if (this.inload) {
        console.log("重复");
        return;
      }

      if (!this.saveCollectData.title) {
        showToast("请填写栏目名称");
        return;
      }
      this.inload = true;
      this.$http
        .postData("/Collective/editRow", {
          ...this.saveCollectData,
          hide_success: true,
        })
        .then((res) => {
          this.inload = false;
          this.showCollectBox = false;
          this.onRefresh();
        })
        .catch((error) => {
          this.inload = false;
          console.log(error);
        });
    },
    showMakeLink(item) {
      this.nowCaster = { ...item };
      if (item.share_link_list) {
        // 已有分享链接
        let tmp = { ...item.share_link_list };
        tmp.opens = item.opens;
        tmp.title = item.title;
        this.makeShare(tmp);
        return;
      }
      this.saveLinkData.caster_id = item.id;
      this.saveLinkData.collect_id = item.collect_id;
      this.saveLinkData.room_id = item.room_id;
      this.showMakeLinkBox = true;
    },
    submitLinkDo() {
      if (this.inload) {
        console.log("重复");
        return;
      }
      if (this.saveLinkData.has_grade) {
        this.saveLinkData.has_auth = 1;
      }
      if (
        (this.saveLinkData.has_auth || this.saveLinkData.has_grade) &&
        parseInt(this.saveLinkData.max_count) == 0
      ) {
        showToast("请设置锁客上限");
        return;
      }
      this.inload = true;

      this.$http
        .postData("/ShareLink/makeLink", {
          ...this.saveLinkData,
          hide_success: true,
        })
        .then((res) => {
          this.inload = false;
          this.showMakeLinkBox = false;
          this.nowCaster.share_link_list = res;
          this.onRefresh();
          this.showMakeLink(this.nowCaster);
        })
        .catch((error) => {
          this.inload = false;
          console.log(error);
        });
    },
    makeShare(item) {
      let codeUrl = "https://m.qianbaiyou88.com/detail/" + item.code;
      this.posterInfo = {
        nickname: this.userInfo.nickname,
        avatar: this.userInfo.avatar,
        title: item.title,
        has_auth: item.has_auth || item.has_grade ? true : false,
        times: "开播时间 " + item.opens.times[0],
        codeUrl: codeUrl,
      };
      this.showPosterBox = true;
    },
    onClosePoster(val) {
      this.showPosterBox = false;
    },
    goPush(item) {
      // 去开播
      if (!item["play_code"]) {
        showToast("参数错误");
        return;
      }
      if (!item["status"]) {
        showToast("已停用");
        return;
      }
      // if (item["pstatus"]) {
      //   showToast("状态为【准备中】才可操作");
      //   return;
      // }
      this.$router.push({ name: "push", params: { code: item.play_code } });
    },
    viewData(collect_title, item, type) {
      // 主播查看数据
      let tmp = ["zbCaster", item.room_id, item.id, type];
      localStorage.setItem(
        "casterInfo",
        JSON.stringify({
          title: item.title,
          collect_title: collect_title,
        })
      );
      this.$router.push({ name: "viewData", params: { spm: tmp.join("#") } });
    },
    disLink(id = 0, field = "status", msg = "确认更换状态吗？") {
      const beforeClose = (action) =>
        new Promise((resolve) => {
          resolve(action);
        });
      // 加一个确认
      showConfirmDialog({
        title: "提示",
        message: msg,
        beforeClose,
      })
        .then((val) => {
          this.disLinkDo(id, field);
        })
        .catch((e) => {
          console.log("已取消操作");
        });
    },
    disLinkDo(id, field = "status") {
      this.$http
        .postData("/Caster/disItemDo", {
          itemId: id,
          field: field,
          tag: "Caster",
        })
        .then((res) => {
          this.onRefresh();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetCaster(caster_id) {
      // 状态为结束 需不需要重置为准备阶段
      const beforeClose = (action) =>
        new Promise((resolve) => {
          resolve(action);
        });
      // 加一个确认
      showConfirmDialog({
        title: "提示",
        message: "直播已结束，需要恢复为准备状态吗？",
        beforeClose,
      })
        .then((val) => {
          this.$http
            .postData("/Caster/resetCaster", {
              caster_id: caster_id,
            })
            .then((res) => {
              this.onRefresh();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onClickLeft() {
      if (this.step == 1) {
        this.$router.go(-1);
      } else {
        this.step = 1;
      }
    },
    onClickRight() {
      if (this.step == 1) {
        this.step = 2;
      } else {
        this.step = 1;
      }
    },
    addCaster() {
      this.step = 2;
    },
    waterSet() {
      this.step = 3;
    },
    afterSave(res) {
      this.step = 1;
      if (!res) {
        return;
      }
      this.current_page = 0;
      this.dataList = [];
      this.onLoad();
    },
    onLoad() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$http
        .postData("/RowList/getList", {
          search: {
            scence: "collective_list",
            actTag: "zubo",
          },
          table: "Collective",
          hide_success: true,
          pageInfo: {
            page_size: this.per_page,
          },
          page: this.current_page + 1,
        })
        .then((res) => {
          this.loading = false;
          this.refreshing = false;
          res.data.map((item) => {
            this.dataList.push(item);
          });
          this.current_page = res.current_page;
          this.total = res.total;
          if (res.last_page == res.current_page || res.last_page == 0) {
            this.finished = true;
            return;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.refreshing = false;
          this.finished = true;
        });
    },
  },
};
</script>
  <style scoped>
.hello {
}
.room_id {
  text-align: center;
  color: #1989fa;
  background: #f3f3f3;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  margin: 0 auto;
}
.collective {
  position: relative;
  padding: 10px 0;
  background-color: #ffffff;
  font-size: 14px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.3);
}
.collective .thumb {
  width: 70px;
  height: 70px;
  border-radius: 6px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 10px;
}
.collective .title {
  font-size: 1rem;
  line-height: 30px;
}
.collective .desc {
  padding-left: 100px;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: #666666;
}
.right_btn {
  padding-left: 100px;
  display: flex;
  justify-content: first baseline;
}
.right_btn .item {
  padding: 0 6px;
  margin-right: 10px;
}
.right_btn .item1 {
  background: rgb(56 141 212);
  border-radius: 6px;
  height: 24px;
  line-height: 24px;
  font-size: 0.7rem;
  color: #ffffff;
}
.right_btn .item2 {
  background: rgb(237, 157, 60);
  border-radius: 6px;
  height: 24px;
  line-height: 24px;
  font-size: 0.7rem;
  color: #ffffff;
}
.right_btn .item3 {
  background: rgb(153, 160, 157);
  border-radius: 6px;
  height: 24px;
  line-height: 24px;
  font-size: 0.7rem;
  color: #ffffff;
}
.right_btn .item4 {
  background: rgb(48, 204, 150);
  border-radius: 6px;
  height: 24px;
  line-height: 24px;
  font-size: 0.7rem;
  color: #ffffff;
}
.add_collect {
  position: fixed;
  z-index: 2;
  bottom: 10px;
  left: 0px;
  right: 0px;
  text-align: center;
}
.nodata {
  text-align: center;
  color: #999999;
}
</style>
  
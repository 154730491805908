import { createApp } from 'vue'
import App from './App.vue'
// 1. 引入你需要的组件
import vant from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';

import router from './router'
import apis from '@/libs/apis'
let app = createApp(App)

// import VueCanvasPoster from 'vue-canvas-poster'
// import visibility from 'vue-visibility-change';
// app.use(visibility);

// visibility.change((evt, hidden) => {
//     app.config.globalProperties.$isHide = hidden
//     console.log(app.config.globalProperties.$isHide)
// });

app.config.globalProperties.$http = apis
app.config.globalProperties.$version = 234
const str = "😀|😆|🙂|😅|🙃|😇|😘|😙|😜|🤗|🥰|😍|😋|🤭|🫢|🫡|🤔|🙄|😴|🥵|🤠|🥺|😤|💖|💞|💘|💗|💜|🧡|🙏|👍|👏|🫶"
app.config.globalProperties.$emojos = str.split('|')
app.use(router).use(vant).mount('#app')

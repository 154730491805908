<template>
    <div>
      <template v-if="ready">
        <!-- 查看邀约数据 -->
        <user-data pageTitle="邀约用户" v-if="contentDetail.type==1" :tips="tips" :detail="contentDetail"></user-data>
        <!-- 查看红包记录 -->
        <prize-data pageTitle="红包数据" v-if="contentDetail.type==2" :tips="tips" :detail="contentDetail"></prize-data>
      </template>
      <div style="text-align: center;height: 90px;line-height: 90px;color: #999999;" v-else>请稍后</div>

      
    </div>
</template>
  
<script>
import userData from './userData.vue'
import prizeData from './prizeData.vue'
import { showToast } from 'vant'
  export default {
    name: 'viewData',
    components:{
      userData,
      prizeData
    },
    data(){
        return {
            activeName:'',
            contentDetail:{},
            ready:false,
            userOptions:[],
            showUserAction:false,
            selectItem:false,
        }
    },
    mounted() {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let casterInfo = JSON.parse(localStorage.getItem('casterInfo'))
      this.tips = `【${casterInfo.collect_title}】${casterInfo.title}`
      const spm = (this.$route.params.spm!=null && this.$route.params.spm) ? this.$route.params.spm : false
      if(!spm){
        showToast('参数错误')
        return
      }
      let spmArr = spm.split('#')
      this.contentDetail = {
        scence:spmArr[0],
        room_id:spmArr[1]!=null?spmArr[1]:0,
        caster_id:spmArr[2]!=null?spmArr[2]:0,
        type:spmArr[3]!=null?spmArr[3]:0,
      }
      this.ready=true
    },
    methods: {
      
    },
  }
  </script>
  <style scoped>
  .hello{
  }
  
  </style>
  
<template>
  <div class="hello">
    <div v-if="needReload" style="text-align: center; color: #999999">
      <div style="height: 100px; line-height: 100px">
        播放过程被中断，请重新连接
      </div>
      <div>
        <van-button type="success" @click="reloadPage">重新连接</van-button>
      </div>
    </div>
    <div v-else-if="!network" style="text-align: center; color: #999999">
      <div style="height: 100px; line-height: 100px">网络异常，请重新连接</div>
      <div>
        <van-button type="success" @click="reloadPage">重新连接</van-button>
      </div>
    </div>
    <div v-else>
      <template v-if="hasLoad">
        <!-- 未认证用户 -->
        <van-popup
          v-model:show="showAuthBox"
          :close-on-click-overlay="false"
          round
        >
          <div class="qrcodeBox">
            <div
              style="
                text-align: center;
                width: 100%;
                height: 100%;
                margin: 0 auto;
              "
              v-if="userAuth && userAuth.qrcode"
            >
              <van-image
                fit="cover"
                style="width: 100%; height: 70vh"
                :src="userAuth.qrcode"
              >
                <!-- <template v-slot:loading>
                                <van-loading type="spinner" size="80" />
                            </template> -->
              </van-image>
            </div>
            <div
              style="
                padding: 20px;
                line-height: 30px;
                font-size: 14px;
                text-align: center;
              "
            >
              请长按保存二维码，添加工作人员微信
            </div>
          </div>
        </van-popup>
        <!-- <div class="goHome" v-if="userAuth && userAuth.is_admin">
          <van-icon
            style="font-size: 20px"
            v-if="canGoBack"
            name="arrow-left"
            @click="goUrl"
          />
          <van-icon
            style="font-size: 20px"
            v-else
            @click="goUrl"
            name="wap-home-o"
          />
        </div> -->

        <!-- 视频文件直播 -->
        <template v-if="roomInfo.type == 2">
          <!-- <van-sticky :offset-top="30"> -->
          <div
            class="play_area"
            :class="clears ? 'insmp' : ''"
            @click="hideInput"
            v-if="roomInfo.pstatus == 1 && showPlayer"
          >
            <!-- <van-barrage v-model="danMulist" ref="danmu" :auto-play="true"> -->
            <ali-player
              ref="aply"
              @onBarrageError="barrageError"
              @onBarrageEnd="barrageEnd"
              @onBarrageLoading="onLoading"
              @onBarragePause="barragePause"
              @onBarragePlay="onPlay"
              :videoInfo="videoInfo"
              :windowHeight="windowHeight"
              :marginTop="marginTop"
            ></ali-player>
            <!-- </van-barrage> -->
          </div>
          <div
            class="play_area"
            style="padding: 0px 0"
            :class="clears ? 'insmp' : ''"
            @click="hideInput"
            v-else
          >
            <!-- <van-barrage v-model="danMulist" ref="danmu" :auto-play="true"> -->
            <van-image
              class="thumb"
              fit="cover"
              position="center"
              :src="roomInfo.thumb"
            />
            <!-- </van-barrage> -->
            <div class="mask">
              {{ roomInfo.opens.tips }} {{ roomInfo.opens.times[0] }}
            </div>
          </div>
          <!-- </van-sticky> -->
          <div class="bgbox" :style="bgStyle" @click="hideInput"></div>
          <div style="padding-top: 300px">
            <van-tabs
              v-model:active="activeName"
              v-if="!clears"
              @click-tab="onClickTab"
            >
              <van-tab name="talk" title="交流互动">
                <!-- 评论 -->
                <div class="comment_box" @click="hideInput" ref="comment_box">
                  <div
                    class="item"
                    v-for="(comment, idx) in commentList"
                    :key="idx"
                  >
                    <span class="text" v-if="comment.to_uid">
                      <span class="nickname">{{
                        comment.nickname + "@" + comment.to_nickname
                      }}</span
                      >{{ "：" + comment.content }}
                    </span>
                    <span class="text" v-else>
                      <span class="nickname">{{ comment.nickname }}</span
                      >{{ "：" + comment.content }}
                    </span>
                  </div>
                </div>
                <!-- 发言区 -->
                <div class="footer_box" v-if="showInput">
                  <div class="content">
                    <van-icon
                      class="icon"
                      @click="changeEmojo"
                      name="smile-o"
                    />
                    <van-field
                      v-model="sendData.content"
                      placeholder="请文明发表评论"
                      :maxlength="maxLength"
                      ref="inputRef"
                    >
                      <template #button>
                        <van-button type="success" @click="sendComment"
                          >发送</van-button
                        >
                      </template>
                    </van-field>
                  </div>
                  <div class="emojo van-hairline--top" v-if="viewEmojo">
                    <span
                      v-for="(item, idx) in emojos"
                      :key="idx"
                      @click="clickEmojo(item)"
                      >{{ item }}</span
                    >
                  </div>
                </div>
                <div class="foot_tools" v-if="!showInput">
                  <div class="footer_input_tips" @click="showContent">
                    请文明发表评论
                  </div>
                  <!-- 底部右侧小工具 -->
                  <div class="bot_tool">
                    <div class="item" @click="openApps">
                      <van-icon name="apps-o" />
                    </div>
                    <div class="item red" @click="showSharePoster">
                      <van-icon name="share-o" />
                    </div>
                  </div>
                </div>
              </van-tab>
              <van-tab name="intro" title="内容介绍">
                <div class="room_content" v-html="roomInfo.content"></div>
              </van-tab>
            </van-tabs>
          </div>
        </template>
        <!-- 真人直播 -->
        <template v-if="roomInfo.type == 1">
          <template v-if="roomInfo.pstatus < 2">
            <div
              class="bgbox2"
              :style="bgStyle"
              v-if="!showPlayer"
              @click="hideInput"
            >
              <div
                style="
                  line-height: 1.8rem;
                  font-size: 1.2rem;
                  color: #ffffff;
                  padding-top: 40px;
                "
              >
                {{ roomInfo.opens.tips }}<br />{{ roomInfo.opens.times[0] }}
              </div>
            </div>
            <div
              class="play_area_b"
              style="height: 100vh; width: 100vw; background-color: #fff"
              @click="hideInput"
              v-else
            >
              <ali-player
                ref="aply"
                @onBarrageError="barrageError"
                @onBarrageEnd="barrageEnd"
                @onBarrageLoading="onLoading"
                @onBarragePause="barragePause"
                @onBarragePlay="onPlay"
                :videoInfo="videoInfo"
              ></ali-player>
            </div>
          </template>
          <template v-else>
            <!-- 回放 -->
            <template
              v-if="roomInfo.replay && roomInfo.replay_status && videoInfo"
            >
              <span class="replay">回放</span>
              <div
                class="play_area_b"
                style="height: 100vh; width: 100vw; background-color: #fff"
                @click="hideInput"
              >
                <ali-player
                  ref="aply"
                  @onBarrageError="barrageError"
                  @onBarrageEnd="barrageEnd"
                  @onBarrageLoading="onLoading"
                  @onBarragePause="barragePause"
                  @onBarragePlay="onPlay"
                  :videoInfo="videoInfo"
                ></ali-player>
              </div>
            </template>
            <div class="bgbox2" :style="bgStyle" @click="hideInput" v-else>
              <div
                style="
                  line-height: 1.8rem;
                  font-size: 1.2rem;
                  color: #ffffff;
                  padding-top: 40px;
                "
              >
                {{ roomInfo.opens.tips }}
              </div>
            </div>
          </template>
        </template>
        <!-- 答题弹窗 -->
        <div
          style="
            position: fixed;
            z-index: 100;
            left: 0;
            right: 0;
            bottom: 0px;
            background-color: #ffffff;
          "
          v-if="showAnswerBox"
        >
          <van-notice-bar
            left-icon="info-o"
            color="#cc0000"
            v-if="questionConfig"
            :scrollable="true"
            :text="
              '累计观看' +
              questionConfig.min_time +
              '分钟可答题,已观看' +
              watchTimeTxt
            "
          />
          <div
            class="ques_box"
            v-for="(question, idx) in questionList"
            :key="idx"
          >
            <div class="title">{{ idx + 1 }}、{{ question.title }}</div>
            <van-radio-group
              :disabled="!canSubmitQuestion"
              v-model="question.user_answer"
            >
              <van-radio
                style="font-size: 14px"
                :name="aidx + 1"
                :key="aidx"
                v-for="(ans, aidx) in question.list"
                >{{ getAnsArr(aidx) }}、{{ ans }}</van-radio
              >
            </van-radio-group>
          </div>
          <div style="margin: 10px; text-align: center">
            <van-button
              @click="submitQuestion"
              :disabled="!canSubmitQuestion"
              block
              round
              type="primary"
              >答题赢红包</van-button
            >
          </div>
        </div>
        <!-- 评论区 -->
        <template v-if="!clears && roomInfo.type == 1">
          <div
            :class="roomInfo.isZb ? 'comment_box_zb' : 'comment_box'"
            @click="hideInput"
            ref="comment_box"
          >
            <div class="item" v-for="(comment, idx) in commentList" :key="idx">
              <span class="text" v-if="comment.to_uid">
                <span class="nickname">{{
                  comment.nickname + "@" + comment.to_nickname
                }}</span
                >{{ "：" + comment.content }}
              </span>
              <span class="text" v-else>
                <span class="nickname">{{ comment.nickname }}</span
                >{{ "：" + comment.content }}
              </span>
            </div>
          </div>
          <!-- 发言区 -->
          <div class="footer_box" v-if="showInput">
            <div class="content">
              <van-icon class="icon" @click="changeEmojo" name="smile-o" />
              <van-field
                v-model="sendData.content"
                placeholder="请文明发表评论"
                :maxlength="maxLength"
                ref="inputRef"
              >
                <template #button>
                  <van-button type="success" @click="sendComment"
                    >发送</van-button
                  >
                </template>
              </van-field>
            </div>
            <div class="emojo van-hairline--top" v-if="viewEmojo">
              <span
                v-for="(item, idx) in emojos"
                :key="idx"
                @click="clickEmojo(item)"
                >{{ item }}</span
              >
            </div>
          </div>
          <div class="foot_tools" v-if="!showInput">
            <div class="footer_input_tips" @click="showContent">
              请文明发表评论
            </div>
            <!-- 底部右侧小工具 -->
            <div class="bot_tool">
              <div class="item" @click="openApps">
                <van-icon name="apps-o" />
              </div>
              <div class="item red" @click="showSharePoster">
                <van-icon name="share-o" />
              </div>
            </div>
          </div>
        </template>
        <!-- 工具栏 -->
        <div class="tools">
          <div v-if="clears" class="item" @click="clears = false">
            <van-icon name="brush-o" />
          </div>
          <template v-else>
            <div class="item red">
              <van-badge :content="roomInfo.hudong.view">
                <van-icon name="fire-o" />
              </van-badge>
            </div>
            <div class="item" @click="doHuDong('subscribe')">
              <van-icon
                name="like"
                class="red"
                v-if="roomInfo.hudong.hasSubscribe"
              />
              <van-icon name="like-o" v-else />
            </div>
            <div class="item" @click="doHuDong('good')">
              <van-badge :content="roomInfo.hudong.good">
                <van-icon
                  name="good-job"
                  class="red"
                  v-if="roomInfo.hudong.hasGood"
                />
                <van-icon name="good-job-o" v-else />
              </van-badge>
            </div>
            <div
              class="item"
              v-if="roomInfo.questionList"
              @click="showAnswerBox = true"
            >
              <van-icon name="balance-o" class="red" />
            </div>
          </template>
        </div>
      </template>
      <div v-else class="waiting">{{ loadTips }}</div>
    </div>
    <!-- 修改昵称 -->
    <van-dialog
      v-model:show="showNickname"
      title="修改昵称"
      close-on-click-overlay
      :show-confirm-button="false"
    >
      <div
        style="
          margin: 20px;
          border: solid 1px #dedede;
          border-radius: 10px;
          overflow: hidden;
        "
      >
        <van-field
          v-model="newName"
          input-align="center"
          maxlength="10"
          size="small"
          placeholder="最多10个字"
        />
      </div>
      <div style="width: 100%; padding: 10px; text-align: center">
        <van-button
          @click="submitName"
          size="small"
          style="padding: 4px 20px; width: 90px"
          type="success"
          >修改</van-button
        >
      </div>
    </van-dialog>
    <!-- 获得红包 -->
    <van-popup v-model:show="viewBound" :close-on-click-overlay="false" round>
      <div class="bounds">
        <div class="btop"></div>
        <div
          style="
            margin-top: 40px;
            text-align: center;
            font-size: 20px;
            font-weight: bolder;
          "
        >
          <div class="price">
            ￥<b>{{ boundAmount }}</b>
          </div>
        </div>
        <div class="tips">恭喜您获得红包</div>
        <div class="btns" @click="reciveBound">开心收下</div>
        <div class="icon"><van-icon name="gold-coin-o" /></div>
      </div>
    </van-popup>
    <!-- 菜单 -->
    <van-popup
      v-model:show="viewApps"
      round
      position="bottom"
      :style="{ height: popHeight }"
    >
      <template #default>
        <div class="apps">
          <div class="title">更多操作</div>
          <div class="cons">
            <template v-for="(menu, idx) in options">
              <div
                class="item"
                v-if="menu.act != 'shareLink'"
                :key="idx"
                @click="selectAct(menu)"
              >
                <van-icon
                  class="icon"
                  :style="{ color: menu.color }"
                  :name="menu.icon"
                />
                <div class="name">{{ menu.name }}</div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </van-popup>
    <!-- 弹出层 各类列表 -->
    <div class="myPopBox" v-if="viewSortData">
      <div
        class="my_bg"
        style="
          position: fixed;
          z-index: 1001;
          bottom: 0;
          top: 0;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.5);
        "
        @click="closeMyBox"
      ></div>
      <div class="title">{{ boxTitle }}</div>
      <div class="con">
        <!-- 分享榜 -->
        <top-list
          :casterId="caster_id"
          :code="code"
          v-if="roomInfo && listType == 1"
        ></top-list>
        <!-- 我的分享 -->
        <share-list
          @onCallBack="selectUser"
          @returnTotal="returnTotal"
          :userId="userInfo.uid"
          :casterId="caster_id"
          :roomId="roomInfo.room_id"
          v-if="roomInfo && listType == 2"
        ></share-list>
        <!-- 我的红包记录 -->
        <prize-list
          :casterId="caster_id"
          v-if="roomInfo && listType == 3"
        ></prize-list>
        <!-- 专属链接 -->
        <share-link-list
          @onCallBack="showSharePosterChild"
          :collectId="roomInfo.collect_id"
          :casterId="caster_id"
          :roomId="roomInfo.room_id"
          v-if="roomInfo && listType == 4"
        ></share-link-list>
        <!-- 助理查看房间用户 -->
        <user-list
          :userIds="userIds"
          :userAuth="userAuth"
          @returnTotal="returnTotalZuli"
          :casterId="roomInfo.id"
          :roomId="roomInfo.room_id"
          v-if="roomInfo && listType == 5"
        ></user-list>
      </div>
    </div>
    <!-- 分享海报 -->
    <QrcodeNew
      v-if="showPosterBox"
      @onClose="onClosePoster"
      :info="posterInfo"
    />

    <!-- 对用户进行操作 -->
    <!-- <van-popup v-model:show="showUserAction" round position="bottom" :style="{ minHeight: '30%' }">
        <template #default>
                <div class="apps_new">
                    <div class="title van-hairline--bottom">【管理】{{selectItem.nickname}}</div>
                        <van-grid :column-num="4" :gutter="10" square>
                            <van-grid-item v-for="(menu,idx) in userOptions" :key="idx" @click="selectUserActionDo(menu)">
                                <div class="item">
                                    <van-icon class="icon" :style="{color:menu.color}" :name="menu.icon" />
                                    <div>{{ menu.name }}</div>
                                </div>
                            </van-grid-item>
                        </van-grid>
                </div>
            </template>
        </van-popup> -->
  </div>
</template>
<script>
import axios from "axios";
import AliPlayer from "@/components/AliPlayerNew.vue";
import ShareList from "@/components/ShareList.vue";
import ShareLinkList from "@/components/ShareLinkList.vue";
import TopList from "@/components/TopList.vue";
import PrizeList from "@/components/PrizeList.vue";
import { WxShare } from "@/libs/WxShare";
import heartCheck from "@/libs/heartCheck";
import QrcodeNew from "@/components/QrcodeNew.vue";
import UserList from "@/components/UserList.vue";

import { ref } from "vue";
import {
  showNotify,
  showToast,
  BarrageInstance,
  showConfirmDialog,
  showDialog,
} from "vant";
export default {
  name: "Home",
  components: {
    AliPlayer,
    ShareList,
    ShareLinkList,
    TopList,
    PrizeList,
    BarrageInstance,
    QrcodeNew,
    UserList,
  },
  data() {
    return {
      from: false,
      watchTime: 0,
      marginTop: "0px",
      windowHeight: "100%",
      isBack: false, //是否后台运行
      userAuth: false,
      update_time_limit: 5,
      showPosterBox: false,
      viewBound: false,
      boundAmount: "0.00",
      showAuthBox: false,
      showAnswerBox: false,
      listType: 1, // 1分享榜单 2我的邀请
      canSubmitQuestion: false,
      loadTips: "请稍后",
      totalTime: 0,
      canGoBack: false,
      viewEmojo: false,
      showNewUser: false,
      newUserNotice: false,
      visComment: false,
      showInput: false,
      hasLoad: false,
      pageTitle: "...",
      current_time: 0,
      instance: null,
      playInfo: [],
      roomInfo: false,
      videoInfo: false,
      danMulist: [],
      actMax: false,
      noctr: false,
      room_id: 0, //直播间
      caster_id: 0, //场次
      code: false,
      uid: false,
      sendData: { content: "" },
      userInfo: false,
      showPlayer: false,
      nowQues: false,
      showQuestionBox: false,
      quesTmp: [],
      commentList: [],
      parent_id: 0,
      socket: null,
      lockReconnect: false,
      soketPath: "wss://m.qianbaiyou88.com/websocket/",
      sockArr: [],
      tmpArr: [],
      pageHeight: 0,
      scrollEl: false,
      inLoading: false,
      viewApps: false,
      clears: false,
      checkCurrTimer: null, //定时累计观看时长
      watchTimer: null, //定时上报时长至后端
      watchTimeTxt: "0秒",
      clientCount: 0,
      isLogout: false,
      auth: false,
      isgroup: false,
      bgStyle: {},
      popHeight: "250px",
      ansArr: ["A", "B", "C", "D", "E", "F", "G"],
      options: [], //操作选项
      userOptions: [],
      showUserAction: false,
      commentStatusRoom: true,
      commentStatusUser: true,
      showNickname: false, //修改昵称
      newName: "",
      emojos: [],
      questionList: [],
      questionConfig: false,
      boxTitle: "",
      viewSortData: false, //展示邀请榜单
      posterInfo: {},
      userIds: [], //在线用户列表
      hideStatus: false,
      needReload: false,
      badWords: [],
      maxLength: 0,
      network: true,
    };
  },
  setup() {
    const danmu = ref("danmu");
    const aply = ref < AliPlayer > null;
    const activeName = ref("question");
    return { danmu, aply, activeName };
  },
  watch: {},
  unmounted() {
    // 监听是否后台运行
    document.removeEventListener("visibilitychange", this.listenVisible);
    this.clearTimer();
    this.isLogout = true;
  },
  mounted() {
    this.isLogout = false;
    this.clearTimer();
    // 检测后台运行
    document.addEventListener("visibilitychange", this.listenVisible);
    // 检测断网
    window.addEventListener("offline", () => {
      this.network = false;
      if (this.socket) {
        this.socket.close();
      }
    });
    window.addEventListener("online", () => {
      this.network = true;
    });
    // 房间号
    this.code =
      this.$route.params.code != null && this.$route.params.code
        ? this.$route.params.code
        : false;
    // 团队长 显示返回
    this.canGoBack = history.state && history.state.back != null ? true : false;
    // this.from = (this.$route.query.from!=null && this.$route.query.from) ? true : false
    //
    this.noctr =
      this.$route.query.noctr != null && this.$route.query.noctr
        ? this.$route.query.noctr
        : false;
    this.mdk =
      this.$route.query.mdk != null && this.$route.query.mdk
        ? this.$route.query.mdk
        : false;
    // 当前用户信息
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.uid = this.userInfo ? this.userInfo.uid : 0;

    if (!this.code) {
      showToast("资源地址异常");
      return;
    }
    this.emojos = this.$emojos;
    this.getCasterInfo();
  },
  methods: {
    reloadPage() {
      location.reload();
    },
    clearTimer() {
      if (this.checkCurrTimer) {
        window.clearInterval(this.checkCurrTimer);
        this.checkCurrTimer = null;
      }
      if (this.watchTimer) {
        window.clearInterval(this.watchTimer);
        this.watchTimer = null;
      }
    },
    formatTime() {
      let theTime = localStorage.getItem("watchTime_" + this.caster_id)
        ? localStorage.getItem("watchTime_" + this.caster_id)
        : 0;

      this.watchTime = theTime;

      var theTime1 = 0; // 分
      var theTime2 = 0; // 小时
      var theTime3 = 0; // 天

      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60);
          theTime1 = parseInt(theTime1 % 60);
          if (theTime2 > 24) {
            //大于24小时
            theTime3 = parseInt(theTime2 / 24);
            theTime2 = parseInt(theTime2 % 24);
          }
        }
      }
      var result = "";

      if (theTime == 0) {
        result = "0秒";
      }
      if (theTime > 0) {
        result = "" + parseInt(theTime) + "秒";
      }
      if (theTime1 > 0) {
        result = "" + parseInt(theTime1) + "分" + result;
      }
      if (theTime2 > 0) {
        result = "" + parseInt(theTime2) + "小时" + result;
      }
      if (theTime3 > 0) {
        result = "" + parseInt(theTime3) + "天" + result;
      }

      this.watchTimeTxt = result;
    },
    checkShowQuestionBox() {
      if (!this.questionConfig) {
        console.log("未开启问答");
        return;
      }
      if (this.roomInfo.pstatus < 2) {
        // 直播结束后可答题
        this.canSubmitQuestion = false;
        console.log("直播未结束");
        return;
      }
      if (this.questionConfig.success) {
        this.canSubmitQuestion = false;
        console.log("已答题");
        return;
      }
      if (this.questionConfig.retry < 1) {
        this.canSubmitQuestion = false;
        console.log("机会已用完");
        return;
      }
      // 累计观看时长
      let min = this.questionConfig.min_time * 60;
      // 是否在答题时间范围内
      let now_time = parseInt(new Date().getTime() / 1000);
      // now_time + this.questionConfig.diff_time < this.questionConfig.start ||

      if (now_time + this.questionConfig.diff_time > this.questionConfig.end) {
        this.canSubmitQuestion = false;
        console.log(
          "超出答题时间范围",
          this.questionConfig.start,
          this.questionConfig.end
        );
        return;
      }
      if (this.watchTime >= min) {
        // 可答题
        this.canSubmitQuestion = true;
        this.showAnswerBox = true;
        console.log("可答题");
      } else {
        console.log("时长不足");
      }
    },
    listenVisible() {
      if (this.roomInfo.pstatus != 1) {
        return;
      }
      this.isBack = document.hidden ? true : false;
      if (this.isBack == false) {
        // 从后台恢复
        this.needReload = true;
        showConfirmDialog({
          title: "提示",
          message: "播放过程被中断，请重新连接",
        })
          .then(() => {
            location.reload();
          })
          .catch(() => {
            console.log("cancel");
          });
      } else {
        // 前台进入后台
        // 清除定时任务
        this.clearTimer();
        // 切后台后上报最新观看时长
        this.sendTimeToServer();
      }
    },
    returnTotal(val) {
      this.boxTitle = `我的邀约（${val}人）`;
    },
    returnTotalZuli(val) {
      this.boxTitle = `房间用户（${val}人）`;
    },
    selectUser(item) {
      if (this.userAuth.grade == 0 && this.userAuth.zuli == 0) {
        showToast("没有权限");
        return;
      }
      // 团队长操作用户
      this.selectItem = item;
      this.userOptions = [];
      if (item.auth > 0) {
        this.userOptions.push({
          name: "取消认证",
          icon: "good-job",
          act: "auth_cancle",
          color: "#666666",
        });
      } else {
        this.userOptions.push({
          name: "认证",
          icon: "good-job",
          act: "auth_ok",
          color: "#23cc77",
        });
      }
      if (item.grade > 0) {
        this.userOptions.push({
          name: "取消团队长",
          icon: "manager",
          act: "down_grade",
          color: "#666666",
        });
      } else {
        this.userOptions.push({
          name: "设为团队长",
          icon: "manager",
          act: "up_grade",
          color: "#1ed6e0",
        });
      }
      if (item.black > 0) {
        this.userOptions.push({
          name: "恢复",
          icon: "cross",
          act: "in_black",
          color: "#23cc77",
        });
      } else {
        this.userOptions.push({
          name: "踢出房间",
          icon: "cross",
          act: "out_black",
          color: "#666666",
        });
      }
      if (item.comment_status == 0) {
        this.userOptions.push({
          name: "开启评论",
          icon: "cross",
          act: "open_comment",
          color: "#23cc77",
        });
      } else {
        this.userOptions.push({
          name: "禁止评论",
          icon: "cross",
          act: "close_comment",
          color: "#666666",
        });
      }
      this.showUserAction = true;
    },
    selectUserActionDo(opt) {
      if (opt.act == "auth_ok" || opt.act == "auth_cancle") {
        // 进行认证操作
        this.changeAuthStatus("auth", `确认【${opt.name}】吗？`);
      } else if (opt.act == "down_grade" || opt.act == "up_grade") {
        // 进行认证操作
        this.changeAuthStatus("grade", `确认【${opt.name}】吗？`);
      } else if (opt.act == "in_black" || opt.act == "out_black") {
        // 进行认证操作
        this.changeAuthStatus("black", `确认【${opt.name}】吗？`);
      } else if (opt.act == "down_zb" || opt.act == "up_zb") {
        // 设置主播身份
        this.changeAuthStatus("is_zb", `确认【${opt.name}】吗？`);
      } else if (opt.act == "open_comment" || opt.act == "close_comment") {
        // 设置主播身份
        this.changeAuthStatus("comment_status", `确认【${opt.name}】吗？`);
      } else {
        showToast("不支持的操作");
      }
    },
    changeAuthStatus(field, tips) {
      showConfirmDialog({
        title: "请确认",
        message: tips,
      })
        .then(() => {
          this.$http
            .postData("/ShareLog/changeStatus", {
              user_id: this.selectItem.user_id,
              caster_id: this.caster_id,
              field: field,
              hide_success: false,
            })
            .then((res) => {
              this.showUserAction = false;
              this.viewSortData = false;
            })
            .catch((error) => {});
        })
        .catch(() => {
          // on cancel
        });
    },
    onClosePoster(val) {
      this.showPosterBox = false;
    },
    closeMyBox() {
      this.viewSortData = false;
      // this.viewApps = true
    },
    getAnsArr(idx) {
      return this.ansArr[idx];
    },
    onClickTab(val) {
      this.activeName = val.name;
      // 处理事件
      if (this.activeName == "sortlist") {
        this.listType = 1;
      }
    },
    changeEmojo() {
      this.viewEmojo = !this.viewEmojo;
    },
    openApps() {
      this.viewApps = true;
    },
    showContent() {
      if (!this.commentStatusRoom) {
        showToast(this.roomInfo.opens.close_comment_tip);
        return;
      }
      if (!this.commentStatusUser) {
        showToast("已禁言");
        return;
      }
      this.showInput = !this.showInput;
      if (this.showInput) {
        this.$nextTick(() => {
          this.$refs.inputRef.focus();
        });
      } else {
        this.hideInput();
      }
    },
    clickEmojo(val) {
      this.sendData.content += val;
    },
    hideInput() {
      this.showInput = false;
      this.showAnswerBox = false;
      this.sendData.content = "";
    },
    initWebSocket: function () {
      if (typeof WebSocket === "undefined") {
        showToast("您的浏览器不支持socket");
      } else {
        if (this.socket) {
          console.log("已链接");
          return;
        }
        // 实例化socket
        this.socket = new WebSocket(
          this.soketPath +
            "?token=" +
            this.userInfo.token +
            "&caster=" +
            this.roomInfo.id +
            "&auth=" +
            this.roomInfo.user_id
        );
        // 监听socket连接
        this.socket.onopen = this.open;
        // 监听socket错误信息
        this.socket.onerror = this.error;
        // 监听socket消息
        this.socket.onmessage = this.getMessage;
        // 监听断开
        this.socket.onclose = this.close;
      }
    },
    open: function () {
      // 心跳检测
      heartCheck.setSocket(this.socket, this.caster_id).reset().start();
      // this.onPlay();
    },
    close: function () {
      this.socket = null;
      this.lockReconnect = false;
      this.reconnect();
    },
    // 断开重连操作
    reconnect() {
      // console.log(this.isLogout, this.lockReconnect);
      // if (this.isLogout) return;
      if (this.lockReconnect) return;
      this.lockReconnect = true;
      let _this = this;
      //没连接上会一直重连，设置延迟避免请求过多
      setTimeout(function () {
        _this.initWebSocket();
        _this.lockReconnect = false;
      }, 6000);
    },
    error: function (e) {
      this.isLogout = false;
      // 更新时间出现异常 采用https方式
      if (this.roomInfo && this.roomInfo.pstatus == 1) {
        this.$http
          .postData("/ShareLog/updateTimes", {
            log_id: this.roomInfo.log_id,
            times: this.watchTime,
            caster_id: this.caster_id,
            hide_success: true,
            hide_error: true,
            hide_loading: true,
          })
          .then((res) => {
            if (res) {
              location.reload();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      console.log("socket断开", e);
    },
    // 发送消息给被连接的服务端
    send: function (params) {
      let disMsg = ["good", "subscribe"];
      // if (this.roomInfo.pstatus > 1 && disMsg.indexOf(params.type) >= 0) {
      //   showToast("直播已结束，不支持");
      //   return;
      // }
      if (this.socket) {
        this.socket.send(JSON.stringify(params));
      } else {
        showToast("发送失败");
      }
    },
    showSharePoster() {
      if (this.userAuth.grade) {
        this.options.map((item) => {
          if (item.act == "shareLink") {
            this.selectAct(item);
          }
        });
        return;
      }
      let url = this.roomInfo.codeUrl;
      let has_auth = this.roomInfo.has_auth;

      if (this.roomInfo.has_auth && this.roomInfo.noctr) {
        url += "?noctr=" + this.roomInfo.noctr;
        has_auth = false;
      }
      // 普通用户直接分享
      this.posterInfo = {
        nickname: this.userInfo.nickname,
        avatar: this.userInfo.avatar,
        title: this.roomInfo.title,
        has_auth: has_auth,
        times: "开播时间 " + this.roomInfo.opens.times[0],
        codeUrl: url,
      };
      this.showPosterBox = true;
    },
    showSharePosterChild(item) {
      // 团队长发起
      let codeUrl = "https://m.qianbaiyou88.com/detail/" + item.code;

      let has_auth = item.has_auth || item.has_grade ? true : false;
      this.posterInfo = {
        nickname: this.userInfo.nickname,
        avatar: this.userInfo.avatar,
        title: item.caster_title,
        has_auth: has_auth,
        times: "开播时间 " + item.count_info.opens.time,
        codeUrl: codeUrl,
      };
      this.showPosterBox = true;
    },
    selectAct(opt) {
      if (opt.act == "sortData") {
        // 房间的邀请榜单
        this.viewSortData = true;
        this.boxTitle = "邀请榜单";
        this.listType = 1;
      } else if (opt.act == "getOnlineUser") {
        // 查询房间用户
        this.listType = 5;
        this.boxTitle = "房间用户";
        this.viewSortData = true;
      } else if (opt.act == "myShare") {
        // 我的邀约
        if (!this.userAuth.grade) {
          showToast("没有权限");
          return;
        }
        this.viewSortData = true;
        this.boxTitle = "我的邀约";
        this.listType = 2;
      } else if (opt.act == "balance") {
        // 运营费用
        if (!this.userAuth.grade) {
          showToast("没有权限");
          return;
        }
        this.$router.push({ path: "/balance/getList" });
        return;
      } else if (opt.act == "viewData") {
        // 查看数据
        if (!this.userAuth.grade) {
          showToast("没有权限");
          return;
        }
        this.$router.push({
          path: "/teamData/getList",
          query: {
            extId: this.roomInfo.id,
          },
        });
        return;
      } else if (opt.act == "shareLink") {
        // 我的专属链接
        if (!this.userAuth.grade) {
          showToast("没有权限");
          return;
        }
        this.viewSortData = true;
        this.boxTitle = "专属链接";
        this.listType = 4;
      } else if (opt.act == "prize") {
        this.viewSortData = true;
        this.boxTitle = "答题红包";
        this.listType = 3;
      } else if (opt.act == "videos") {
        this.$router.push({ path: "/live/getList" });
      } else if (opt.act == "change") {
        // 修改昵称
        this.showNickname = true;
        this.newName = "";
      } else if (opt.act == "changePc") {
        // 切换PC
        const beforeClose = (action) =>
          new Promise((resolve) => {
            resolve(action);
          });
        // 加一个确认
        showConfirmDialog({
          title: "提示",
          message: "切换电脑观看布局吗?",
          beforeClose,
        })
          .then((val) => {
            this.$router.push({
              name: "forPc",
              params: {
                code: this.code,
              },
            });
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (opt.act == "clears") {
        this.clears = !this.clears;
        if (this.clears) {
          this.danMulist = [];
          showToast("简洁模式开启");
          opt.name = "简洁(开)";
          opt.color = "#71d85f";
        } else {
          showToast("简洁模式关闭");
          opt.name = "简洁(关)";
          opt.color = "#666666";
        }
      } else if (opt.act == "qrcode") {
        if (!this.userAuth.grade) {
          showToast("没有权限");
          return;
        }
        this.$router.push({ path: "/qrcode" });
      } else if (opt.act == "clearMsg") {
        // 助理清屏
        if (!this.userAuth.zuli) {
          showToast("没有权限");
          return;
        }
        const beforeClose = (action) =>
          new Promise((resolve) => {
            resolve(action);
          });
        // 加一个确认
        showConfirmDialog({
          title: "提示",
          message: "确认【清屏】吗?",
          beforeClose,
        })
          .then((val) => {
            this.send({
              type: "clearMsg",
              uid: this.userInfo.uid,
              group: this.caster_id, //房间号
            });
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (opt.act == "changeRoomCommentStatus") {
        // 助理修改房间评论状态
        if (!this.userAuth.zuli) {
          showToast("没有权限");
          return;
        }
        const beforeClose = (action) =>
          new Promise((resolve) => {
            resolve(action);
          });
        // 加一个确认
        showConfirmDialog({
          title: "提示",
          message: `确认【${this.commentStatusRoom ? "关闭" : "开启"}】评论吗?`,
          beforeClose,
        })
          .then((val) => {
            this.$http
              .postData("/ShareLog/changeStatus", {
                caster_id: this.caster_id,
                field: "open_comment",
                hide_success: false,
              })
              .then((res) => {
                if (this.commentStatusRoom) {
                  opt.name = "评论(开)";
                  opt.color = "#09a657";
                } else {
                  opt.name = "评论(关)";
                  opt.color = "#666666";
                }
              })
              .catch((error) => {});
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (opt.act == "clearMsgByUser") {
        // 用户自己清屏
        this.commentList = [];
      } else {
        showToast("未知操作");
      }
      this.viewApps = false;
    },
    getMessage: function (msg) {
      let data = JSON.parse(JSON.parse(JSON.stringify(msg.data)));
      if (data.client_count != null) {
        this.clientCount = data.client_count;
      }

      let disMsg = ["sendComment"];

      if (disMsg.indexOf(data.type) >= 0) {
        // 允许的消息 可以展示
        this.pushComment(data);
      }

      if (data.type == "connect") {
        // 连接成功 绑定用户
        this.send({
          type: "login",
          uid: this.userInfo.uid,
          nickname: this.userInfo.nickname,
          group: this.caster_id, //房间号进行分组
          mg_id: this.roomInfo.user_id, //通知主播
        });
        this.pushComment({
          uid: 0,
          nickname: "系统",
          content: "请文明发表评论",
          type: "sendComment",
        });
      } else if (data.type == "changeBlack") {
        // 黑名单
        this.letUserGo();
      } else if (data.type == "login") {
        // this.pushDanmu({
        //     id:Math.random(),
        //     text:data.content
        // })
      } else if (data.type == "getUids") {
        // 查询房间用户
        this.userIds = [];
        // let tmp = [];
        // Object.keys(data.content).map(function (key) {
        //   tmp.push(parseInt(data.content[key]));
        // });
        this.userIds = tmp;
        this.listType = 5;
        this.boxTitle = "房间用户";
        this.viewSortData = true;
      } else if (data.type == "logout") {
        // this.pushDanmu({
        //     id:Math.random(),
        //     text:data.content
        // })
      } else if (data.type == "updateTime") {
        // 更新累计时长
      } else if (data.type == "caster") {
        // 直播状态变化
        showToast(data.live_msg);
        if (data.live_status == "play") {
          this.roomInfo.pstatus = 1;
          this.videoInfo = data.videoInfo;
        } else {
          this.roomInfo.pstatus = 2;
          this.roomInfo.opens.tips = "已结束";
          if (this.questionConfig) {
            this.questionConfig.start = data.start;
            this.questionConfig.end = data.end;
          }
          // localStorage.removeItem("watchTime_" + this.caster_id);
          this.videoInfo = false;
          this.sendTimeToServer();
        }
        this.loadPlayer();
      } else if (data.type == "hudong") {
        // 用户互动
        this.roomInfo.hudong.view = data.hudong.view;
        this.roomInfo.hudong.good = data.hudong.good;
        if (data.hudong.user_id == this.userInfo.uid) {
          this.roomInfo.hudong.hasSubscribe = data.hudong.hasSubscribe;
          this.roomInfo.hudong.hasGood = data.hudong.hasGood;
        }
      } else if (data.type == "close") {
        console.log(data.content);
      } else if (data.type == "sendComment") {
        this.viewEmojo = false;
      } else if (data.type == "restartCaster") {
        showNotify({
          type: "warning",
          message: data.msg,
          duration: data.duration ? data.duration : 3000,
        });
      } else if (data.type == "changeRoomAuth") {
        // 调整权限
        let act_type = data.act_type;
        if (act_type == "open_comment") {
          // 修改房间禁言状态
          this.commentStatusRoom = data.status ? true : false;
        } else if (act_type == "comment_status") {
          // 修改用户禁言状态
          this.commentStatusUser = data.status ? true : false;
        } else if (act_type == "auth") {
          // 修改用户认证状态
          this.letUserGo();
        } else if (act_type == "status") {
          // 修改账户状态
          this.letUserGo();
        } else if (act_type == "zuli") {
          // 修改助理状态
          this.userAuth.zuli = data.status ? true : false;
          this.initUserAuth();
          // this.letUserGo();
        } else if (act_type == "grade") {
          // 修改用户团队长权限
          this.userAuth.grade = data.status ? true : false;
          this.initUserAuth();
          // this.letUserGo();
        } else if (act_type == "black") {
          this.letUserGo();
        } else if (act_type == "caster_status" && data.status == false) {
          // 关闭房间
          let _this = this;
          showToast("房间已关闭");
          setTimeout(function () {
            _this.letUserGo();
          }, 1500);
        }
      } else if (data.type == "clearMsg") {
        let status = data.status;
        if (status) {
          this.commentList = [];
        } else {
          showToast(data.content);
        }
      }
    },
    pushComment(data) {
      this.commentList.push(data);
      let tmp = this.commentList.slice(-100);
      this.commentList = [...tmp];

      let _this = this;
      setTimeout(function () {
        _this.topScrollClick();
      }, 10);
    },
    pushDanmu(data) {
      if (this.clears) {
        return;
      }
      // 增加弹幕
      this.danMulist.push(data);
      let tmp = this.danMulist.slice(-100);
      this.danMulist = [...tmp];
    },
    topScrollClick() {
      this.$nextTick(() => {
        this.scrollEl = this.$refs.comment_box;
        if (this.scrollEl) {
          this.scrollEl.scrollTo({
            top: this.scrollEl.scrollHeight,
            behavior: "smooth",
          });
        }
      });
    },
    letUserGo() {
      this.clearTimer();
      // 发送时间
      this.sendTimeToServer();
      setTimeout(() => {
        location.reload();
      }, 500);
    },
    // 修改昵称
    submitName() {
      if (!this.newName) {
        showToast("请输入昵称");
        return;
      }
      this.$http
        .postData("/User/changeName", {
          nickname: this.newName,
          hide_success: false,
        })
        .then((res) => {
          this.userInfo = res;
          localStorage.setItem("userInfo", JSON.stringify(res));
          this.showNickname = false;
          this.newName = "";
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 点赞 关注
    doHuDong(type) {
      if (type == "good") {
        // 点赞
        if (this.actMax) {
          return;
        } else {
          this.actMax = true;
          let _this = this;
          setTimeout(function () {
            _this.actMax = false;
          }, 500);
        }
      }
      this.send({
        type: "hudong",
        act_type: type,
        uid: this.userInfo.uid,
        mg_id: this.roomInfo.user_id, //通知给管理员
        nickname: this.userInfo.nickname,
        caster_id: this.roomInfo.id,
        room_id: this.roomInfo.room_id,
        log_id: this.roomInfo.log_id,
        group: this.roomInfo.id,
      });
    },
    reciveBound() {
      this.$http
        .postData("/Question/toPay", {
          link_id: this.roomInfo.link_id,
          hide_success: false,
        })
        .then((res) => {
          this.viewBound = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 发布评论
    sendComment() {
      // if (this.roomInfo.pstatus > 1) {
      //   showToast("直播已结束，不支持");
      //   return;
      // }
      if (!this.commentStatusRoom) {
        showToast(this.roomInfo.opens.close_comment_tip);
        return;
      }
      if (!this.commentStatusUser) {
        showToast("已禁言");
        return;
      }
      if (!this.sendData.content) {
        showToast("请填写评论内容");
        return;
      }
      this.send({
        type: "sendComment",
        uid: this.userInfo.uid,
        nickname: this.userInfo.nickname,
        group: this.caster_id,
        content: this.handleInput(this.sendData.content),
      });
      this.hideInput();
    },
    handleInput(text = "") {
      if (this.badWords) {
        for (const word of this.badWords) {
          const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
          const reg = new RegExp(escapedWord, "gi");
          text = text.replace(reg, "*".repeat(word.length));
        }
      }
      return text;
    },
    makeShare() {
      let _that = this;
      const wxShare = new WxShare();
      const CURRENT_URL_HISTORY = location.href; //注意我的项目使用的history；
      const CURRENT_URL_HASH = location.href.split("#")[0]; // hash模式使用这个
      const SHARE_CONFIG = {
        title: _that.roomInfo.title,
        desc: _that.roomInfo.desc ? _that.roomInfo.desc : _that.roomInfo.title,
        link: CURRENT_URL_HISTORY, // 要分享的页面地址不允许存在#参数
        imgUrl: _that.roomInfo.thumb, // 分享出去链接图片
      };
      wxShare.yioksShare(SHARE_CONFIG); // 调用分享
    },
    submitQuestion() {
      if (this.userAuth.grade) {
        showToast("群主不参与答题");
        return;
      }
      if (this.loading) {
        return;
      }
      let answerArr = [];
      let hasError = this.questionList.filter((item) => {
        answerArr.push(item.user_answer);
        return !item.user_answer;
      });
      if (hasError && hasError.length) {
        showToast("全部问题都要选择答案");
        return;
      }
      this.loading = true;
      //后端接口 发送红包
      // let times = localStorage.getItem("watchTime_" + this.caster_id)
      //   ? localStorage.getItem("watchTime_" + this.caster_id)
      //   : 0;
      this.$http
        .answerQuestion({
          code: this.code,
          answer: answerArr,
          front_time: this.watchTime,
          hide_success: true,
          hide_error: true,
        })
        .then((res) => {
          this.loading = false;
          if (res.status == 1) {
            // 回答正确
            this.canSubmitQuestion = this.questionConfig.can_submit = false;
            this.viewBound = res.status;
            this.showAnswerBox = false;
            this.boundAmount = res.amount;
          } else if (res.status == 2) {
            // 机会用完
            this.canSubmitQuestion = this.questionConfig.can_submit = false;
            showToast(res.msg);
          } else {
            // 再试一次
            showToast(res.msg);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    goUrl() {
      if (this.canGoBack) {
        // 有历史记录可以后退
        this.$router.go(-1);
      } else {
        // 没有历史记录可以后退
        this.$router.push({ path: "/" });
      }
    },
    onPlay() {
      // 播放开始
      let _this = this;
      this.clearTimer();
      this.watchTime = localStorage.getItem("watchTime_" + _this.caster_id)
        ? localStorage.getItem("watchTime_" + _this.caster_id)
        : 0;

      if (this.roomInfo.pstatus == 1) {
        // 开启定时任务
        this.checkCurrTimer = window.setInterval(function () {
          _this.watchTime++;
          localStorage.setItem("watchTime_" + _this.caster_id, _this.watchTime);
          _this.formatTime();
        }, 1000);
        // 上报一次服务器
        this.watchTimer = window.setInterval(function () {
          _this.sendTimeToServer();
        }, this.update_time_limit);
      } else {
        console.log("不计时长");
      }
    },
    barragePause() {
      this.clearTimer();
      console.log("onPAuse");
      return;
    },
    barrageEnd() {
      this.clearTimer();
      this.roomInfo.status = "end";
      this.roomInfo.live_tips = "已结束";
    },
    onLoading() {
      this.clearTimer();
      this.roomInfo.status = "loading";
      this.roomInfo.live_tips = "加载中";
    },
    barrageError(error) {
      this.clearTimer();
      console.log("出错了", error);
    },
    getCasterInfo() {
      this.loading = true;
      let now = new Date();
      let seconds = now.getTime();
      // console.log(pageHeight, this.marginTop);
      let _this = this;
      this.$http
        .getDetail({
          table: "Caster",
          ctime: parseInt(seconds / 1000),
          code: this.code,
          noctr: this.noctr,
        })
        .then((res) => {
          if (res.redirect) {
            // 如果需要重置链接
            window.location.href = res.redirect;
            return;
          }
          if (res.version > 0 && this.$version != res.version) {
            setTimeout(() => {
              location.reload();
            }, 500);
            return;
          }
          this.loading = false;
          this.hasLoad = true;
          this.roomInfo = res;
          this.room_id = res.room_id;
          this.caster_id = res.id;
          // 是否有答题
          if (res.questionList) {
            this.questionList = res.questionList;
          }
          if (res.questionConfig) {
            this.questionConfig = res.questionConfig;
          }
          let localTime = localStorage.getItem("watchTime_" + res.id)
            ? localStorage.getItem("watchTime_" + res.id)
            : 0;
          if (parseInt(res.has_watch) > parseInt(localTime)) {
            console.log("以后端时间为准", res.has_watch, localTime);
            localStorage.setItem("watchTime_" + res.id, res.has_watch);
          } else {
            console.log("以本地时间为准", res.has_watch, localTime);
          }
          this.formatTime();
          this.videoInfo = res.video_info ? res.video_info : false;

          if (res.type == 1) {
            // 真人直播
            this.bgStyle = {
              backgroundImage: "url(" + res.bg + ")",
              backgroundPosition: "top center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            };
          } else {
            this.bgStyle = {
              backgroundImage: "url(" + res.bg + ")",
              backgroundPosition: "top center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            };
            const pageWidth = window.innerWidth;
            let pageHeight = parseInt(pageWidth / (16 / 9));
            this.windowHeight = pageHeight + "px";
            this.marginTop = parseInt((300 - pageHeight) / 2) + "px";
          }
          // 优化后 以opens返回的状态为准
          this.commentStatusRoom = res.opens.open_comment;
          this.update_time_limit = res.update_time_limit;

          // if (
          //   (this.roomInfo.pstatus >= 3 && this.roomInfo.type == 1) ||
          //   (this.roomInfo.pstatus >= 2 && this.roomInfo.type == 2)
          // ) {
          //   this.commentStatusRoom = false;
          // }

          // 用户权限 优化后为准
          this.userAuth = res.userAuth;

          window.document.title = res.title;
          // 敏感词过滤
          this.badWords = res.badWords;
          // 评论限制
          this.maxLength = res.maxLength ? res.maxLength : 20;

          if (this.userAuth.auth == 1) {
            if (this.userAuth.grade && !this.userAuth.self_qrcode) {
              showDialog({
                title: "提示",
                message: "请上传联系方式，便于用户联系您认证",
              }).then(() => {
                this.$router.push({ path: "/qrcode" });
              });
              return;
            }
            // 初始化用户身份
            this.initUserAuth();
            if (this.roomInfo.pstatus < 3) {
              // 用户是否禁言
              this.commentStatusUser = this.userAuth.comment_status
                ? true
                : false;
              // 更新是否可答题状态
              this.showAnswerBox = this.canSubmitQuestion = this.questionConfig
                ? this.questionConfig.can_submit
                : false;
              this.initWebSocket();
            }
            this.loadPlayer();
            if (!res.isSelf) {
              showNotify({
                message: "请创建并进入自己的链接，再分享转发!",
                color: "#ad0000",
                background: "#ffe1e1",
                duration: 10000,
              });
              this.showSharePoster();
            } else {
              if (this.userAuth.grade) {
                // 是团队长
                showNotify({
                  message: "可点击右上角分享转发!",
                  color: "#37a000",
                  background: "#e4ffd8",
                  duration: 3000,
                });
              }
            }
            let _this = this;
            setTimeout(function () {
              _this.makeShare();
            }, 1600);
          } else {
            this.showAuthBox = true;
            showToast("请添加工作人员微信，完成认证");
          }
        })
        .catch((error) => {
          this.loading = false;
          this.loadTips = error.msg;
          console.log(error);
        });
    },
    initUserAuth() {
      // 初始化 根据用户身份 加载不同菜单
      this.options = [
        {
          name: "修改昵称",
          icon: "edit",
          act: "change",
          color: "#1ed6e0",
        },
        {
          name: "邀请榜单",
          icon: "bar-chart-o",
          act: "sortData",
          color: "#00d87d",
        },
        {
          name: "答题红包",
          icon: "gift-o",
          act: "prize",
          color: "#cc0000",
        },
        {
          name: "简洁(关)",
          icon: "brush-o",
          act: "clears",
          color: "#666666",
        },
      ];

      if (this.userAuth.grade) {
        // 加团队长操作
        this.options.push({
          name: "我的邀约",
          icon: "friends-o",
          act: "myShare",
          color: "#58c",
        });
        this.options.push({
          name: "专属链接",
          icon: "link-o",
          act: "shareLink",
          color: "#1eac03",
        });
        this.options.push({
          name: "运营费用",
          icon: "refund-o",
          act: "balance",
          color: "#1eac03",
        });
        this.options.push({
          name: "联系方式",
          icon: "qr",
          act: "qrcode",
          color: "#1ef103",
        });
        this.options.push({
          name: "切换",
          icon: "tv-o",
          act: "changePc",
          color: "#58c",
        });
        this.options.push({
          name: "汇总数据",
          icon: "chart-trending-o",
          act: "viewData",
          color: "#a2c",
        });
      }

      if (this.userAuth.zuli) {
        // 助理
        this.options.push({
          name: "助理",
          icon: "good-job",
          act: "getOnlineUser",
          color: "#1eac03",
        });
        this.options.push({
          name: this.roomInfo.open_comment ? "评论(开)" : "评论(关)",
          icon: "chat-o",
          act: "changeRoomCommentStatus",
          color: this.roomInfo.open_comment ? "#09a657" : "#666666",
        });
        this.options.push({
          name: "清屏",
          icon: "brush-o",
          act: "clearMsg",
          color: "#cc0000",
        });
      }
      if (!this.userAuth.zuli) {
        // 普通用户 清理自己屏幕
        this.options.push({
          name: "清屏",
          icon: "brush-o",
          act: "clearMsgByUser",
          color: "#cc0000",
        });
      }
    },
    loadPlayer() {
      this.clearTimer();
      if (this.roomInfo.pstatus == 1) {
        if (this.userAuth.auth == 1) {
          this.showPlayer = true;
        } else {
          // localStorage.removeItem("watchTime_" + this.caster_id);
          this.showPlayer = false;
        }
      } else {
        // localStorage.removeItem("watchTime_" + this.caster_id);
        this.showPlayer = false;
      }
      this.checkShowQuestionBox();
    },
    sendTimeToServer() {
      // 上报至后端服务器
      if (this.socket) {
        this.send({
          type: "updateTime",
          uid: this.userInfo.uid,
          group: this.caster_id,
          room_id: this.room_id,
          log_id: this.roomInfo.log_id,
          times: this.watchTime,
        });
      }
    },
  },
};
</script>
  <style>
/* body{
    overflow: hidden!important;
  } */
</style>
  <style scoped>
html,
body {
  background: #f3f3f3 !important;
  /* min-height: 600px!important; */
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden !important;
}
.red {
  color: #eb3333 !important;
}
.poster {
}
.hello {
  width: 100%;
  height: 100%;
}
/* 自定义内容 */
.myPopBox {
}
.myPopBox .con {
  position: fixed;
  z-index: 1002;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60vh;
  background-color: #ffffff;
  overflow-y: scroll;
}
.myPopBox .title {
  position: fixed;
  z-index: 1002;
  left: 0;
  right: 0;
  bottom: 60vh;
  background-color: #ffffff;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  border-bottom: 1px solid rgb(235, 235, 235);
  border-radius: 10px 10px 0 0;
}

.apps {
}
.apps .title {
  background-color: #ffffff;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  color: #666666;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(235, 235, 235);
}
.apps .cons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.apps .item {
  font-size: 12px;
  text-align: center;
  line-height: 24px;
  width: 25%;
  margin: 10px 0;
}
.apps .name {
  font-size: 12px;
  line-height: 20px;
}
.apps .icon {
  font-size: 20px;
  line-height: 20px;
  width: 20px;
  height: 20px;
}

.new_user {
  display: inline-block;
  background-color: #00c368;
  color: #ffffff;
  border-radius: 0 15px 15px 0;
  line-height: 30px;
  font-size: 14px;
  padding: 0 10px;
}
.bgbox {
  background: #ffffff;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  background: url("https://jytc.qianbaiyou88.com/storage/bg/bg1.jpg") top center
    no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 400px;
}
.bgbox2 {
  background: #ffffff;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 5;
  /* background: url('../../public/bg/bg1.jpg') top center no-repeat; */
  background-size: 100% 100%;
  text-align: center;
  line-height: 400px;
}
.notice {
  background: rgba(0, 0, 0, 0.6);
  height: 40px;
  line-height: 40px;
  color: #f3f3f3;
  padding: 0 20px;
  font-size: 0.8rem;
}
.notice .clients {
  margin-left: 20px;
  float: right;
}

.tools {
  position: fixed;
  z-index: 20;
  right: 0px;
  bottom: 100px;
  width: 60px;
  height: auto;
  font-size: 12px;
}
.tools .item {
  display: block;
  margin: 10px auto;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 20px;
  line-height: 36px;
  color: #ffffff;
}
.goHome {
  width: 36px;
  height: 36px;
  line-height: 39px;
  text-align: center;
  border-radius: 50%;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 100;
  left: 10px;
  top: 10px;
}
.play_area {
  background: #000;
  height: 300px;
  overflow: hidden;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
}
.play_area_b {
  background: #000000;
  overflow: hidden;
}
/* 简洁模式 播放区域下移 */
.insmp {
  top: 0px;
}
.ques_box {
  width: 90%;
  padding: 10px;
  margin: 0 auto;
}
.ques_box .title {
  line-height: 1.5rem;
}
.van-radio {
  height: 1.8rem;
}

.footer_box {
  position: fixed;
  z-index: 21;
  bottom: 0;
  height: auto;
  left: 0px;
  right: 0px;
  background: #ffffff;
}
.footer_box .content {
  display: flex;
  justify-content: left;
}
.van-field__control {
  font-size: 2rem !important;
}
.footer_box .emojo {
  padding: 10px;
  overflow: hidden;
  overflow-y: scroll;
  height: auto;
  min-height: 80px;
  max-height: 160px;
}
.footer_box .emojo span {
  line-height: 40px;
  font-size: 30px;
  margin: 4px;
}
.footer_box .icon {
  font-size: 36px;
  line-height: 64px;
  color: #333333;
  padding-left: 6px;
}

.foot_tools {
  position: fixed;
  left: 10px;
  bottom: 10px;
  right: 0px;
  z-index: 22;
  display: flex;
  justify-content: space-between;
}

.foot_tools .footer_input_tips {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 14px;
  height: 36px;
  width: 100%;
  line-height: 36px;
  padding: 0 10px;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
  color: #666666;
}
.foot_tools .bot_tool {
  flex-basis: 150px;
}

.foot_tools .bot_tool .item {
  float: left;
  margin-left: 10px;
  height: 36px;
  width: 36px;
  font-size: 22px;
  line-height: 36px;
  border-radius: 50%;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
}
.foot_tools .bot_tool .red {
  color: #cc0000;
}

.comment_box {
  padding: 10px;
  height: 40vh;
  position: fixed;
  left: 0;
  right: 10px;
  bottom: 50px;
  z-index: 12;
  color: #333;
  overflow: scroll;
  overflow-x: hidden;
}
.comment_box .item {
  width: 70%;
  position: relative;
  z-index: 10;
  margin-bottom: 4px;
}
.comment_box .item .nickname {
  color: #e6b100;
  font-weight: bolder;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
  line-height: 1rem;
}
.comment_box .item .text {
  color: #ffffff;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5rem;
  padding: 2px 5px;
  border-radius: 10px;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.3); /* 背景颜色 */
  white-space: pre-line;
  word-break: break-all;
  border-radius: 4px;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

.comment_box_zb {
  padding: 10px;
  height: 50vh;
  position: fixed;
  left: 0;
  right: 10px;
  bottom: 50px;
  z-index: 12;
  color: #333;
  overflow: scroll;
  overflow-x: hidden;
}
.comment_box_zb .item {
  width: 80%;
  position: relative;
  z-index: 10;
  margin-bottom: 4px;
}
.comment_box_zb .item .nickname {
  color: #e6b100;
  font-weight: bolder;
  font-size: 1.3rem;
  -webkit-text-size-adjust: 100%;
  line-height: 2rem;
}
.comment_box_zb .item .text {
  color: #ffffff;
  font-size: 1.3rem;
  line-height: 2rem;
  -webkit-text-size-adjust: 100%;
  padding: 2px 5px;
  border-radius: 10px;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.3); /* 背景颜色 */
  white-space: pre-line;
  word-break: break-all;
  border-radius: 4px;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.waiting {
  text-align: center;
  padding-top: 50px;
  color: #999999;
}
.thumb {
  width: 100%;
  height: 300px;
}
.mask {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 300px;
  width: 100%;
  line-height: 300px;
  background: rgba(0, 0, 0, 0.3);
  color: #ffffff;
  text-align: center;
}
.prism-cc-btn,
.prism-volume,
.prism-fullscreen-btn,
.prism-setting-speed {
  display: none !important;
}
.bounds {
  background-color: #d12929;
  color: #ffffff;
  width: 220px;
  height: 260px;
  position: relative;
}
.bounds .btop {
  width: 100%;
  height: 70px;
  border-radius: 0 0 50% 50%;
  background-color: #e93030;
}
.bounds .icon {
  position: absolute;
  top: 40px;
  left: 85px;
  width: 50px;
  height: 50px;
  font-size: 50px;
  color: #e6b100;
}
.bounds .price {
  font-weight: 300;
  color: #e6b100;
}
.bounds .price b {
  font-weight: 300;
  font-size: 2rem;
}
.bounds .tips {
  text-align: center;
  font-size: 12px;
  color: #e08204;
  padding: 10px;
}
.bounds .btns {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  background: #e6b100;
  color: #be2222;
  margin: 0 auto;
  border-radius: 10px;
  margin-top: 10px;
}
.sharejt {
  position: fixed;
  z-index: 1000;
  right: 10px;
  top: 0px;
  color: #fff;
}
.sharejt .jticon {
  font-size: 100px;
  transform: rotate(-40deg);
  text-align: right;
}
.sharejt .jttxt {
  font-size: 14px;
  color: #dd6502;
}
.sharejtmsk {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}
.room_content {
  min-height: 80vh;
  padding: 10px 20px;
  line-height: 2rem;
  background: #ffffff;
  border-top: 1px solid #dedede;

  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  white-space: pre-line;
  word-break: break-all;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.room_content p {
  text-indent: 2rem;
  padding: 0px !important;
  margin: 5px 0px !important;
}
.apps_new {
  padding: 20px 10px;
}
.apps_new .title {
  text-align: center;
  font-size: 16px;
  color: #666666;
  padding-bottom: 14px;
  margin-bottom: 10px;
}
.apps_new .item {
  font-size: 12px;
  text-align: center;
  line-height: 24px;
}
.apps_new .icon {
  font-size: 28px;
}
.replay {
  color: #fff;
  padding: 4px 8px;
  position: fixed;
  z-index: 100;
  right: 20px;
  top: 20px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
</style>
  